.text_center {
  text-align: center;
}

.align_items_start {
  align-items: start;
}

.d_inline {
  display: inline;
}

.d_block {
  display: block !important;
}

.position_relative {
  position: relative;
}
