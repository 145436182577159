
.body_text_1 {
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 28px;
  color: #19252a;
}

.body_text_1_bold {
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 28px;
  color: #19252a;
}

.body_text_5 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #373f41;
}

.body_text_6 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #373f41;
}

.body_text_12 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 3px;
  color: #221e1e;
}

.font_w_normal {
  font-weight: normal;
}
