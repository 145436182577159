.max_width_600 {
  max-width: 600px;
}

.min_height_600 {
  min-height: 600px;
}

.min_height_700 {
  min-height: 700px;
}

.full_width {
  width: 100%;
}
