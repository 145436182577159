@import '../../../../styles/colours.scss';

.myProgress_wrapper {
  & .summary_subject_wrapper {
    & .subject_selection {
      margin: 40px 0 20px 0;
    }
  }
  & .summary_top_content {
    background: none;
    padding-left: 0;
    padding-right: 0;
    padding-top: 20px;
    padding-bottom: 20px;

    & .learner_info_wrapper {
      width: 98px;
      height: 98px;
      & .learner_token {
        width: 40px;
      }

      & .learner_name {
        font-size: 7px;
        line-height: 7px;
      }
      & .learner_points {
        font-size: 7px;
        line-height: 7px;
      }
    }

    & .custom_button_icon {
      padding: 2px 8px 0px 0px;
    }
  }
}

.summary_body_wrapper {
  & .date_wrapper {
    padding: 18px;
    background: #f8f8f8;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }
  & .topic_name {
    text-transform: capitalize;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    color: #000000;
    margin-top: 40px;
  }
  & .nothingToShow-wrapper {
    height: 350px;
    & .top-image-wrapper {
      & .top-image {
        height: 280px;
        margin-bottom: 40px;
      }
    }
  }
}

.summary_practice {
  & .practice_boxes {
    text-align: center;
    background: #f8f8f8;
    padding: 25px 25px;
    cursor: pointer;
    margin-top: 20px;
    & .titles {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      position: relative;
      width: fit-content;
      margin: 0 auto;
      margin-bottom: 20px;
      & .active_box {
        position: absolute;
        left: -15px;
        top: 6px;
      }
    }

    & .values {
      font-style: normal;
      font-weight: 600;
      font-size: 23px;
      line-height: 28px;
      color: #000000;
    }

    &:focus {
      border-bottom: 5px solid #19252a;
      background: white;
      outline: none;
    }
  }

  & .box_active {
    border-bottom: 5px solid #19252a;
    background: white;
  }
}

.summary_practice_graph {
  height: 440px;
  background: #f8f8f8;
  padding-top: 30px;
  position: relative;
  text-align: center;
  & .graph_label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }

  & .recharts-bar-rectangle {
    cursor: pointer;
  }

  & .recharts-legend-wrapper {
    right: 30px !important;
    & .recharts-legend-item-text {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
      margin-left: 6px;
    }
  }

  & .custom_tooltip {
    background: #172e38;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color: white;
    padding: 15px;
    text-align: start;
    & .label {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }
    & .value {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #6ff2c2;
    }
  }
}

.practice_activity {
  padding-top: 30px;
  & .title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 9px;
  }

  & .custom_hr {
    margin: 20px 0;
  }

  & .activity_wrapper {
    background: #fbfafa;
    padding: 30px 30px;
    margin-bottom: 30px;

    & .progress_wrapper {
      text-align: end;
    }

    & .activity_item {
      & .name {
        text-transform: capitalize;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        margin-bottom: 3px;
      }
      & .skill_name {
        font-style: normal;
        font-weight: 600;
        font-size: 23px;
        line-height: 28px;
        color: #000000;
        margin-bottom: 8px;
      }
      & .date {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        margin-bottom: 10px;
      }
    }
  }
}

@media only screen and (max-width: 1362px) {
  .summary_top_content {
    padding-top: 70px;
    position: relative;
    & .learner_info_wrapper {
      width: 110px;
      height: 110px;
      position: absolute !important;
      top: -65px;
      left: 43%;
      & .learner_token {
        width: 45px;
      }
      & .learner_name,
      .learner_points {
        font-size: 10px;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .summary_practice {
    padding-bottom: 20px;
    & .practice_boxes {
      padding: 10px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .summary_top_content {
    margin-top: 30px;
    & .learner_info_wrapper {
      left: 36%;
    }
  }

  .practice_activity {
    & .activity_wrapper {
      & .progress_wrapper {
        text-align: start;
      }
    }
  }

  .summary_practice_graph {
    height: 300px;
  }
}

@media only screen and (max-width: 470px) {
  .summary_top_content {
    & .learner_info_wrapper {
      left: 32%;
    }
  }
}
