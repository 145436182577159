.student_page_wrapper {
  background: #f8f8f8;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  min-height: 750px;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  & h1 {
    font-family: 'Open Sans';
    font-weight: 600;
    line-height: 48px;
    font-style: normal;
    width: 978px;
    margin-bottom: 41px;
  }
  & .bottom_button {
    display: none;
  }
  & .addStudentButton {
    text-transform: none;
  }
  & .top_area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 990px;
    position: relative;
    align-items: center;
    top: 60px;
    margin-bottom: 30px;
    & .my_students {
      font-weight: 600;
      line-height: 48px;
      font-size: 32px;
      font-style: normal;
      width: fit-content;
    }
  }
  .student_grid {
    position: relative;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 50px;
    width: 1100px;
    .student_box {
      width: 470px;
      height: 330px;
      background: #ffffff;
      box-shadow: 0px 0px 8.21492px 4.10746px rgba(180, 180, 180, 0.2);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      & .student_box_top {
        width: 470px;
        height: 140px;
        & .top_bg {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
        & .child_img {
          border-radius: 100%;
          position: relative;
          left: 183.5px;
          top: -120px;
        }
      }
      & .divider {
        width: 100%;
        height: 1.5px;
      }
      & .student_name {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
        /* identical to box height, or 27px */
        color: #172e38;
        margin-top: 19px;
        margin-bottom: 10px;
      }
      & .student_info {
        width: 215px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 17px;
        & .current_grade {
          display: flex;
          flex-direction: column;
          text-align: center;
          text-transform: capitalize;
          & .current_grade_text {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #5d6970;
          }
          & .grade {
            font-weight: 700;
            font-size: 14px;
            line-height: 150%;
            color: #172e38;
          }
        }
        & .last_active {
          display: flex;
          flex-direction: column;
          text-align: center;
          & .last_active_text {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #5d6970;
          }
          & .last_active_date {
            font-weight: 700;
            font-size: 14px;
            line-height: 150%;
            color: #172e38;
          }
        }
      }
    }
    & .buttons_wrapper {
      display: flex;
      gap: 10px;
    }
  }
  & .info_box {
    display: flex;
    flex-direction: row;
    background-color: white;
    box-shadow: 0px 0px 8.21492px 4.10746px rgba(180, 180, 180, 0.2);
    border-radius: 10px;
    width: 978px;
    height: 338px;
    background-image: url('/assets/images/accountbox_bg.png');
    background-size: cover;

    & .right_side {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      left: 50%;
      position: relative;
      & .membership_count {
        font-family: Open Sans;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: left;
        margin-bottom: 16px;
      }
      & .buttons_div {
        margin-bottom: 8px;
        height: 44px;
        width: 326px;
      }
      & .buttons_div2 {
        height: 44px;
        width: 326px;
      }
    }
    & .info_box_img {
      position: absolute;
      width: 314px;
      height: 218px;
      margin-top: 60px;
      margin-left: 85px;
    }
  }
}
.blurElement {
  animation-delay: 1s;
  filter: blur(5px) brightness(55%);
  pointer-events: none;
  // transform: scale(1.1);
  overflow: hidden;
}

.addStudentDialog {
  width: calc(450px + 40px);
  height: calc(350px + 40px);
  z-index: 2;
}
.addStudent {
  background: #ffffff;
  box-shadow: 0px 0px 8.21492px 4.10746px rgba(180, 180, 180, 0.2);
  border-radius: 10px;
  position: absolute;
  width: 450px;
  height: 350px;
  z-index: 2;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .button {
    text-transform: none;
    line-height: 15px;
  }

  & .tree_image {
    position: relative;
    z-index: 3;
    top: -177px;
    width: 490px;
  }
  & .close_button {
    position: relative;
    z-index: 4;
    top: -249px;
    left: 219px;
    border: none;
    font-size: 17px;
    background: none;
    padding-top: 3px;
    cursor: pointer;
  }
  .MuiFormHelperText-root {
    margin-left: 14px;
  }
  & .addStudentText {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 150%;
    color: #221e1e;
    left: 54px;
    top: 110px;
    text-align: center;
    width: 370px;
    height: 38px;
    position: absolute;
  }
  & .membership_text {
    width: 301px;
    position: absolute;
    height: 20px;
    left: 104.5px;
    top: 168px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #19252a;
  }
  & .button1 {
    position: absolute;
    top: 225px;
  }
}
.addExistingDialog {
  width: calc(450px + 40px);
  height: calc(350px + 40px);
  z-index: 2;
}
.addExisting {
  background: #ffffff;
  box-shadow: 0px 0px 8.21492px 4.10746px rgba(180, 180, 180, 0.2);
  border-radius: 10px;
  position: absolute;
  width: 450px;
  height: 370px;
  z-index: 2;
  padding: 20px;
  & .tree_image {
    position: relative;
    z-index: 3;
    left: -20px;
    top: -134px;
    width: 490px;
  }
  & .close_button {
    position: relative;
    z-index: 4;
    top: -208px;
    left: 429px;
    border: none;
    font-size: 17px;
    background: none;
    padding-top: 3px;
    cursor: pointer;
  }
  & .close_button:hover {
    background-color: rgba(58, 90, 255, 0.14);
  }
  & .addExistingText {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 150%;
    color: #221e1e;
    left: 54px;
    top: 110px;
    text-align: center;
    width: 370px;
    height: 38px;
    position: absolute;
  }
  & .enter_email {
    position: absolute;
    width: 340px;
    height: 20px;
    left: 44px;
    top: 166px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */
    color: #19252a;
  }
  & .studentCodeField {
    position: absolute;
    left: 44px;
    top: 204px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */
    color: #19252a;
  }
  & .studentNameField {
    position: absolute;
    left: 44px;
    top: 166px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */
    color: #19252a;
    & .addStudent_button_div {
      margin-top: 10px;
    }
  }
}
.addNewDialog {
  width: calc(450px + 40px);
  height: calc(471px + 40px);
  z-index: 2;
  padding: none;
}
.addNew {
  background: #ffffff;
  box-shadow: 0px 0px 8.21492px 4.10746px rgba(180, 180, 180, 0.2);
  border-radius: 10px;
  position: absolute;
  width: 450px;
  height: 510px;
  z-index: 2;
  padding: 20px;
  & .tree_image {
    position: relative;
    z-index: 3;
    left: -20px;
    top: -134px;
    width: 490px;
  }
  & .close_button {
    position: relative;
    z-index: 4;
    top: -208px;
    left: 429px;
    border: none;
    font-size: 17px;
    background: none;
    padding-top: 3px;
    cursor: pointer;
  }
  & .close_button:hover {
    background-color: rgba(58, 90, 255, 0.14);
  }
  & .addNewText {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 150%;
    color: #221e1e;
    left: 54px;
    top: 89px;
    text-align: center;
    width: 370px;
    height: 38px;
    position: absolute;
  }
  & .newStudentInfo {
    left: 44px;
    top: 140px;
    width: fit-content;
    position: absolute;
    display: flex;
    flex-direction: column;
    & .items {
      margin-bottom: 0px;
    }
    .addNewButton {
      margin-top: 10px;
    }
  }
}
@media only screen and (max-width: 990px) {
  .student_page_wrapper {
    min-height: 800px;
    justify-content: flex-start;
    & h1 {
      font-family: 'Open Sans';
      font-weight: 600;
      line-height: 48px;
      font-style: normal;
      position: relative;
      margin-top: 60px;
      margin-bottom: 41px;
      margin-left: 21px;
      width: 100%;
    }
    .top_area {
      width: 90%;
    }
    .addExistingDialog {
      margin-top: 150px;
    }
    .addExisting {
      margin-top: 150px;
    }
    & .student_grid {
      width: 100%;
      & .student_box {
        width: 300px;
        height: 380px;
        & .student_box_top {
          width: 300px;
          & .child_img {
            border-radius: 100%;
            position: relative;
            left: 100.5px;
            top: -120px;
            width: 98px;
            height: 98px;
          }
        }
      }
      & .buttons_wrapper {
        flex-direction: column;
      }
    }

    & .info_box {
      width: 100%;
      height: 651px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 20%;
      left: 0%;
      background-image: url('/assets/images/accountbox_bg_mobile.png');
      background-size: cover;
      & .right_side {
        padding: unset;
        align-items: center;
        width: 50%;
        left: 0%;
        & .membership_count {
          text-align: center;
          height: 20px;
          width: 300px;
          left: 49px;
          top: 116px;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0px;
          margin-bottom: 26px;
          margin-left: 10px;
        }
      }
      .right_side {
        & .buttons_div {
          width: 272px;
          height: 40px;
          font-size: 14px;
          margin-bottom: 16px;
        }
        & .buttons_div2 {
          width: 272px;
          height: 40px;
          font-size: 14px;
          margin-bottom: 41px;
        }
      }
      & .info_box_img {
        padding: unset;
        position: unset;
        margin: unset;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .student_page_wrapper {
    .top_area {
      margin-top: -20px;
      .top_area_button {
        display: none;
      }
    }
    .student_grid {
      margin-bottom: 10px;
    }
    & .bottom_button {
      display: block;
      margin-bottom: 50px;
    }
    & .info_box {
      & .right_side {
        width: 100%;
      }
    }
  }
  .addExistingDialog {
    width: calc(300px + 40px);
    height: calc(250px + 40px);
  }
  .addExisting {
    width: 300px;
    height: 250px;
    .tree_image {
      width: 340px;
    }
    .close_button {
      top: -195px;
      left: 285px;
    }
    .addExistingText {
      font-size: 20px;
      left: 24px;
      top: 35px;
      width: fit-content;
    }
    .enter_email {
      left: 44px;
      font-size: 12px;
      top: 80px;
      width: fit-content;
    }
    .studentCodeField {
      top: 120px;
    }
    .studentNameField {
      top: 70px;
    }
  }
  .addStudentDialog {
    width: calc(300px + 40px);
    height: calc(250px + 40px);
    z-index: 2;
  }
  .addStudent {
    width: 300px;
    height: 250px;
    .tree_image {
      width: 340px;
      top: -158px;
    }
    .close_button {
      top: -213px;
      left: 153px;
    }
    .addStudentText {
      font-size: 20px;
      left: 100px;
      top: 60px;
      width: fit-content;
    }
    .membership_text {
      left: 31.25px;
      top: 100px;
    }
    .button1 {
      top: 150px;
    }
  }
  .addNewDialog {
    width: calc(300px + 40px);
    height: calc(420px + 40px);
    z-index: 2;
  }
  .addNew {
    width: 300px;
    height: 420px;
    .tree_image {
      width: 340px;
    }
    .close_button {
      top: -195px;
      left: 285px;
    }
    .addNewText {
      font-size: 20px;
      top: 35px;
      width: fit-content;
    }
    .newStudentInfo {
      top: 77px;
    }
  }
}
@media only screen and (max-width: 360px) {
  .addStudentDialog {
    width: calc(250px + 40px);
    height: calc(250px + 40px);
    z-index: 2;
  }
  .addStudent {
    width: 250px;
    height: 250px;
    text-align: center;
    .tree_image {
      width: 290px;
      top: -136px;
    }
    .addStudentText {
      left: 27%;
    }
    .membership_text {
      font-size: 14px;
      left: -7px;
    }
    .button1 {
      top: 137px;
    }
    .close_button {
      top: -183px;
      left: 120px;
    }
  }
  .close_button {
    top: -218px;
    left: 153px;
  }
  .addExistingDialog {
    width: calc(240px + 40px);
    height: calc(250px + 40px);
  }
  .addExisting {
    width: 240px;
    height: 250px;
    .tree_image {
      width: 280px;
      top: -110px;
    }
    .close_button {
      top: -163px;
      left: 230px;
    }
    .addExistingText {
      font-size: 16px;
      left: 24px;
      top: 35px;
      width: fit-content;
    }
    .enter_email {
      left: 15px;
      font-size: 12px;
      top: 80px;
      width: fit-content;
    }
    .studentCodeField {
      top: 120px;
      left: 15px;
    }
    .studentNameField {
      top: 70px;
      left: 15px;
    }
  }
  .addNewDialog {
    width: calc(240px + 40px);
    height: calc(420px + 40px);
    z-index: 2;
  }
  .addNew {
    width: 240px;
    height: 420px;
    .tree_image {
      width: 280px;
      top: -110px;
    }
    .close_button {
      top: -163px;
      left: 230px;
    }
    .addNewText {
      font-size: 16px;
      top: 35px;
      width: 100%;
      left: 0px;
    }
    .newStudentInfo {
      left: 15px;
    }
  }
}

@media only screen and (min-height: 1120px) {
  .student_page_wrapper {
    min-height: 85vh;
    justify-content: flex-start;
  }
}
