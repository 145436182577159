.product_page_wrapper {
  width: 100%;
  overflow: hidden;

  & .sign_up_button {
    border: 0px solid #172e38;
    border-radius: 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #172e38;
    background-color: #6ff2c2;
    width: 187px;
    height: 57px;
    padding: 10px 16px 10px 16px;
    white-space: nowrap;
    cursor: pointer;
    transform: all 0.3s;
  }

  & .sign_up_button.active {
    background-color: #31d196;
  }

  & .section_one_wrapper {
    position: relative;

    & .section_one_bg_img_one {
      position: absolute;
      top: -50px;
      left: -50px;
      z-index: -1;
      transition: all 0.3s;
    }
    & .section_one_bg_img_one.active {
      top: -40px;
      left: -10px;
      transform: rotate(-45deg);
    }
    & .section_one_bg_img_two {
      position: absolute;
      bottom: -100px;
      left: 50px;
      z-index: -1;
      transition: all 0.3s;
    }
    & .section_one_bg_img_two.active {
      bottom: -70px;
      left: 0px;
      transform: rotate(45deg);
    }
    & .section_one_bg_img_three {
      position: absolute;
      top: -100px;
      left: 40%;
      z-index: -1;
      transition: all 0.3s;
    }
    & .section_one_bg_img_three.active {
      top: -130px;
      left: 45%;
      transform: rotate(-45deg);
    }
    & .section_one_bg_img_four {
      position: absolute;
      top: -80px;
      right: 10%;
      z-index: -1;
      transition: all 0.3s;
    }
    & .section_one_bg_img_four.active {
      top: -80px;
      right: 12%;
      transform: rotate(-55deg);
    }
    & .section_one_bg_img_five {
      position: absolute;
      bottom: -100px;
      right: 10px;
      z-index: -1;
      transition: all 0.3s;
    }
    & .section_one_bg_img_five.active {
      bottom: -80px;
      right: 10px;
      transform: rotate(-20deg);
    }

    & .section_one_content_container {
      display: flex;
      position: relative;
      margin-left: 15%;
      margin-right: 15%;
      padding-top: 126px;
      padding-bottom: 113px;
      justify-content: space-between;
      & .section_one_card {
        padding-right: 40px;
        & .section_one_text {
          width: 520px;
          & h2 {
            font-weight: 700;
            font-size: 40px;
            line-height: 52px;
            color: #172e38;
          }
          & p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            color: #172e38;
            max-width: 420px;
            padding-bottom: 10px;
          }
        }
        & .section_one_buttons_wrapper {
          display: flex;
          gap: 20px;
          & .sign_up_button_cover {
            width: 187px;
            height: 57px;
            border: 1.5px solid #19252a;
            box-sizing: border-box;
            border-radius: 10px;
            transform: rotate(-4deg);
            position: absolute;
            transition: all 0.3s;
          }
          & .sign_up_button_cover.active {
            transform: rotate(0deg);
          }
        }
      }
    }
  }

  & .section_two_wrapper {
    background-color: #172e38;
    padding-top: 60px;
    & .section_two_content_container {
      display: grid;
      padding-top: 30px;
      padding-bottom: 30px;
      margin-left: 15%;
      margin-right: 20%;
      & .card_content_container {
        position: relative;
        align-self: center;
        width: 100%;
        max-width: 500px;
        color: white;
        padding-left: 20px;

        & .text_spark_icon {
          position: absolute;
          left: -15px;
          top: 2px;
        }
        & h2 {
          font-weight: 700;
          font-size: 32px;
        }
        & p {
          font-weight: 400;
          font-size: 22px;
        }
      }
      & .section_two_card {
        display: flex;
        justify-content: space-between;
        & .mobile_title {
          display: none;
          visibility: hidden;
        }
        & .mobile_text {
          display: none;
          visibility: hidden;
        }
        // & .card_content_container {
        //   padding-left: 20px;
        // }
        & .image_container {
          // background-color: red;
          width: 65%;
          position: relative;
          display: flex;
          justify-content: center;
          padding-right: 30px;
          padding-top: 30px;
          & .product_section_two_card_image {
            z-index: 2;
          }
          & .product_section_two_bg_one {
            position: absolute;
            top: 0;
            right: 40px;
            z-index: 1;
          }
          & .product_section_two_bg_two {
            position: absolute;
            top: 0;
            left: 0;
          }
          & .product_section_two_bg_three {
            position: absolute;
            top: 0;
            right: 40px;
          }
        }
      }
    }
  }

  & .section_three_wrapper {
    position: relative;
    background-color: #172e38;
    & .section_three_background {
      padding-top: 80px;
      width: 100%;
      bottom: 0px;
    }
    .section_three_content_container {
      left: 0;
      right: 0;
      top: 40%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 70px;
      position: absolute;
      & h2 {
        text-align: center;
        font-weight: 700;
        font-size: 40px;
        line-height: 52px;
        color: #19252a;
        margin: 0 0 0 0;
      }
      & .buttons_wrapper {
        padding-top: 80px;
        display: flex;
        gap: 20px;
        justify-content: center;
        & .sign_up_button_wrapper {
          cursor: pointer;
          & .sign_up_button {
            border: 0px solid #172e38;
            color: #172e38;
            background-color: #6ff2c2;
            width: 187px;
            height: 57px;
            border-radius: 10px;
            transform: all 0.3s;
          }
          & .sign_up_button.active {
            background-color: #31d196;
          }
          & .sign_up_button_cover {
            width: 187px;
            height: 57px;
            border: 1.5px solid #19252a;
            box-sizing: border-box;
            border-radius: 10px;
            transform: rotate(-4deg);
            position: absolute;
            transition: all 0.3s;
          }
          & .sign_up_button_cover.active {
            transform: rotate(0deg);
          }
        }
        & .demo_button {
          width: 187px;
          height: 57px;
          border-radius: 10px;
          transition: all 0.2s;
        }
        & .demo_button:hover {
          color: white;
          background-color: #172e38;
        }
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .product_page_wrapper {
    & .section_one_wrapper {
      & .section_one_content_container {
        & .section_one_card {
          & .section_one_text {
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .product_page_wrapper {
    & .section_one_wrapper {
      //background images
      & .section_one_bg_img_one {
        top: -80px;
        left: -80px;
      }
      & .section_one_bg_img_two {
        position: absolute;
        bottom: -150px;
        left: -100px;
      }
      & .section_one_bg_img_three {
        top: -180px;
        left: 40%;
      }
      & .section_one_bg_img_four {
        top: 60px;
        right: -80px;
      }
      & .section_one_bg_img_five {
        bottom: -65px;
        right: -70px;
      }
      //background images done
      & .section_one_content_container {
        display: flex;
        position: relative;
        margin-left: 15%;
        margin-right: 15%;
        padding-top: 126px;
        padding-bottom: 113px;
        justify-content: center;
        text-align: center;
        & .section_one_card {
          padding-right: 0px;
          & .section_one_text {
            & p {
              margin: auto;
              padding-bottom: 40px;
            }
          }
          & .section_one_buttons_wrapper {
            justify-content: center;
          }
        }
        & .product_page_section_one_image {
          display: none;
          visibility: hidden;
        }
      }
    }

    //section TWO
    & .section_two_wrapper {
      padding-top: 20px;
      & .section_two_content_container {
        display: grid;
        margin-left: 20%;
        margin-right: 20%;
        & .card_content_container {
          display: none;
          visibility: hidden;
          & h2 {
            font-weight: 700;
            font-size: 30px;
          }
          & p {
            font-weight: 400;
            font-size: 20px;
          }
        }
        & .section_two_card {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 25px;
          padding-bottom: 25px;
          text-align: center;
          color: white;
          & .mobile_title {
            font-weight: 700;
            font-size: 25px;
            line-height: 150%;
            display: block;
            visibility: visible;
          }
          & .mobile_text {
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
            display: block;
            visibility: visible;
          }
          & .image_container {
            width: 100%;
            padding-right: 0px;
          }
        }
      }
    }

    & .section_three_wrapper {
      & .section_three_background {
        margin-left: 0%;
        padding-top: 100px;
        height: 450px;
      }
      & .section_three_content_container {
        top: 45%;
        & .buttons_wrapper {
          padding-top: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .product_page_wrapper {
    //section TWO
    & .section_one_wrapper {
      & .section_one_content_container {
        padding-top: 40px;
      }
    }
    & .section_two_wrapper {
      & .section_two_content_container {
        margin-left: 2%;
        margin-right: 2%;
        & .section_two_card {
          & .mobile_text {
            padding-left: 10%;
            padding-right: 10%;
          }
          & .image_container {
            padding-top: 10px;
            & .product_section_two_card_image {
              width: 100%;
            }
            & .product_section_two_bg_one {
              width: 50%;
            }
            & .product_section_two_bg_two {
              width: 50%;
            }
            & .product_section_two_bg_three {
              width: 50%;
            }
          }
        }
      }
    }

    & .section_three_wrapper {
      overflow: hidden;
      height: 500px;
      & .section_three_background {
        height: 450px;
        width: 150%;
        position: absolute;
        right: -25%;
        top: -70px;
      }
      & .section_three_content_container {
        top: 35%;
        & .buttons_wrapper {
          padding-top: 20px;
        }
        & h2 {
          font-size: 25px;
          line-height: 150%;
        }
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  .product_page_wrapper {
    & .section_three_wrapper {
      & .section_three_background {
        width: 200%;
        right: -50%;
      }
      & .section_three_content_container {
        top: 34%;
        & .buttons_wrapper {
          padding-top: 20px;
        }
        & h2 {
          font-size: 25px;
          line-height: 150%;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .product_page_wrapper {
    & .section_two_wrapper {
      & .section_two_content_container {
        & .section_two_card {
          & .mobile_text {
            padding-left: 0%;
            padding-right: 0%;
          }
        }
      }
    }
    & .sign_up_button {
      width: 165px;
    }
    &
      .section_one_wrapper
      .section_one_content_container
      .section_one_card
      .section_one_buttons_wrapper
      .sign_up_button_cover {
      width: 165px;
    }
    &
      .section_three_wrapper
      .section_three_content_container
      .buttons_wrapper
      .sign_up_button_wrapper
      .sign_up_button_cover {
      width: 165px;
    }
    &
      .section_three_wrapper
      .section_three_content_container
      .buttons_wrapper
      .sign_up_button_wrapper
      .sign_up_button {
      width: 165px;
    }

    & .section_one_wrapper {
      & .section_one_bg_img_one.active {
        top: -59px;
        left: -30px;
        width: 150px;
      }
      & .section_one_bg_img_three.active {
        top: -100px;
        width: 150px;
      }

      & .section_one_bg_img_four.active {
        top: 30px;
        right: -70px;
      }

      & .section_one_bg_img_five.active {
        bottom: -30px;
        width: 150px;
        right: -90px;
      }

      & .section_one_bg_img_two.active {
        width: 150px;
        bottom: -30px;
        left: -40px;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .product_page_wrapper {
    & .section_one_wrapper {
      & .section_one_content_container {
        padding-top: 30px;
        & .section_one_card {
          & .section_one_text {
            & h2 {
              font-size: 32px;
              padding-left: 10%;
              padding-right: 10%;
              margin-bottom: 15px;
            }
            & p {
              width: 345px;
              font-weight: 400;
              padding-bottom: 30px;
              font-size: 20px;
            }
          }
        }
      }
    }

    & .section_three_wrapper {
      & .section_three_content_container {
        top: 35%;
        & h2 {
          line-height: 37.5px;
        }
        & .buttons_wrapper {
          gap: 10px;
          & #button_cover_3 {
            width: 171px;
          }
          & #button_3 {
            width: 171px;
          }
        }
      }
    }
  }
}
