@import '../../styles/colours.scss';

.myPractice_tab {
  padding-top: 60px;
  background-color: #f8f8f8;
}

.myPractice_wrapper {
  position: relative;
  min-height: 72vh;

  & .grade_button_wrapper {
    text-transform: capitalize;
  }

  & .student_grade_wrapper {
    margin-top: 50px;

    button {
      background: #ffffff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
      border-radius: 14px;
      color: #000;
    }
  }

  & .topic_box {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 38px 35px;
    margin-bottom: 20px;
    // left box color
    border-left: 60px solid;
    border-left-color: $secondary_color;

    & .desktop_diagnose {
      visibility: visible;
      display: initial;
    }
    & .mobile_diagnose {
      visibility: hidden;
      display: none;
    }

    & .grade_number {
      position: absolute;
      top: 20px;
      left: -53px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #33cc94;
      & .number {
        padding-top: 10px;
        display: block;
        font-size: 32px;
        font-weight: bold;
      }
    }

    & .topic_control-mobile {
      visibility: hidden;
      display: none;
    }

    & .expansion_section {
      transition: all 2s linear;
      visibility: hidden;
      display: none;
      &.visible {
        visibility: visible;
        display: flex;
      }
    }

    & .title {
      margin-top: 0px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      text-transform: capitalize;
      & .button_wrapper {
        margin-left: 15px;
      }
    }
    & .title-disabled {
      color: #505d62;
    }
    & .disabled_icon {
      vertical-align: bottom;
      margin-right: 9px;
    }
    & .description {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: $secondary_text-color;
      max-width: 80%;
      max-height: 90px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      margin: 16px 0px;
    }
    & .disabled-description {
      color: #919a9e;
    }
    & .links_wrapper {
      cursor: default;
      padding: 10px 20px;
      display: block;
      text-decoration: none;
      border-radius: 10px;

      & .links {
        color: #172e38;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        text-decoration: underline;
      }

      & .circle_wrapper {
        display: inline-block;
        vertical-align: middle;
        margin-left: 15px;
      }
      & .grade_level_box {
        background: #f8f8f8;
        border-radius: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #19252a;
        padding: 5px 9px;
        text-decoration: none;
        & .level {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: #22805e;
          text-decoration: none;
        }
      }
    }
    & .box_image {
      position: absolute;
      bottom: 0px;
      left: -57px;
      width: 50px;
    }
  }

  & .my_plan_wrapper {
    padding: 50px 0;
  }
  & .reinforcement_box {
    padding: 32px 40px;
    background: #ffffff;
    box-shadow: 0px 3.002px 15.01px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    margin-bottom: 20px;

    & .skill_name {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #19252a;
    }

    & .grade_info_box {
      display: flex;
      flex-direction: row;
      gap: 10px;
      line-height: 20px;
      font-style: normal;
      font-weight: 600;
      align-items: center;
      padding: 5px 15px;
      background: #f8f8f8;
      border-radius: 4px;
      width: fit-content;
      margin-top: 10px;

      & .grade_number {
        font-size: 14px;
        color: #19252a;
      }
      & .grade_type {
        font-size: 12px;
        color: #22805e;
      }
    }

    & .skill_description {
      margin-top: 20px;
    }
  }
}

#subject_description_container {
  width: 510px;
}

#mobile_collapse {
  display: none;
  visibility: hidden;
}

#progress_mobile {
  display: none;
  visibility: hidden;
}

#desktop_collapse {
  display: initial;
  visibility: visible;
}

#progress_desktop {
  display: initial;
  visibility: visible;
}

@media only screen and (max-width: 960px) {
  .myPractice_wrapper .topic_box {
    // left box color
    border-left: 30px solid;
    border-left-color: $secondary_color;
    & .box_image {
      display: none;
    }
    & .topic_control-desktop {
      display: none;
      visibility: hidden;
    }
    .topic_control-mobile {
      display: inline-block;
      visibility: visible;
    }
    & .grade_label {
      visibility: hidden;
    }
    & .grade_number {
      left: -40px;
      top: 5px;
    }
  }
  #mobile_collapse {
    display: initial;
    visibility: visible;
  }
  #desktop_collapse {
    display: none;
    visibility: hidden;
  }

  #progress_desktop {
    display: none;
    visibility: hidden;
  }

  #progress_mobile {
    display: initial;
    visibility: visible;
  }
}

@media only screen and (max-width: 400px) {
  .myPractice_wrapper .topic_box {
    padding-right: 15px;
    padding-left: 15px;

    & .links_wrapper {
      padding-right: 5px;
      padding-left: 5px;
    }
    & .desktop_diagnose {
      visibility: hidden;
      display: none;
    }
    & .mobile_diagnose {
      visibility: visible;
      display: initial;
    }
  }
}
@media only screen and (max-width: 500px) {
  .myPractice_wrapper .topic_box {
    & .desktop_diagnose {
      visibility: hidden;
      display: none;
    }
    & .mobile_diagnose {
      visibility: visible;
      display: initial;
      padding-right: 10px;
    }
    & .diagnose_practice_buttons {
      margin-right: auto;
    }
  }
}

@media only screen and (max-width: 700px) {
  #expansion_container {
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  .button_expand_container {
    justify-content: flex-end !important;
    margin-top: 15px;
  }
  .expand_icon {
    // margin-left: auto !important;
  }
  #subject_description_container {
    h2 {
      margin-top: 35px;
    }
  }
}
