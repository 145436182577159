.billing-history-wrapper {
  padding-top: 5%;
  padding-bottom: 5%;
  background: #f8f8f8;
  height: 75vh;
  min-height: 500px;

  & .no-invoices {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #5d6970;
  }

  & .main_box {
    padding: 5% 10%;
    background: #ffffff;
    box-shadow: 0px 2px 4px 2px rgba(207, 207, 207, 0.25);
    border-radius: 10px;
    & h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 150%;
      color: #19252a;
    }
  }

  & .billing-table {
    width: 100%;
    border-collapse: collapse;
    & .header {
      text-align: left;
      border-bottom: 1px solid #ece8e8;

      & .header-items {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        align-items: center;
        color: #5d6970;
        padding-bottom: 15px;
      }
    }

    & .body-items {
      padding-top: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      align-items: center;
      color: #5d6970;
      text-transform: capitalize !important;

      & .view-button {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        align-items: center;
        text-decoration-line: underline;
        color: #172e38;
        background: none;
        border: none;
        cursor: pointer;
        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px #36a77e;
          border-radius: 2px;
          color: #0a5e3f !important;
        }
        &:hover {
          color: #0a5e3f !important;
        }
      }
    }
  }
}

.billing_dialog_wrapper {
  & .MuiDialog-paper {
    height: calc(80% - 40px) !important;
    margin: 10px !important;
  }
  & .receipt-frame {
    border: none;
    width: 100%;
    height: 1380px;
    margin-top: -200px;
  }
  & .MuiDialog-paper {
    border-radius: 10px;
  }
  & .close_dialog {
    position: absolute;
    right: 25px;
    top: 27px;
    border: none;
    background: none;
    cursor: pointer;
    text-align: center;
    padding-top: 3px;
    border: 2px solid transparent;

    &:focus {
      outline: none;
      border: 2px solid #19252a;
      border-radius: 4px;
    }
  }

  & .dialog_header {
    text-align: center;
    height: 50px;
    padding-bottom: 0px;
    & .billing_logo {
      position: absolute;
      top: 21px;
      left: 33px;
      width: 66px;
      height: 39;
    }
    & .dialog-title {
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 150%;
      color: #19252a;
      padding-top: 15px;
    }
  }

  & .invoice_wrapper {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #5d6970;
    text-align: center;
  }

  & .dialog_body {
    padding: 0px 33px 33px 33px;
  }

  & .top-content {
    padding-right: 15%;
    margin-top: 30px;
    & .cardInfo-wrapper {
      display: flex;
      align-items: center;
    }
    & h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
      text-transform: uppercase;
      color: #5d6970;
      margin-bottom: 0px;
    }
    & h4 {
      margin-top: 8px;
      margin-bottom: 0px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #576167;
    }

    & h5 {
      margin-top: 0px;
      margin-bottom: 0px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #576167;
    }

    & .visa-icon {
      width: 57px;
      height: 37px;
      margin-left: -10px;
    }
  }

  & .summary-wrapper {
    margin-top: 15px;
    & h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
      text-transform: uppercase;
      color: #5d6970;
      margin-top: 0px;
      margin-bottom: 5px;
    }
    & .plan-wrapper {
      background: #f8f8f8;
      padding: 18px 33px;

      & .title {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        color: #576167;
        margin: 0px;
      }

      & .sub-title {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #5d6970;
        margin: 0px;
      }

      & .text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #5d6970;
        margin: 0px;
        margin-top: 4px;
      }
    }
  }

  & .bottom-wrapper {
    & .contact {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #576167;
      padding: 20px 0px;
      & b {
        font-weight: 600;
        color: #36a77e;
      }
    }
    & .download {
      margin-top: 15px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: #36a77e;
      border: 2px solid transparent;
      background: none;
      margin-left: -5px;
      cursor: pointer;
      &:focus {
        outline: none;
        border: 2px solid #36a77e;
        border-radius: 4px;
      }
    }
    & .address {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: #5d6970;
      margin-top: 22px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .billing-history-wrapper {
    & .main_box {
      padding: 5%;
    }
  }
  .billing_dialog_wrapper {
    & .receipt-frame {
      height: 1450px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .billing-history-wrapper {
    & .main_box h1 {
      font-size: 25px;
    }
    & .billing-table {
      & .header {
        border: none;
        & .header-items {
          display: none;
          visibility: hidden;
        }
      }
      & .view-wrapper {
        text-align: end;
      }
      & .body-items .view-button {
        font-size: 14px;
      }
      & .body-items {
        font-size: 14px;
      }
    }
  }
  .billing_dialog_wrapper {
    & .top-content {
      padding-right: 0px;
    }
  }
}

@media only screen and (max-width: 450px) {
  .billing_dialog_wrapper {
    & .close_dialog {
      right: 7px;
    }
    & .MuiDialog-paper {
      width: calc(104% - 40px) !important;
      margin: 10px !important;
    }
    & .dialog_header {
      & .billing_logo {
        display: none;
        visibility: hidden;
      }
    }
    & .dialog_body {
      padding: 0px;
      & .top-content {
        padding-right: 0px;

        & h3 {
          font-size: 12px;
        }
        & h4,
        h5 {
          font-size: 14px;
        }

        & .visa-icon {
          width: 43px;
          height: 27px;
          margin-left: -6px;
        }
      }
    }
  }
}

@media only screen and (min-height: 980px) {
  .billing-history-wrapper {
    height: 85vh;
  }
}
