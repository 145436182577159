.cancelMembership {
  & .loading_image {
    width: 40px;
    height: 40px;
  }
  & .MuiDialog-paper {
    border-radius: 10px;
  }
  #dialog_title {
    padding: 32px 32px 16px 32px;
  }
  #dialog_body {
    padding: 0px 32px 10px 32px;
  }
  & .buttons-wrapper {
    padding: 30px 32px 34px 32px;
  }
  & .title {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 150%;
    color: #221e1e;
  }
  & .body-text {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #19252a;
  }
  & .close_dialog {
    position: absolute;
    right: 15px;
    top: 35px;
    border: none;
    background: none;
    cursor: pointer;
    text-align: center;
    padding-top: 3px;
    border: 2px solid transparent;

    &:focus {
      outline: none;
      border: 2px solid #19252a;
      border-radius: 4px;
    }
  }
}

.membership_billing_wrapper {
  padding-top: 6%;
  padding-bottom: 5%;
  background: #f8f8f8;

  & .billing_card_wrapper {
    margin-top: 60px;
  }

  & .main_box {
    padding: 5% 10%;
    background: #ffffff;
    box-shadow: 0px 2px 4px 2px rgba(207, 207, 207, 0.25);
    border-radius: 10px;
    & .mobile_add_member_button {
      width: 100%;
      justify-content: center;
      padding-top: 35px;
      display: none;
    }
    & .header_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 150%;
        color: #19252a;
      }
      & .desk_add_member_button {
        display: block;
      }
    }
    & h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 150%;
      letter-spacing: 0.005em;
      color: #19252a;
      margin-top: 35px;
      margin-bottom: 30px;
    }
  }
}

.editPayment {
  & .loading_image {
    width: 40px;
    height: 40px;
  }
  & .remove_confirm {
    text-transform: none !important;
  }
  & .MuiDialog-paper {
    border-radius: 10px;
  }
  #dialog_title {
    padding: 32px 32px 16px 32px;
  }
  #dialog_body {
    padding: 10px 32px 10px 32px;
  }
  & .buttons-wrapper {
    padding: 30px 32px 34px 32px;
  }
  & .edit_card_box {
    width: 380px !important;
  }
  & .payment-form {
    width: 300px !important;
  }
  & .close_dialog {
    top: 30px !important;
  }
  & .title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #221e1e;
  }
  & .body-text {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #19252a;
    // & .edit_card_box {
    //   width: 350px;
    // }
    & .remove_box {
      width: 380px;
      & .card_info {
        display: flex;
        gap: 10px;
        & .card_info_text {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #19252a;
        }
      }
      & .cancel_warning {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #19252a;
      }
      & .buttons-wrapper {
        display: flex;
        gap: 10px;
        padding: 0px;
        padding-top: 32px;
        padding-bottom: 20px;
        justify-content: right;
      }
    }
  }
  & .close_dialog {
    position: absolute;
    right: 15px;
    top: 35px;
    border: none;
    background: none;
    cursor: pointer;
    text-align: center;
    padding-top: 3px;
    border: 2px solid transparent;

    &:focus {
      outline: none;
      border: 2px solid #19252a;
      border-radius: 4px;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .membership_billing_wrapper {
    & .main_box {
      & h1 {
        font-size: 25px;
        line-height: 100%;
      }
      & h2 {
        font-size: 20px;
        line-height: 100%;
        margin-top: 35px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .membership_billing_wrapper {
    padding: 0px !important;
    & .main_box {
      border-radius: 0px;
      box-shadow: none;
      padding-left: 20px;
      padding-right: 20px;
      & .mobile_add_member_button {
        display: flex;
      }
      & .header_box {
        & .desk_add_member_button {
          display: none;
        }
      }
    }
  }
  .cancelMembership {
    & .MuiDialog-paper {
      margin: 10px;
    }
  }
  .MuiDialog-paper {
    margin: 12px !important;
  }
  // .MuiFormControl-root.MuiTextField-root.makeStyles-root-128 {
  //   width: 280px !important;
  // }
  .editPayment {
    & #dialog_body {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
    & .edit_card_box {
      width: 320px !important;
    }
    & .payment-form {
      width: 300px !important;
    }
    & .close_dialog {
      top: 30px !important;
    }
    & #dialog_title {
      padding: 32px 10px 15px !important;
    }
    & .makeStyles-root-135 {
      width: 100px;
    }
    & .makeStyles-root-132 {
      width: 100px;
    }
    & .remove_box {
      width: 320px !important;
      & .buttons-wrapper {
        padding-top: 10px !important;
      }
    }
  }
}
