@media only screen and (min-width: 1600px) {
  .section_5_wrapper {
    & .section_5_content {
      width: 1050px;
      left: 40%;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .section_5_wrapper .section_5_content {
    width: 90%;
  }
}

@media only screen and (max-width: 780px) {
  .section_5_wrapper .BrainhubCarouselItem--active .review_slides {
    height: 310px;
    width: 300px;
    & .review_slides_content_wrapper {
      padding-top: 0px;
      & .review_top_wrapper {
        margin-bottom: 10px;
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .section_5_wrapper .section_5_content {
    width: 100%;
  }
  .section_5_wrapper .BrainhubCarouselItem--active .review_slides {
    height: 310px;
    width: 300px;
    & .review_comment {
      font-size: 16px;
    }
    & .review_slides_content_wrapper {
      padding-top: 0px;
      & .review_top_wrapper {
        margin-bottom: 10px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .section_5_wrapper .BrainhubCarouselItem .review_slides {
    margin-top: 50px;
    padding-left: 8%;
    padding-top: 10px;
  }
}

@media only screen and (max-width: 560px) {
  .section_5_wrapper .section_5_content {
    left: 2%;
  }
  .section_5_wrapper .BrainhubCarouselItem .review_slides {
    & .review_comment {
      font-size: 13px;
    }
  }
  .section_5_wrapper .BrainhubCarouselItem--active .review_slides {
    width: 340px !important;
    margin-left: 40px;
    & .review_comment {
      font-size: 16px;
    }
    & .review_slides_content_wrapper {
      padding-top: 0px;
      & .review_top_wrapper {
        margin-bottom: 10px;
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  .section_5_wrapper .BrainhubCarouselItem .review_slides {
    margin-top: 50px;
    padding-left: 8%;
    padding-top: 10px;
    & .review_comment {
      font-size: 12px;
    }
  }
  .section_5_wrapper .BrainhubCarouselItem--active .review_slides {
    margin-left: 60px;
    & .review_comment {
      font-size: 16px;
    }
    & .review_slides_content_wrapper {
      padding-top: 0px;
      & .review_top_wrapper {
        margin-bottom: 10px;
      }
    }
  }
}
