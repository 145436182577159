.image_upload_button{
    border: none;
    background-color: white;
}

#add_button{
    cursor: pointer;
}

.image_list_container{
    display: flex;
    flex-direction: column;
    & .image_item{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 319px;
        & .remove_button{
            cursor: pointer;
        }
    }
}