@import '../../styles/colours.scss';

.signup_wrapper {
  background-image: url('/assets/images/registration-signup-desktop-svg.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 0% 68%;
  max-width: 1600px;
  height: 800px;

  & .tablet_image_wrapper {
    display: none;
    visibility: hidden;
  }

  & .back_image {
    visibility: hidden;
    height: 700px;
  }

  & .sign_up_box {
    padding-top: 160px;
  }

  & .sign_up_title_mobile {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #000000;
    max-width: 400px;
    margin-bottom: 0px;
    margin-top: 30px;
    visibility: hidden;
    display: none;
  }

  & .sign_up_title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 52px;
    display: flex;
    align-items: center;
    color: #000000;
  }

  & .sign_up_box_subtext {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 20px;
    color: #19252a;
    margin-bottom: 16px;
  }

  & .sign_up_have_account {
    text-align: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;

    & .login_button {
      background: none;
      border: none;
      cursor: pointer;
      color: #0a5e3f;
      text-decoration: underline;
      padding: 2px;
      font-size: 16px;
      border: 2px solid transparent;
      &:focus {
        border: 2px solid #2a8463;
        outline: none;
        border-radius: 4px;
        color: #2a8463;
        text-shadow: 1px 0 0 #2a8463;
      }
      &:hover {
        text-shadow: 1px 0 0 #2a8463;
        color: #2a8463;
      }
    }
  }

  & .account_type_button {
    width: 358px;
    height: 64px;
    background: #f8f8f8;
    border-radius: 7px;
    cursor: pointer;
    box-shadow: none;
    display: block;
    margin-bottom: 18px;
    position: relative;
    border: none;

    & .account_type_label {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 18px;
      color: #000000;
    }

    & .account_type_icon {
      width: 46px;
      height: 46px;
      position: absolute;
      left: 31px;
      top: 8px;
    }

    & .shadow_icon {
      width: 53px;
      height: 53px;
      top: 8px;
      left: 27px;
    }

    & .parent_shadow_icon {
      width: 53px;
      height: 53px;
      top: 9px;
      left: 28px;
    }

    &:focus {
      outline: none;
      background: #172e38;
      & .account_type_label {
        color: white;
      }
    }

    &:hover {
      outline: none;
      background: #172e38;
      & .account_type_label {
        color: white;
      }
    }
  }
}

@media only screen and (max-width: 1195px) {
  .signup_wrapper {
    background-position: -14% 68%;
  }
}

@media only screen and (max-width: 1110px) {
  .signup_wrapper {
    background-position: -27% 68%;
  }
}

@media only screen and (max-width: 1060px) {
  .signup_wrapper {
    background-position: 0% 48%;
    background-size: 500px;
    height: 550px;

    & .back_image {
      height: 300px;
    }

    & .sign_up_title {
      font-weight: 700;
      font-size: 35px;
      line-height: 32px;
    }
    & .sign_up_box_subtext {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #19252a;
      margin-bottom: 16px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .signup_wrapper {
    height: 100%;
    background: none;
    min-height: 80vh;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;

    & .sign_up_title {
      margin-bottom: 32px;
    }

    & .sign_up_title_mobile {
      display: visible;
      visibility: block;
    }
    & .sign_up_have_account {
      text-align: left;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    & .back_image {
      display: none;
      visibility: none;
    }

    & .trial_information_wrapper {
      max-width: 400px !important;
    }
    & .MuiBox-root.MuiBox-root-30 {
      margin-right: auto;
      margin-left: auto;
    }

    & .sign_up_box {
      margin-bottom: 20px;
      padding-top: 30%;
      margin-right: 0px !important;
      margin-left: 0px !important;
    }

    & .MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-8.MuiGrid-grid-lg-6 {
      display: none;
    }

    & .tablet_image_wrapper {
      display: block;
      visibility: visible;
      position: relative;
    }

    & .tablet_image {
      margin-top: 60px;
      position: absolute;
      left: -40px;
    }

    & .tablet_image_2 {
      position: absolute;
      left: 0px;
      top: 38%;
    }

    & .MuiGrid-root.MuiGrid-container.MuiGrid-align-items-xs-center {
      & .sign_up_box {
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 100px;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .signup_wrapper {
    & .tablet_image {
      width: 300px;
      top: 55px;
    }

    & .tablet_image_2 {
      width: 160px;
    }

    & .account_type_button {
      width: 280px;
    }
  }
}

@media only screen and (max-width: 650px) {
  .signup_wrapper {
    & .tablet_image_wrapper {
      display: none;
      visibility: hidden;
    }
    & .account_type_button {
      width: 380px;
    }
  }
}

@media only screen and (max-width: 430px) {
  .signup_wrapper {
    & .MuiGrid-root.MuiGrid-container.MuiGrid-align-items-xs-center {
      & .sign_up_box {
        margin-left: 5%;
        margin-right: 5%;
        padding-top: 35%;
        & .account_type_button {
          width: 300px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .signup_wrapper {
    & .sign_up_box {
      position: absolute;
      top: 25%;
      right: 25%;
    }
  }
}

@media only screen and (min-height: 1120px) {
  .signup_wrapper {
    height: 85vh;
    & .sign_up_box {
      position: absolute;
      top: 25%;
    }
    & .tablet_image {
      top: 22%;
    }
  }
}

@media only screen and (min-height: 1600px) {
  .signup_wrapper {
    height: 85vh;
    & .sign_up_box {
      position: absolute;
      top: 33%;
    }
  }
}
