.question_body_buttons {
  margin-top: 40px !important;
  margin-left: 0px !important;
}

.question_body_label {
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 28px;
  color: #000000;
  margin: 0px;
}

.question_body_description {
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 28px;
  color: #000000;
  margin: 15px 0 0 0;
}

.question_text_field_wrapper {
  margin-top: 20px;
}

.model_one_wrapper {
  max-width: 85% !important;
}

.question_image {
  max-width: 100%;
}

@media only screen and (max-width: 600px) {
  // .question_text_field_wrapper {
  //   margin-right: -90px;
  // }
  .question_body_label,
  .question_body_description {
    font-size: 17px;
  }
  .model_one_wrapper {
    max-width: 100% !important;
  }
}


@media only screen and (max-width: 410px) {
  .model_one_wrapper {
    max-width: 80% !important;
  }
}
