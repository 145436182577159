@import '../styles/colours.scss';

.header_wrapper {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 10px;
  padding-top: 2px;
  position: relative;
  z-index: 10;

  & .admin_name {
    background: none;
    border: none;
    box-shadow: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    margin-top: 28px;
    text-transform: capitalize;
    max-width: 200px;
  }

  & .header_items {
    font-family: 'Open Sans', sans-serif;
    background: none;
    border: none;
    padding: 27px 10px 10px 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    border-top: 4px solid transparent;
    cursor: pointer;
    &:focus {
      outline: none;
      border-top: 4px solid $primary_color;
      color: $primary_color;
    }
  }

  & .active_item {
    border-top: 4px solid $primary_color;
    color: $primary_color;
  }

  .header_profile {
    color: #19252a;
    padding-top: 13px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    & .user_image_wrapper {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #172e38;
      & .profile_picture {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      & .profile_icon_default {
        color: white;
        margin-top: 7px;
      }
    }
  }

  .profile_active {
    border-color: black !important;
  }

  .profile_header_closed {
    position: absolute;
    right: 300em;
  }

  .profile_header_wrapper {
    padding: 5px 36px;
    width: 290px;
    position: absolute;
    top: 75px;
    right: 0px;
    background: white;
    border: 1px solid #e0e0e0;
    z-index: 5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

    & .collapsed_menu_list {
      list-style-type: none;
      padding-left: 0px;

      & .collapsed_menu_items {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #000000;
        cursor: pointer;
        padding-bottom: 15px;
        &:focus {
          outline: none;
          color: #36a77e;
        }
        &:hover {
          color: #36a77e;
        }
      }

      & .collapsed_menu_items:last-child {
        padding-bottom: 0px;
      }
    }

    & .profile_division_wrapper {
      position: relative;
      margin: 0px 2% 0px 5%;
      & .division {
        border-left: 1px solid #dddddd;
        height: 147px;
        position: absolute;
        left: -28px;
        top: 33px;
      }
    }

    & .header_boxes_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 75px;
    }
  }

  .profile_header_wrapper_mobile {
    padding: 0px 20px 0px 20px;
    // overflow-x: scroll;

    & .switch_button_wrapper {
      margin-bottom: 25px;
    }

    & .profile_division_wrapper {
      position: relative;
      margin: 0px 2% 0px 5%;
      & .division {
        border-left: 1px solid #dddddd;
        height: 147px;
        position: absolute;
        left: -28px;
        top: 33px;
      }
    }

    & .header_boxes_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 75px;
    }
  }

  & .logo_wrapper {
    padding: 9px 40px 0 0;

    & .logo {
      cursor: pointer;
      width: 86px;
      &:focus {
        outline-offset: 2px;
        outline: 2px solid $primary_focus;
      }
    }
  }

  & .buttons_wrapper {
    padding: 0 5px;
  }

  & #signIn {
    padding-left: 15px;
  }

  & .buttons_spacing {
    margin-top: 18px;
    margin-left: 10px;
    margin-right: 10px;
  }

  & .buttons_menu_spacing {
    margin-top: 10px;
  }

  & .button_menu_wrapper {
    display: none;
    visibility: hidden;
    height: 39px;
    &:focus {
      outline: 2px solid $primary_focus;
    }
    &:hover {
      background-color: $primary_hover;
    }
  }

  & #login {
    padding-left: 20px;
  }

  & .division_wrapper {
    position: relative;
    & .division {
      border-left: 1px solid #dddddd;
      height: 25px;
      position: absolute;
      left: 50%;
      top: 12px;
    }
  }

  & .menu_sideBar {
    position: absolute;
    top: 0px;
    right: 300em;
    width: 70%;
    background: #ffffff;
    height: 100%;
    padding: 20px 24px;
    z-index: 2;
    & .close_menu {
      position: absolute;
      top: 20px;
      right: 30px;
      height: 35px;
    }

    & .navbar-nav-mobile {
      padding-left: 0px;
      padding-top: 5%;
      text-align: center;
      padding-bottom: 30px;
    }

    & .menu_nav_items {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
      cursor: pointer;
      padding-bottom: 15px;
      padding-top: 15px;
      text-align: start;
      border-top: 1px solid #ece8e8;
      &:focus {
        outline: none;
        color: #36a77e;
      }
      &:hover {
        color: #36a77e;
      }
    }

    & .menu_nav_items:last-child {
      padding-bottom: 0px;
    }

    & .division_line {
      border-top: 2px solid #dddddd;
      width: 25px;
      display: none;
      visibility: hidden;
    }
  }

  & .menu_open {
    right: 0px;
    z-index: 1000;
    overflow: scroll;
    height: 100vh;
  }
}

#side_menu_product,
#side_menu_pricing,
#side_menu_curriculum,
#side_menu_faq,
#side_menu_contact_us,
#side_menu_my_practice,
#side_menu_my_progress,
#side_menu_parent_summary,
#side_menu_parent_progress {
  visibility: hidden;
  display: none;
}

.header_opacity {
  background: border-box;
}

#header_text_field {
  padding-top: 11px;
}

.navbar-nav-mobile ::marker {
  content: '';
}

@media only screen and (max-width: 1500px) {
  .header_wrapper .profile_header_wrapper .header_boxes_wrapper {
    gap: 30px;
  }
}

@media only screen and (max-width: 1190px) {
  .header_wrapper .profile_header_wrapper .profile_division_wrapper .division {
    left: -5px;
  }
}

@media only screen and (max-width: 1132px) {
  .header_wrapper .profile_header_wrapper {
    & .profile_division_wrapper {
      display: none;
      visibility: hidden;
    }
  }
}

@media only screen and (max-width: 1080px) {
  #login,
  #signIn,
  #profile_button,
  #division,
  #subscription,
  .profile_header_wrapper {
    display: none;
    visibility: hidden;
  }
  #side_menu_login {
    margin-bottom: 20px;
    margin-top: 10px;
  }

  #side_menu_login,
  #side_menu_signIn,
  #side_menu_logout,
  #side_menu_division {
    visibility: visible;
    display: block;
  }

  #side_menu_signIn,
  #side_menu_logout,
  #side_menu_subscription {
    display: inline;
  }

  .side_menu_subscription_wrapper {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .header_wrapper {
    & .button_menu_wrapper {
      display: inline-block;
      visibility: visible;
    }
  }
}

@media only screen and (max-width: 940px) {
  #division {
    display: none;
    visibility: hidden;
  }
}

@media only screen and (max-width: 1010px) {
  .header_wrapper {
    padding-right: 5%;
    padding-left: 5%;
  }
}

@media only screen and (max-width: 730px) {
  #side_menu_onboarding,
  #side_menu_profile,
  #side_menu_subscription {
    visibility: visible;
    display: inline-block;
  }
  #onboarding,
  #profile,
  #subscription {
    display: none;
    visibility: hidden;
  }
}

@media only screen and (max-width: 850px) {
  #product,
  #pricing,
  #curriculum,
  #faq,
  #my_practice,
  #contact_us,
  #my_progress,
  #parent_summary,
  #parent_progress {
    display: none;
    visibility: hidden;
  }

  #side_menu_product,
  #side_menu_pricing,
  #side_menu_curriculum,
  #side_menu_faq,
  #side_menu_contact_us,
  #side_menu_my_practice,
  #side_menu_my_progress,
  #side_menu_parent_summary,
  #side_menu_parent_progress {
    visibility: visible;
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  #header_text_field {
    visibility: hidden;
    display: none;
  }
}

@media only screen and (max-width: 482px) {
  .header_wrapper {
    padding-bottom: 5px;
    & .logo_wrapper {
      padding: 5px 40px 0 0;
      & .logo {
        width: 85px;
        height: 55px;
      }
    }
    & .buttons_menu_spacing {
      margin-top: 6px;
    }
  }
}
