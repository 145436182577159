.practice_portal_wrapper {
  background: #19252a;
  min-height: 100vh;

  & .practice_portal_body {
    padding-top: 50px;
  }

  & .practice_portal_content {
    padding: 9%;
    text-align: center;
    position: relative;
    background-image: url('/assets/images/card_one_back.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-size: auto 100%;
    height: 555px;
    width: 890px;

    & .separation_icon {
      margin-bottom: 5px;
    }

    & .title {
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 36px;
      color: #19252a;
    }

    & .sub_title {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #252121;
    }

    & .Info_text {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      color: #19252a;
    }
    & .skip_button {
      border: none;
      box-shadow: none;
      background: none;
      color: #19252a;

      &:hover,
      :focus {
        color: #19252a;
        box-shadow: 0px 2.62009px 13.1004px rgba(0, 0, 0, 0.15);
      }
    }
  }
}

@media only screen and (max-width: 1080px) {
  .practice_portal_wrapper {
    & .practice_portal_content {
      padding: 12%;
      background-size: auto 90%;
    }
  }
}

@media only screen and (max-width: 980px) {
  .practice_portal_wrapper {
    padding: 0 !important;
    & .practice_portal_content {
      padding: 15%;
      background-size: auto 80%;
      & .title {
        font-size: 26px;
      }
      & .Info_text {
        font-size: 18px;
      }
    }
  }
}

@media only screen and (max-width: 880px) {
  .practice_portal_wrapper {
    & .practice_portal_body {
      padding: 0;
    }
    & .practice_portal_content {
      padding: 14%;
    }
  }
}

@media only screen and (max-width: 710px) {
  .practice_portal_wrapper {
    & .practice_portal_content {
      padding: 17%;
    }
  }
}

@media only screen and (max-width: 690px) {
  .practice_portal_wrapper {
    & .practice_portal_content {
      padding: 17%;
    }
  }
}

@media only screen and (max-width: 690px) {
  .practice_portal_wrapper {
    & .practice_portal_content {
      padding: 20%;
      background-size: auto 70%;
      & .title {
        font-size: 20px;
      }
      & .sub_title {
        font-size: 12px;
      }
      & .Info_text {
        font-size: 16px;
      }
    }
  }
}

@media only screen and (max-width: 630px) {
  .practice_portal_wrapper {
    min-height: fit-content;
    & .practice_portal_content {
      padding: 20% 20px;
      height: 72vh;
      background: none;
      & .separation_icon {
        margin-bottom: 2px;
      }

      & .title {
        font-size: 28px;
        color: white;
      }
      & .sub_title {
        font-size: 14px;
        color: white;
      }
      & .Info_text {
        font-size: 20px;
        color: white;
      }
      & .skip_button {
        color: white;
        border: none;
        box-shadow: none;
        background: none;
      }
    }
  }
}

@media only screen and (max-width: 440px) {
  .practice_portal_wrapper {
    & .practice_portal_content {
      padding: 66px 20px;
      background-size: 121% 84%;
      background-position: -57px 16%;
      & .title {
        font-size: 20px;
      }
      & .sub_title {
        font-size: 14px;
      }
      & .Info_text {
        font-size: 16px;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .practice_portal_wrapper {
    & .practice_portal_content {
      padding: 66px 20px;
      background-size: 127% 84%;
      background-position: -57px 32%;
    }
  }
}
