.contact-us-form {
  background: #ffffff;
  box-shadow: 0px 0px 8.21492px 4.10746px rgba(180, 180, 180, 0.2);
  border-radius: 10px;
  padding: 5% 10%;

  & .card-title {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 150%;
    color: #19252a;
    margin-bottom: 20px;
  }

  & .toggle-buttons {
    padding: 5px 5px 30px 5px;
    overflow: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    &::-webkit-scrollbar {
      background: transparent; /* Chrome/Safari/Webkit */
      width: 0px;
    }
  }
}

@media only screen and (max-width: 750px) {
  .contact-us-form {
    padding: 5% 10% 8% 10%;
  }
}

@media only screen and (max-width: 500px) {
  .contact-us-form {
    padding: 5% 10% 14% 10%;
  }
}
