.header_dark {
  background: #19252a !important;

  & .profile_header_wrapper {
    border-color: #19252a;

    & .collapsed_menu_list .collapsed_menu_items {
      color: white;
    }
  }

  & .header_items {
    color: #ffffff !important;
  }
  & .active_item {
    color: #36a77e !important;
  }
  & .header_profile {
    color: #ffffff !important;
  }

  & .profile_header_wrapper {
    background: #19252a !important;

    & .header_box_title,
    .header_box_text,
    .profile_header_links {
      color: #ffffff !important;
    }
  }
}
