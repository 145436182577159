// "page_wrapper" is the main alignment to be implemented in every new page created to keep a consistent page layout thought the application.
.page_wrapper {
  max-width: 1600px;
  margin: auto;
  position: relative;
  padding-left: 10%;
  padding-right: 10%;
  // min-height: 70vh;
}

@media only screen and (min-width: 1600px) {
  .page_wrapper {
    max-width: 1600px;
    margin: auto;
    position: relative;
    padding-left: 159px;
    padding-right: 159px;
  }
}

@media only screen and (max-width: 810px) {
  .page_wrapper {
    padding-right: 5%;
    padding-left: 5%;
  }
}

@media only screen and (max-width: 600px) {
  .page_wrapper {
    padding-left: 22px;
    padding-right: 22px;
  }
}

.m_top_1 {
  margin-top: 10px !important;
}

.m_top_2 {
  margin-top: 20px !important;
}

.m_top_3 {
  margin-top: 30px !important;
}

.m_top_4 {
  margin-top: 40px !important;
}

.m_top_5 {
  margin-top: 50px !important;
}

.m_top_6 {
  margin-top: 60px !important;
}

.m_top_9 {
  margin-top: 90px !important;
}

.m_bottom_0 {
  margin-bottom: 0px !important;
}

.m_bottom_1 {
  margin-bottom: 10px !important;
}

.m_bottom_2 {
  margin-bottom: 20px !important;
}

.m_bottom_3 {
  margin-bottom: 30px !important;
}

.m_bottom_4 {
  margin-bottom: 40px !important;
}

.m_bottom_5 {
  margin-bottom: 50px !important;
}

.m_bottom_6 {
  margin-bottom: 60px !important;
}

.m_0 {
  margin: 0px !important;
}

.m_right_2 {
  margin-right: 20px;
}

.m_left_1 {
  margin-left: 10px;
}

.m_left_15 {
  margin-left: 15px;
}

.p_left_20 {
  padding-left: 20px;
}

.p_right_20 {
  padding-right: 20px;
}

.min_h_vh_100 {
  min-height: 100vh;
}
