@import '../../../styles/colours.scss';

.carousel_box {
  background: #f8f8f8;
  border-radius: 20px;
  padding: 35px;
  margin: 40px 20px 20px 20px !important;
  position: relative;
  height: 300px;
  & .new_button {
    background-color: #ffa602;
    border-radius: 39px;
    padding: 5px 10px;
    width: 70px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #19252a;
  }
  & .carousel_title {
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    color: #000000;
    margin-top: 10px;
  }
  & .carousel_body {
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    color: #19252a;
    max-width: 300px;
    margin-top: 16px;
    height: 170px;
    overflow: hidden;
  }
  & .learn_more-wrapper {
    margin-top: 8px;
  }
  & .learn_more {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #2a8463;
    cursor: pointer;
  }

  & .imageUrl {
    position: absolute;
    top: 40px;
    right: 10px;
  }
  & .bigger_image {
    height: 220px;
    top: 50px;
  }
}

.BrainhubCarousel__dots {
  margin-top: 70px;
}

.BrainhubCarousel__dot {
  background: none;
}

.BrainhubCarousel__dot:before {
  width: 16px !important;
  height: 16px !important;
  background: #c4c4c4 !important;
}

.BrainhubCarousel__dot--selected {
  opacity: 0 !important;
  &.BrainhubCarousel__dot:before {
    background: $primary-color !important;
  }
}

.section_5_wrapper {
  & .review_slides {
    border-radius: 12px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07) !important;
    transition: all 0.5s ease-in-out;
    z-index: 5;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  // & .BrainhubCarouselItem {
  //   width: 400px !important;
  //   min-width: 400px !important;
  // }
  .BrainhubCarouselItem--active {
    margin-left: 10px !important;
    margin-right: 10px !important;
    & .review_slides {
      width: 400px;
      height: 390px;
      z-index: 5;
      padding-left: 8%;
      padding-right: 8%;
      & .review_title {
        transition-delay: 350ms;
        transition-property: font-size;
        font-size: 20px;
      }
      & .review_comment {
        transition-delay: 350ms;
        transition-property: font-size;
        font-size: 18px;
        line-height: 150%;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
      }
      & .review_slides_content_wrapper {
        & .review_top_wrapper {
          margin-bottom: 21px;
          & .star_review_wrapper {
            padding-bottom: 5px;
          }
          & .review_top_container {
            & h2 {
              transition-delay: 350ms;
              transition-property: font-size;
              font-size: 22px;
              line-height: 150%;
            }
            & p {
              transition-delay: 350ms;
              transition-property: font-size;
              font-size: 18px;
              line-height: 150%;
            }
          }
        }
      }
    }
  }
  & .star_review_wrapper {
    display: flex;
    gap: 7.67px;
  }
}

#marketing_carousel {
  .BrainhubCarousel {
    height: 450px;
  }

  .BrainhubCarousel__trackContainer {
    height: 680px;
  }

  .BrainhubCarousel__track {
    height: 680px;
  }
  & .marketing_carousel_button_wrapper {
    padding-top: 4px;
    background: none;
    border: none;
    cursor: pointer;

    &:focus {
      outline: 2px solid $primary_focus;
    }
    &:hover {
      background-color: $primary_hover;
    }
  }
  .marketing_carousel_dots {
    display: flex;
    padding-top: 35px;
    gap: 15px;
    margin-left: 80%;
    & .arrow_container {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 1600px) {
  #marketing_carousel {
    .marketing_carousel_dots {
      margin-left: 60%;
    }
  }
}

@media only screen and (max-width: 1500px) {
  #marketing_carousel {
    .marketing_carousel_dots {
      margin-left: 60%;
    }
  }
}

@media only screen and (max-width: 1300px) {
  #marketing_carousel {
    .marketing_carousel_dots {
      margin-left: 50%;
    }
  }
}

@media only screen and (max-width: 1100px) {
  #marketing_carousel {
    .marketing_carousel_dots {
      margin-left: 60%;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .carousel_box {
    & .imageUrl {
      top: 70px;
      width: 150px;
    }
  }
}

@media only screen and (max-width: 1300px) {
  .carousel_box {
    & .carousel_body {
      max-width: 350px;
    }
    & .imageUrl {
      position: absolute;
      top: 40px;
      right: 60px;
      width: 180px;
    }
  }
}

@media only screen and (max-width: 1100px) {
  #marketing_carousel {
    .BrainhubCarousel {
      height: 380px;
    }

    .BrainhubCarousel__trackContainer {
      height: 450px;
    }

    .BrainhubCarousel__track {
      height: 450px;
    }
    .marketing_carousel_dots {
      margin-left: 75%;
      padding-top: 5px;
    }
  }
  .section_5_wrapper {
    .BrainhubCarouselItem--active {
      & .review_slides {
        width: 400px;
        height: 309px;
        padding-right: 25px;
        padding-left: 25px;
        margin-bottom: 20px;
      }
    }
    & .star_review_wrapper {
      display: flex;
      gap: 7.67px;
    }
  }
}

@media only screen and (min-width: 1000px) {
  .section_5_wrapper {
    & .BrainhubCarouselItem {
      // margin-left: -100px;
    }
  }
}

@media only screen and (max-width: 850px) {
  .carousel_box {
    margin: 0 !important;
    & .carousel_body {
      max-width: 300px;
    }
    & .imageUrl {
      position: absolute;
      top: 40px;
      right: 60px;
    }
  }
}

@media only screen and (max-width: 750px) {
  .carousel_box {
    & .imageUrl {
      top: 40px;
      right: 10px;
    }
  }
}

@media only screen and (max-width: 750px) {
  .carousel_box {
    & .imageUrl {
      height: 200px;
      top: 60px;
    }
  }
  .section_5_wrapper {
    .BrainhubCarouselItem--active {
      & .review_slides {
        width: 256px;
        height: 269px;
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    & .star_review_wrapper {
      display: flex;
      gap: 7.67px;
    }
  }
}

@media only screen and (max-width: 600px) {
  #marketing_carousel {
    & .BrainhubCarousel__track {
      height: 380px;
    }
  }
}

@media only screen and (max-width: 560px) {
  .carousel_box {
    margin: 0 !important;
    height: 280px;
    & .imageUrl {
      top: 145px;
      right: 0px;
      height: 120px;
    }
    & .carousel_body {
      font-size: 14px;
      max-width: 200px;
      margin-top: 10px;
      font-style: normal;
      font-weight: normal;
      line-height: 20px;
      height: 150px;
    }
    & .carousel_title {
      font-size: 23px;
      margin-top: 10px;
    }
    & .learn_more-wrapper {
      margin-top: 20px;
    }
  }
  .BrainhubCarousel__dots {
    margin-top: 15px;
  }
  .review_slides {
    width: 200px !important;
  }
}

@media only screen and (max-width: 400px) {
  .review_slides {
    width: 256px !important;
  }

  .section_5_content {
    & .BrainhubCarousel__track {
      margin-left: 4% !important;
    }
  }
  .marketing_carousel_dots {
    display: none;
  }
}
