@import '../../styles/colours.scss';

.student_registration_wrapper {
  display: flex;
  justify-content: center;
  height: 850px;
  align-items: center;
  & .info_box {
    display: flex;
    width: 1109px;
    height: 466px;
    box-shadow: 0px 0px 8.21492px 4.10746px rgba(180, 180, 180, 0.2);
    background-image: url('/assets/images/student_signup_infobox_bg_desktop.png');
    & .text_box {
      width: 40%;
      height: 75%;
      margin-left: 50%;
      margin-top: 8%;
      display: flex;
      flex-direction: column;
      & .ask_parent {
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: #000000;
        width: 355px;
        height: 76px;
      }
      & .parent_must_activate {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        margin-top: 8px;
        margin-bottom: 14px;
        height: 48px;
        width: 372px;
      }

      & .share_code {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        margin-top: 16px;
        margin-bottom: 12px;
        width: 372px;
        height: 24px;
        left: 53.5%;
        top: 39%;
      }
      & .code_box {
        background-color: #eff8f5;
        width: 309px;
        height: 71px;
        font-size: 25px;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 0px;
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
      }
    }
    & .info_box_img {
      width: 463px;
      height: 356px;
      margin: 50px 50px 50px 50px;
      position: absolute;
    }
    & .parent_box_img {
      width: 375px;
      height: 303.75px;
      top: 15%;
      left: 7%;
      position: absolute;
    }
    & .info_box_button {
      margin-bottom: 61px;
    }
    & .box_button {
      text-transform: none;
    }
    & .parent_already_has {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      width: fit-content;
      color: #172e38;

      &:hover {
        color: $primary_focus;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .student_registration_wrapper {
    & .info_box {
      width: 793.75px;
      height: 333.5px;

      & .info_box_img {
        width: 328px;
        height: 257.2px;
      }
      & .info_box_button {
        margin-bottom: 13px;
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .student_registration_wrapper {
    background-image: url('/assets/images/student_signup_infobox_bg_mobile.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 813px;
    margin-bottom: 0px;
    & .info_box {
      width: 100%;
      height: 813px;
      background-image: none;
      box-shadow: none;
      flex-direction: column;
      justify-content: flex-start;
      overflow: hidden;
      align-items: center;
      & .text_box {
        width: unset;
        height: unset;
        justify-content: center;
        margin-left: unset;
        margin-top: 82px;
        margin-bottom: 75px;
        align-items: center;
        text-align: center;
      }
      & .share_code {
        text-align: center;
      }
      & .info_box_img {
        position: relative;
        margin: unset;
        width: 398px;
        height: 327.2px;

        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
.student_registration_wrapper{ 
  height: 715px;
  & .info_box{ 
    & .info_box_img { 
      width: 328px;
      height: 257px;
      }
    }
  }
}