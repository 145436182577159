@import '../../styles/colours.scss';

.signUp_message_wrapper {
  min-height: 100%;
  display: flex;
  height: 700px;
  justify-content: center;
  align-items: center;
  & .info_box {
    width: 625px;
    height: 564px;
    box-shadow: 0px 0px 8.21492px 4.10746px rgba(180, 180, 180, 0.2);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
  & .almost_there {
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 42px;
    margin-bottom: 24px;
  }
  & .sent_email {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: 41px;
  }
  & .email {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0px;
    text-align: center;
  }
  & .box_image {
    margin-top: 90px;
  }
  & .email_verification_button {
    text-transform: none;
  }
  & .resend_email {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #2a8463;
    margin-top: 43px;
    text-decoration: none;
  }
}
@media only screen and (min-height: 1000px) and (max-height: 800px) {
  .signUp_message_wrapper {
    height: 1200px;
  }
}

@media only screen and (max-width: 750px) {
  .signUp_message_wrapper {
    justify-content: flex-start;
    height: 700px;
    & .info_box {
      width: 100%;
      box-shadow: none;
      & .almost_there {
        font-size: 25px;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 0px;
        text-align: left;
      }
      & .sent_email {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #19252a;
      }
      & .email {
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: center;
        color: #19252a;
      }
      & .box_image {
        margin-top: 77px;
      }
    }
  }
}
@media only screen and (max-width: 1200px) and (min-height: 900px) {
  .signUp_message_wrapper {
    height: 810px;
  }
}
@media only screen and (max-width: 600px) {
  .signUp_message_wrapper {
    height: 564px;
  }
}
