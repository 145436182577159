.billing_card_wrapper {
  background: #ffffff;
  box-shadow: 0px 2px 4px 2px rgba(207, 207, 207, 0.25);
  border-radius: 10px;
  padding: 34px 65px;
  width: 100%;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px white, 0 0 0 4px #36a77e;
  }

  & h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 32px;
  }

  & .charge_date {
    background: #f1eff0;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    align-items: center;
    color: #19252a;
    padding: 6px 24px;
    width: fit-content;
    & b {
      margin-left: 3px;
    }
    margin-bottom: 20px;
  }

  & .view_history {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: #172e38;
    background: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px white, 0 0 0 4px #36a77e;
      border-radius: 2px;
      color: #0a5e3f !important;
    }
    &:hover {
      color: #0a5e3f !important;
    }
  }

  & .edit {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: #33cc94;
    background: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px white, 0 0 0 4px #36a77e;
      border-radius: 2px;
      color: #0a5e3f !important;
    }
    &:hover {
      color: #0a5e3f !important;
    }
  }

  & .card_wrapper {
    display: flex;
    gap: 15px;
    & h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #19252a;
      margin: 0px;
    }
    & h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      display: flex;
      align-items: center;
      color: #979797;
      margin-top: 8px;
      margin-bottom: 0px;
    }
  }
}

@media only screen and (max-width: 1380px) {
  .billing_card_wrapper {
    padding: 34px;
  }
}

@media only screen and (max-width: 1280px) {
  .billing_card_wrapper {
    & .billing_card {
      min-width: 490px;
      padding-bottom: 30px;
    }
  }
}

@media only screen and (max-width: 860px) {
  .billing_card_wrapper {
    padding: 30px 20px;
    & .billing_card {
      min-width: 100%;
      padding-bottom: 30px;
    }
  }
}
