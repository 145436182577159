@import '../../../styles/colours.scss';

.sq-input {
  border-radius: 8px;
  padding: 14px !important;
}

.sq-input--focus {
  outline: none;
  box-shadow: 0 0 0 2px $primary_color !important;
}

.billing_card_container {
  background-color: white;
  border: 4px solid #6ff2c2;
  box-sizing: border-box;
  width: 456px;
  height: 512px;
  filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.1));
  border-radius: 7px;
  & .billing_card_content {
    padding-left: 5%;
    padding-right: 5%;
    & .billing_card_title {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #221e1e;
      padding-top: 36px;
      margin: 0 0 0 0;
      padding-bottom: 15px;
    }
    & .student_number_container {
      display: flex;
      align-items: center;
      gap: 20px;
      & .billing_card_icons {
        width: 45px;
        height: 45px;
      }
      & .student_number_container {
        width: 104px;
        height: 50px;
        background: #ffffff;
        border: 1px solid #d3d3d3;
        box-sizing: border-box;
        border-radius: 4px;
        & p {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  & .billing_information_container {
    padding-top: 36px;
    display: grid;
    grid-template-columns: auto auto;
    & .part_one {
      & h2 {
        font-size: 23px;
        color: #252b42;
        margin: 0 0 0 0;
        line-height: 28px;
      }
      & p {
        font-size: 12px;
        margin: 0 0 0 0;
        color: #252b42;
        font-weight: 400;
        line-height: 16px;
      }
      & h3 {
        margin: 0 0 0 0;
        font-size: 12px;
        font-weight: bold;
        color: #252b42;
        line-height: 18px;
      }
    }
    & .part_two {
      & h2 {
        display: inline-block;
        font-weight: 800;
        font-size: 65px;
        line-height: 66px;
        color: #33cc94;
        margin: 0 0 0 0;
      }
      & p {
        display: inline-block;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 33px;
        color: #252b42;
        margin: 0 0 0 0 !important;
      }
    }
    & .part_three {
      padding-top: 36px;
      & h2 {
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #a6a6a6;
      }
    }
    & .part_four {
      font-weight: 600;
      font-size: 12px;
      line-height: 100%;
      padding-top: 50px;
      & h3 {
        color: #374754;
        margin: 0 0 0 0;
        padding-bottom: 20px;
      }
      & p {
        color: #a6a6a6;
        margin: 0 0 0 0;
      }
    }
    & .part_five {
      padding-top: 50px;
      & h3 {
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #374754;
        margin: 0 0 0 0;
      }
    }
    & .part_six {
      padding-top: 40px;
      & p {
        font-weight: 600;
        font-size: 15px;
        line-height: 100%;
        color: #374754;
      }
    }
  }
  & .line {
    display: block;
    border: 0;
    border: 1px solid rgba(196, 196, 196, 0.44);
    margin: 1em 0;
    padding: 0;
  }
  & .payment_notice {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #374754;
  }
}

.pay-button {
  width: 100%;
  background-color: #6ff2c2;
  height: 44px;
  color: #172e38;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  border-radius: 4px;
  border: 0px;
  cursor: pointer;
}

.button_wrapper {
  text-align: center;
  & .loading_image {
    width: 40px;
    height: 40px;
  }
}

.button_wrapper_edit {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  & .loading_image {
    width: 40px;
    height: 40px;
  }
  & .edit_remove_button {
    background: none;
    border: none;
    color: #ef4741;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin: auto auto auto 0;
    cursor: pointer;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px white, 0 0 0 4px #ef4741;
      border-radius: 2px;
      color: #ef4741 !important;
    }
    &:hover {
      color: #ef4741 !important;
    }
  }
}
