@import '../../styles/colours.scss';

.curriculum_wrapper {
  & .curriculum_select_grade_wrapper {
    padding-bottom: 30px;
    padding-top: 30px;
    & .grade_title {
      font-style: normal;
      font-weight: 400;
      font-size: 25px;
      line-height: 28px;
    }
  }

  & .ontario_curriculum_wrapper {
    background: #def0e9;
    margin-top: 28px;
    padding-bottom: 25px;

    & .title {
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 150%;
      color: #000000;
      margin: 15px 0 15px 0;
    }

    & .body {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      color: #000000;
      max-width: 720px;
    }

    & .mobile_icon {
      visibility: hidden;
      display: none;
    }
  }

  & .section_one_wrapper {
    position: relative;
    //background images
    & .section_one_bg_img_one {
      position: absolute;
      top: -50px;
      left: -50px;
      z-index: -1;
      transition: all 0.3s;
    }
    & .section_one_bg_img_one.active {
      top: -40px;
      left: -10px;
      transform: rotate(-45deg);
    }
    & .section_one_bg_img_two {
      position: absolute;
      bottom: -100px;
      left: 50px;
      z-index: -1;
      transition: all 0.3s;
    }
    & .section_one_bg_img_two.active {
      bottom: -70px;
      left: 0px;
      transform: rotate(45deg);
    }
    & .section_one_bg_img_three {
      position: absolute;
      top: -100px;
      left: 40%;
      z-index: -1;
      transition: all 0.3s;
    }
    & .section_one_bg_img_three.active {
      top: -130px;
      left: 45%;
      transform: rotate(-45deg);
    }
    & .section_one_bg_img_four {
      position: absolute;
      top: -80px;
      right: 10%;
      z-index: -1;
      transition: all 0.3s;
    }
    & .section_one_bg_img_four.active {
      top: -80px;
      right: 12%;
      transform: rotate(-55deg);
    }
    & .section_one_bg_img_five {
      position: absolute;
      bottom: -100px;
      right: 10px;
      z-index: -1;
      transition: all 0.3s;
    }
    & .section_one_bg_img_five.active {
      bottom: -80px;
      right: 10px;
      transform: rotate(-20deg);
    }
    //background images done
    & .section_one_content_container {
      display: flex;
      position: relative;
      margin-left: 0;
      margin-right: 0;
      padding-top: 126px;
      padding-bottom: 2%;
      justify-content: space-between;

      & .curriculum_top_bg {
        width: 550px;
        height: 400px;
      }

      & .section_one_card {
        padding-right: 40px;
        & .section_one_text {
          & h2 {
            font-weight: 700;
            font-size: 40px;
            line-height: 52px;
            color: #172e38;
          }
          & h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 27px;
            color: #172e38;
            margin-bottom: 40px;
            max-width: 380px;
          }
        }
        & .section_one_buttons_wrapper {
          display: flex;
          gap: 20px;
          & .sign_up_button_cover {
            width: 187px;
            height: 57px;
            border: 1.5px solid #19252a;
            box-sizing: border-box;
            border-radius: 10px;
            transform: rotate(-4deg);
            position: absolute;
            transition: all 0.3s;
          }
          & .sign_up_button_cover.active {
            transform: rotate(0deg);
          }
        }
      }
    }
  }

  & .section_three_wrapper {
    position: relative;
    background-color: white;
    & .section_three_background {
      padding-top: 80px;
      width: 100%;
      bottom: 0px;
    }
    & .section_three_content_container {
      left: 0;
      right: 0;
      top: 40%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 70px;
      position: absolute;
      & h2 {
        text-align: center;
        font-weight: 700;
        font-size: 40px;
        line-height: 52px;
        color: #19252a;
        margin: 0 0 0 0;
      }
      & .buttons_wrapper {
        padding-top: 80px;
        display: flex;
        gap: 20px;
        justify-content: center;
        & .sign_up_button_wrapper {
          cursor: pointer;
          & .sign_up_button {
            border: 0px solid #172e38;
            color: #172e38;
            background-color: #6ff2c2;
            width: 187px;
            height: 57px;
            border-radius: 10px;
            transform: all 0.3s;
          }
          & .sign_up_button.active {
            background-color: #31d196;
          }
          & .sign_up_button_cover {
            width: 187px;
            height: 57px;
            border: 1.5px solid #19252a;
            box-sizing: border-box;
            border-radius: 10px;
            transform: rotate(-4deg);
            position: absolute;
            transition: all 0.3s;
          }
          & .sign_up_button_cover.active {
            transform: rotate(0deg);
          }
        }
        & .demo_button {
          width: 187px;
          height: 57px;
          border-radius: 10px;
          transition: all 0.2s;
        }
        & .demo_button:hover {
          color: white;
          background-color: #172e38;
        }
      }
    }
  }
}

.course_box_wrapper {
  margin-top: 28px;
  padding: 23px 10%;
}

.course_box {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 38px 35px;
  margin-bottom: 20px;
  & .mobile_box_controls {
    visibility: hidden;
    display: none;
  }

  & .expansion_section {
    transition: all 2s linear;
    visibility: hidden;
    display: none;
    position: relative;
    &.visible {
      visibility: visible;
      display: flex;
    }
  }

  & .circle {
    padding-top: 4px;
  }

  & .practice_button {
    margin-left: 15px;
  }

  & .title {
    margin-top: 0px;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 150%;
    color: #19252a;
    opacity: 0.9;
    text-transform: capitalize;
    & .button_wrapper {
      margin-left: 15px;
    }
  }
  & .title-disabled {
    color: #505d62;
  }
  & .disabled_icon {
    vertical-align: bottom;
    margin-right: 9px;
  }
  & .description {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #5d6970;
    max-width: 100%;
    max-height: 90px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin: 16px 0px;
  }
  & .disabled-description {
    color: #919a9e;
  }
  & .links_wrapper {
    cursor: default;
    padding: 8px 0px;
    display: block;
    text-decoration: none;

    & .skills_button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0px 5px;
      text-align: left;
      &:focus {
        outline: 2px solid $primary_focus;
      }
      &:hover {
        background-color: $primary_hover;
      }
    }

    & .no_interaction {
      &:focus {
        outline: none;
      }
      &:hover {
        background-color: none !important;
      }
    }
    & .list_dot {
      margin-right: 5px;
    }
    & .links {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      text-decoration-line: underline;
      color: #172e38;
      opacity: 0.9;
      text-decoration: underline;
    }
    & .circle_wrapper {
      display: inline-block;
      vertical-align: middle;
      margin-left: 15px;
    }
    & .grade_level_box {
      background: #f8f8f8;
      border-radius: 4px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #19252a;
      padding: 5px 9px;
      text-decoration: none;
      & .level {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #22805e;
        text-decoration: none;
      }
    }
  }
}

.diagnostic_dialog {
  max-width: 920px;
  margin: 0 auto;
  & .MuiDialog-paper {
    background: #ffffff;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  & .close_dialog {
    position: absolute;
    top: 25px;
    right: 25px;
  }

  & .top_content {
    margin-bottom: 50px;
    & .diagnostic_dialog_logo {
      position: absolute;
      top: 28px;
      left: 30px;
      & .logo {
        width: 89px;
      }
    }
  }
  & .step_body {
    background: #19252a;
    position: relative;
    height: 340px;
    padding: 40px 90px;
    & .diagnostic_image {
      position: absolute;
      top: 53px;
      right: 40px;
      height: 287px;
    }
    & .title {
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
      color: #6ff2c2;
      margin-bottom: 30px;
    }
    & .subTitle-mobile {
      visibility: hidden;
      display: none;
    }
    & .body_item {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: #b1f4dc;
      margin-bottom: 16px;
    }
    & .body_icon {
      vertical-align: middle;
      margin-right: 23px;
      height: 24px;
    }
  }
  & .body_item-mobile {
    visibility: hidden;
    display: none;
  }
  & .steps_control {
    margin: 16px 0px 8px 0px;
    & .step_navigation {
      margin: 0px 40px;
      & .navigation_item {
        background: #c4c4c4;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        border: none;
        cursor: pointer;
      }
      & .active {
        background: #6ff2c2;
      }
    }
  }
  & .action_section {
    text-align: center;
    padding-bottom: 44px;
  }
}

@media only screen and (max-width: 1200px) {
  .curriculum_wrapper {
    & .section_one_wrapper {
      //background images
      & .section_one_bg_img_one {
        top: -80px;
        left: 0px;
      }
      & .section_one_bg_img_two {
        position: absolute;
        bottom: -150px;
        left: -100px;
      }
      & .section_one_bg_img_three {
        top: -180px;
        left: 40%;
      }
      & .section_one_bg_img_five {
        bottom: -65px;
        right: 0px !important;
      }
      //background images done
      & .section_one_content_container {
        display: block;
        justify-content: center;
        text-align: center;
        & .section_one_card {
          padding-right: 0px;
          & .section_one_buttons_wrapper {
            justify-content: center;
          }
        }
      }
    }
    & .section_one_card {
      & .section_one_text {
        & h3 {
          margin: auto;
          margin-bottom: 40px;
        }
      }
    }

    & .section_three_wrapper {
      & .section_three_background {
        margin-left: 0%;
        padding-top: 100px;
        height: 450px;
      }
      & .section_three_content_container {
        top: 45%;
        & .buttons_wrapper {
          padding-top: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1230px) {
  .course_box_wrapper {
    padding: 23px 5%;
  }
}
@media only screen and (max-width: 1205px) {
  .curriculum_wrapper {
    & .ontario_curriculum_wrapper {
      text-align: center;

      & .body {
        max-width: 100%;
      }

      & .image_wrapper {
        width: 100% !important;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  & .practice_button {
    display: none;
    visibility: hidden;
  }
  & .circle {
    display: none;
    visibility: hidden;
  }
  & .circle-mobile {
    display: block;
    visibility: visible;
  }
  & .expansion_mobile {
    display: block;
    visibility: visible;
  }
  & .course_box .title .button_wrapper {
    margin-left: 0px;
    margin-top: 10px;
    display: block;
  }

  & .course_box {
    & .mobile_box_controls {
      visibility: visible;
      display: flex;
    }
  }
}

@media only screen and (max-width: 900px) {
  .course_box_wrapper {
    & .course_box {
      border-left-width: 30px;
      padding: 30px 15px;
    }
  }
  .curriculum_wrapper {
    & .ontario_curriculum_wrapper {
      & .desktop_icon {
        visibility: hidden;
        display: none;
      }
      & .mobile_icon {
        visibility: visible;
        display: inline-block;
      }
    }

    & .section_one_wrapper {
      overflow: hidden;
      & .section_one_bg_img_three {
        left: 0% !important;
        width: 40%;
      }

      & .section_one_bg_img_one {
        right: 0% !important;
        left: auto !important;
        width: 30%;
      }

      & .section_one_bg_img_four {
        width: 30%;
        left: 0% !important;
      }
      & .section_one_content_container {
        & .curriculum_top_bg {
          width: 100%;
          height: 100%;
        }
      }
      & .section_one_bg_img_two,
      .section_one_bg_img_five {
        visibility: hidden;
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .curriculum_wrapper {
    & .section_three_wrapper {
      overflow: hidden;
      height: 500px;
      & .section_three_background {
        height: 450px;
        width: 150%;
        position: absolute;
        right: -25%;
        top: -30px;
      }
      & .section_three_content_container {
        top: 42%;
        & .buttons_wrapper {
          padding-top: 20px;
        }
        & h2 {
          font-size: 25px;
          line-height: 150%;
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  & .diagnostic_dialog {
    & .step_body {
      padding: 35px 50px;
      & .body_icon {
        margin-right: 20px;
        width: 30px;
      }
      & .diagnostic_image {
        top: 80px;
        height: 250px;
      }
    }
  }
  .course_box {
    & .links_wrapper {
      & .links_progress {
        margin-top: 20px;
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  & .diagnostic_dialog {
    & .step_body {
      & .diagnostic_image {
        top: 129px;
        height: 200px;
      }
    }
  }
  .curriculum_wrapper {
    & .section_three_wrapper {
      & .section_three_background {
        width: 200%;
        right: -50%;
      }
      & .section_three_content_container {
        top: 36%;
        & .buttons_wrapper {
          padding-top: 20px;
        }
        & h2 {
          font-size: 25px;
          line-height: 150%;
        }
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  & .diagnostic_dialog {
    & .step_body {
      & .diagnostic_image {
        top: 194px;
        right: 10px;
        height: 135px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .page_wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
  .mobile_progress {
    position: absolute;
    top: 25px;
    right: 20px;
  }
  .course_box {
    & .description {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
    }
    & .links_wrapper {
      padding: 2px 0px;
      & .links {
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
      }
    }
  }
  .curriculum_wrapper {
    & .sign_up_button {
      width: 165px;
    }
    &
      .section_one_wrapper
      .section_one_content_container
      .section_one_card
      .section_one_buttons_wrapper
      .sign_up_button_cover {
      width: 165px;
    }
    &
      .section_three_wrapper
      .section_three_content_container
      .buttons_wrapper
      .sign_up_button_wrapper
      .sign_up_button_cover {
      width: 165px;
    }
    &
      .section_three_wrapper
      .section_three_content_container
      .buttons_wrapper
      .sign_up_button_wrapper
      .sign_up_button {
      width: 165px;
    }
    & .ontario_curriculum_wrapper {
      padding: 15px;
      & .title {
        font-weight: 700;
        font-size: 25px;
        line-height: 150%;
      }

      & .body {
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  & .diagnostic_dialog {
    & .step_body {
      padding: 25px 27px 90px 27px;
      height: 400px;
      max-height: 260px;
      & .title {
        margin-bottom: 14px;
        margin-top: 25px;
      }
      & .body_item {
        visibility: hidden;
        display: none;
      }
      & .subTitle-mobile {
        visibility: visible;
        display: block;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #6ff2c2;
      }
      & .body_icon {
        margin-right: 20px;
        width: 30px;
      }
      & .diagnostic_image {
        right: 20px;
        top: 125px;
      }
    }
    & .steps_control {
      padding: 40px;
      & .body_items_wrapper {
        margin-bottom: 40px;
        & .body_item-mobile {
          visibility: visible;
          display: block;
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 22px;
          color: #19252a;
          margin-bottom: 20px;
          & .body_icon {
            vertical-align: middle;
            margin-right: 16px;
            height: 24px;
          }
        }
      }
    }
  }
  .curriculum_wrapper {
    & .curriculum_select_grade_wrapper {
      & .grade_title {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        color: #172e38;
        margin-top: 9px;
        margin-bottom: 8px;
      }
    }
  }
}

@media only screen and (max-width: 530px) {
  & .diagnostic_dialog {
    & .steps_control {
      & .step_navigation {
        & .navigation_item {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  .curriculum_wrapper {
    & .section_one_wrapper {
      padding-left: 8px;
      padding-right: 8px;
      & .section_one_content_container {
        & .section_one_card {
          & .section_one_text h2 {
            font-weight: 700;
            font-size: 32px;
            line-height: 150%;
            margin-bottom: 10px;
          }
          & .section_one_text h3 {
            font-weight: 400;
            font-size: 20px;
            line-height: 150%;
            text-align: center;
          }
        }
        padding-top: 40px;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  & .course_box {
    & .title {
      max-width: 200px;
      font-size: 16px;
    }
  }
  & .diagnostic_dialog {
    & .MuiDialog-paper {
      margin: 0px;
      border-radius: 0px;
    }
  }

  .curriculum_wrapper {
    .section_three_wrapper {
      & .section_three_background {
        width: 250%;
        right: -75%;
      }
    }
  }
  .myPractice_wrapper .topic_box .links_wrapper {
    & .links_progress {
      display: flex;
      justify-content: space-between;
    }
  }
}

@media only screen and (max-width: 390px) {
  & .diagnostic_dialog {
    & .steps_control {
      padding: 30px;
    }
  }
}

@media only screen and (max-width: 365px) {
  & .diagnostic_dialog {
    & .steps_control {
      padding: 20px;
    }
  }
}

@media only screen and (max-width: 345px) {
  & .diagnostic_dialog {
    & .steps_control {
      padding: 15px;
    }
  }
}
