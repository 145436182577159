@import '../../styles/colours.scss';

.topic_report_wrapper {
  background: #19252a;
  padding-left: 10%;
  padding-right: 10%;

  & .top_report_wrapper {
    min-height: auto;
    padding-top: 36px;
    padding-bottom: 26px;

    & .top_report_sm_title {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #ffffff;
    }

    & .top_report_title {
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
      color: #ffff;
      text-transform: capitalize;
    }
  }

  & .skill_information_wrapper {
    & .skill_name {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      margin-top: 0px;
      margin-bottom: 12 px;
    }

    & .beaten_text {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;
      color: #ffffff;
      margin: 0;

      & .beaten_percentage {
        font-size: 20px;
        font-weight: 600;
      }
    }

    & .current_level_box,
    .estimate_date_box {
      background: #25353b;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      padding: 15px 17px;
      margin-top: 30px;
      margin-right: 20px;

      & .level_title {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #c4c4c4;
        margin-bottom: 0;
      }

      & .level_date {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
        padding-top: 13px;
      }

      & .skill_body {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        & .subject_name {
          color: #31d197;
          text-transform: capitalize;
        }

        & .level_name {
          color: #fda78f;
        }
      }
    }

    & .estimate_date_box {
      margin-left: 0;
    }
  }
}

.estimate_graph {
  height: 150px;
  width: 360px;
  position: relative;
  padding-left: 90px;
  margin-top: 50px;

  & .bottom_arrow {
    position: absolute;
    bottom: 0px;
    left: 94px;
  }
  & .side_arrow {
    position: absolute;
    left: 90px;
    top: 14px;
  }

  & .level_master,
  .level_advanced,
  .level_intermediate,
  .level_fundamental {
    position: absolute;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    left: 0;
  }

  & .level_master {
    top: 10px;
    color: #f87a7a;
    left: 11px;
    display: flex;
    vertical-align: middle;
    gap: 5px;
  }
  & .level_advanced {
    top: 50px;
    color: #fda78f;
    left: 17px;
  }
  & .level_intermediate {
    top: 90px;
    color: #ffbe71;
  }
  & .level_fundamental {
    bottom: 00px;
    color: #8fffd6;
  }

  & .graph_area {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    vertical-align: bottom;

    & .progress_line {
      border: 1px dashed #36a77e;
      position: absolute;
      top: 73px;
      width: 77%;
      left: 27px;
      transform: rotate(-20deg);
    }

    & .bar_fundamental {
      border: 1px dashed #c2c2c2;
      transform: rotate(90deg);
      position: absolute;
      width: 5%;
      bottom: 15px;
      left: 7%;
    }
    & .bar_intermediate {
      border: 1px dashed #c2c2c2;
      transform: rotate(90deg);
      position: absolute;
      width: 14%;
      bottom: 30px;
      left: 26%;
    }
    & .bar_advanced {
      border: 1px dashed #c2c2c2;
      transform: rotate(90deg);
      position: absolute;
      width: 23%;
      bottom: 48px;
      right: 30%;
    }
    & .bar_master {
      border: 1px dashed #c2c2c2;
      transform: rotate(90deg);
      position: absolute;
      width: 32%;
      bottom: 63px;
      right: 1%;
    }

    & .labels {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #bcbcbc;
    }

    & .active {
      color: #ffffff;
      font-weight: bold;
    }

    & .label_fundamental {
      position: absolute;
      left: 6%;
      bottom: -17px;
    }

    & .label_intermediate {
      position: absolute;
      left: 28%;
      bottom: -17px;
    }

    & .label_advanced {
      position: absolute;
      right: 37%;
      bottom: -17px;
    }

    & .label_master {
      position: absolute;
      right: 12%;
      bottom: -17px;
    }
  }
}

.topic_report_more {
  & .more_information {
    padding-bottom: 50px;
    overflow: hidden;
    & .nothing_to_show {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 16px;
      color: #697276;
      text-align: center;
      margin-top: 20px;
    }

    & .more_info_tab_wrapper {
      padding-bottom: 20px;
      padding-top: 20px;
    }

    & .skill_boxes_wrapper {
      padding: 0px 35px;
    }

    & .expanded_box {
      background-color: #f8f8f8;
    }
  }
}

.performance_chart_wrapper {
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #919a9e;
  padding-top: 30px;
  margin-right: 20px;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $primary_focus;
    border-radius: 4px;
  }

  & .average_line {
    position: absolute;
    top: 0px;
    height: 110%;
  }
  & .performance_line {
    left: 0px;
    position: absolute;
    width: 100%;
  }
  & .average_label {
    position: absolute;
    bottom: -50px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #919a9e;
  }

  & .bottom_arrow {
    width: 100%;
    position: absolute;
    bottom: -20px;
  }

  & .side_arrow {
    height: 81%;
    position: absolute;
    left: -18px;
  }

  & .performance_fundamental {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  & .performance_intermediate {
    position: absolute;
    top: 0px;
    left: 40%;
  }
  & .performance_advanced {
    position: absolute;
    top: 0px;
    left: 70%;
  }
  & .performance_master {
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    gap: 8px;
  }
  & .active_level {
    color: #f99f20;
  }
  & .bar_chart {
    margin-top: 8px;
    background: rgba(80, 93, 98, 0.1);
    border-left: 4px solid #919a9e;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
    height: 40px;
    max-height: 40px;
    position: relative;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #919a9e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 12px;
    white-space: pre;
  }
  & .bar_chart:hover {
    background: rgba(210, 220, 226, 0.1);
    border-color: #d2dce2;
  }
  & .active_bar_chart {
    border-color: #6ff2c2;
    background: rgba(111, 242, 194, 0.1);

    & .bar_label {
      color: #6ff2c2;
    }
  }
}

.report_body {
  background: #19252a;
  padding-bottom: 100px;
  padding-right: 20px;
}

.display_more_button {
  width: 200px;
  color: rgba(255, 255, 255, 0.4);
  margin: 0 auto;
  cursor: pointer;
  box-shadow: none;
  border: none;
  background: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.4);
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 10px;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $primary_focus;
    border-radius: 4px;
  }
}

@media only screen and (max-width: 810px) {
  .topic_report_wrapper {
    padding-left: 5%;
    padding-right: 5%;

    & .top_report_wrapper {
      padding-bottom: 0px;
      padding-top: 20px;
    }
  }
  .report_body {
    padding-right: 0px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 600px) {
  .topic_report_wrapper .top_report_wrapper .top_report_title {
    font-size: 24px;
    margin: 10px 0;
  }
  .topic_report_more .more_information {
    & .skill_boxes_wrapper {
      padding: 0px 0px;
    }
  }

  .performance_chart-mobile {
    visibility: visible;
    display: inline;
  }
  .performance_chart-desktop {
    visibility: hidden;
    display: none;
  }
  .performance_chart_wrapper {
    position: relative;
    padding-left: 83px;
    & .level_master,
    .level_advanced,
    .level_intermediate,
    .level_fundamental {
      position: absolute;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      left: 0;
    }
    & .active_title {
      position: absolute;
      top: 103px;
      color: #6ff2c2;
      font-size: 12px;
      line-height: 16px;
      max-width: 127px;
      z-index: 2000;
    }
    & .master_level {
      margin-right: 10px;
      vertical-align: bottom;
    }
    & .level_advanced {
      left: 0px;
      top: 106px;
    }
    & .level_intermediate {
      top: 183px;
    }
    & .level_fundamental {
      bottom: 30px;
    }
    & tspan,
    .recharts-cartesian-axis {
      visibility: hidden;
      display: none;
    }
    & .recharts-rectangle {
      & .bottom_indicator {
        height: 20px;
        background-color: red;
      }
      border: 2px solid green;
    }
  }
}

@media only screen and (max-width: 500px) {
  .topic_report_wrapper {
    & .practice_plan_box {
      background: none;
      padding: 0px;
      box-shadow: none;
    }
  }
}

@media only screen and (max-width: 490px) {
  .estimate_graph {
    padding-left: 0px;

    & .side_arrow {
      left: 0px;
    }

    & .bottom_arrow {
      left: 3px;
    }

    & .bottom_arrow {
      width: 94%;
    }

    .level_fundamental {
      top: 70px;
      left: 10px;
    }

    .level_intermediate {
      top: 40px;
      left: 85px;
    }

    .level_advanced {
      top: 20px;
      left: 180px;
    }

    & .level_master {
      top: 0px;
      left: 260px;
    }
  }
}
@media only screen and (max-width: 440px) {
  .performance_chart_wrapper {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 385px) {
  .estimate_graph {
    width: 330px;
    & .graph_area {
      & .progress_line {
        top: 76px;
        left: 24px;
      }
    }
  }
}
