.contact-us-bg {
  background-image: url('/assets/images/desktop-contact.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 100%;
  padding-top: 10%;
  padding-bottom: 5%;
}

.contact-us-thankyou {
  background-image: url('/assets/images/desktop-thankyou.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 100%;
  padding-top: 15%;

  & .thankyou-wrapper {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 25%;

    & .title {
      font-style: normal;
      font-weight: 800;
      font-size: 45px;
      line-height: 150%;
      text-align: center;
      color: #ffffff;
      margin-bottom: 8px;
    }

    & .sub-title {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      color: #ffffff;
      max-width: 480px;
      display: inline-block;
      margin-bottom: 40px;
    }

    & .mobile-mail-icon {
      visibility: hidden;
      display: none;
    }
  }
}

.contact-us-wrapper {
  & .mobile-mail-icon {
    display: none;
    visibility: hidden;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  & .title {
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 150%;
    margin-bottom: 10px;
    margin-top: 0px;
    color: #ffffff;
  }

  & .sub-title {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    max-width: 288px;
    color: #ffffff;
  }
}

@media only screen and (max-width: 1300px) {
  .contact-us-thankyou {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-size: 100%;
    padding-top: 100px;
    background-position-y: 30%;

    & .thankyou-wrapper {
      padding-top: 100px;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .contact-us-bg {
    background-color: #172e38;
    height: 100%;
  }
}

@media only screen and (max-width: 1250px) {
  .contact-us-bg {
    background-size: 104%;
  }
}

@media only screen and (max-width: 1100px) {
  .contact-us-thankyou {
    padding-top: 100px;
    background-position-y: 10%;

    & .thankyou-wrapper {
      padding-top: 110px;
    }
  }
}

@media only screen and (max-width: 930px) {
  .contact-us-bg {
    background-position-x: 69%;
    background-position-y: -48%;
  }
}

@media only screen and (max-width: 840px) {
  .contact-us-bg {
    background-position-x: 69%;
    background-position-y: -23%;
  }
}

@media only screen and (max-width: 740px) {
  .contact-us-bg {
    background-image: none;
    padding: 0% !important;
  }

  .contact-us-top {
    background-image: url('/assets/images/mobile-contact.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-size: 100%;
    background-position-y: 50%;
    padding-top: 60px;
    padding-bottom: 20px;
    text-align: center;
  }
  .contact-us-wrapper {
    & .mobile-mail-icon {
      display: inline-block;
      visibility: visible;
    }
    & .title {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 150%;
    }
    & .sub-title {
      max-width: 288px;
      margin: auto;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
    }
  }
}

@media only screen and (max-width: 740px) {
  .contact-us-top {
    background-size: 143%;
    background-position-y: 37%;
  }
}

@media only screen and (max-width: 970px) {
  .contact-us-thankyou {
    & .thankyou-wrapper {
      padding-bottom: 300px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .contact-us-thankyou {
    padding-top: 90px;
    background-size: 100% 120%;

    & .thankyou-wrapper {
      & .sub-title {
        margin-bottom: 20px;
      }
      padding-bottom: 300px;
    }
  }
}

@media only screen and (max-width: 750px) {
  .contact-us-thankyou {
    background-image: url('/assets/images/mobile-thankyou.png');
    background-position-x: 0%;
    background-position-y: 80%;
    background-size: 100%;
    background-repeat: no-repeat;

    & .thankyou-wrapper {
      & .mail-icon-big {
        visibility: hidden;
        display: none;
      }
      & .mobile-mail-icon {
        visibility: visible;
        display: inline-block;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .contact-us-thankyou {
    background-image: url('/assets/images/mobile-thankyou.png');
    background-size: 100%;
    background-repeat: no-repeat;
    padding-bottom: 20px;

    & .thankyou-wrapper {
      padding-top: 10px;
      & .title {
        font-weight: 700;
        font-size: 32px;
        line-height: 150%;
      }
      & .sub-title {
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        max-width: 300px;
      }
    }
  }
}

@media only screen and (max-width: 380px) {
  .contact-us-thankyou {
    background-position-y: 70%;
    padding-bottom: 50px;
  }
}
