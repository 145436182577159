.plan_section_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 72vw;
  & .tree_image {
    position: relative;
    z-index: 1;
    top: -100px;
    width: 804px;
  }
  & .payment_box {
    box-shadow: 0px 0px 8.21492px 4.10746px rgba(180, 180, 180, 0.2);
    border-radius: 10px;
    display: flex;
    width: 804px;
    height: 800px;
    margin-top: 130px;
    margin-bottom: 102px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 5%;
    & .close_button {
      position: relative;
      z-index: 4;
      margin-left: auto;
      margin-right: 27px;
      top: -161px;
      border: none;
      font-size: 17px;
      background: none;
      padding-top: 3px;
      cursor: pointer;
    }
    & .close_button:hover {
      background-color: rgba(58, 90, 255, 0.14);
    }
    & .try_genius_forest {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 150%;
      color: #19252a;
      margin-bottom: 5px;
      margin-top: -61px;
      text-align: center;
    }
    & .thirty_day_trial {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      color: #172e38;
      margin-bottom: 27px;
    }
    & .select_students {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      margin-bottom: 8px;
    }
    & .student_number_container {
      display: flex;
      align-items: center;
      gap: -5px;
      & .billing_card_icons {
        width: 43px;
        height: 43px;
      }
      & .button {
        color: #f8f8f8;
        background-color: #f8f8f8;
        border: 2px solid #f8f8f8;
        z-index: 3;
      }
      & .button:focus {
        border: 2px solid #6ff2c2;
        outline: none;
      }
      & .button:active {
        outline: none;
        border: 2px solid #6ff2c2;
      }
      & .student_number_container {
        width: 54px;
        height: 50px;
        background: #ffffff;
        box-sizing: border-box;
        border-radius: 4px;
        & p {
          width: 100%;
          text-align: center;
        }
      }
    }
    & .divider {
      margin-left: -1px;
      width: 550px;
      margin-top: 19px;
      border: 1px solid #ece8e8;
      margin-bottom: 19px;
    }
    & .order_div {
      margin-top: 32px;
      display: flex;
      justify-content: flex-start;
      width: 51%;
      margin-left: -139px;
      flex-direction: column;
      & .order_summary {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #172e38;
        margin-bottom: 8px;
      }
      & .summary_plan {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        left: 15px;
        position: relative;
      }
      & .price_calc {
        margin-top: 6px;
        position: relative;
        left: 15px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
      }
      & .frequency {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #5d6970;
      }

      & .studentCount {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #5d6970;
      }
    }
    & .save_percent {
      background: #172e38;
      border-radius: 16px;
      color: #ffffff;
      width: 89px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      z-index: 3;
      position: absolute;
      margin-left: 173px;
      margin-top: -11px;
    }
    & .pricing_grid {
      width: 33%;
      position: relative;
      left: 18%;
      margin-bottom: 20px;
      & .due_current {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #5d6970;
      }
      & .total_price {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #5d6970;
        text-align: end;
      }
    }
    & .due_today {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #172e38;
    }
    & .today_price {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #172e38;
      text-align: end;
    }
    & .accountCharge {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      margin-top: 8px;
      text-align: center;
      color: #5d6970;
    }
  }
}
.grid {
  margin-top: 30px;
  display: flex;
  grid-gap: 32px;
  padding: 0;
}

.card {
  background-color: #f8f8f8;
  border-radius: 0.5em;
  position: relative;
  width: 256px;
  height: 126px;
}

.radio {
  font-size: inherit;
  margin: 0;
  position: absolute;
  right: calc(1em + 2px);
  top: calc(1em + 2px);
}

.radio {
  background: #fff;
  border: 2px solid #e2ebf6;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  outline: none;
  width: 20px;
  top: 45%;

  &::after {
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    display: block;
    height: 0.75rem;
    left: 25%;
    position: absolute;
    top: 50%;

    width: 0.375rem;
  }

  &:checked {
    border-color: #6ff2c2;
  }
  &:focus {
    border-color: #6ff2c2;
  }
}

.card:hover .radio {
  border-color: #c4d1e1;

  &:checked {
    border-color: #6ff2c2;
  }
}

.plan_details {
  border: 2px solid #f8f8f8;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 0.35em;
  transition: border-color 0.2s ease-out;
  color: #5d6970;
  & .plan_type {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    padding-left: 2%;
    padding-top: 2%;
  }
  & .striked {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #5d6970;
    position: absolute;
    margin-left: 171px;
    margin-top: -16px;
    background: linear-gradient(
      to left top,
      transparent 47.75%,
      currentColor 49.5%,
      transparent 52.25%
    );
  }
  & .plan_price {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    padding-left: 2%;
    padding-top: 0%;
  }
  & .plan_length {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    padding-left: 2%;
    padding-top: 2%;
  }
  & .bill_freq {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #5d6970;
    margin-top: 7px;
    padding-left: 2%;
    padding-top: 2%;
  }
}

.card:hover .plan_details {
  border-color: #c4d1e1;
}

.radio:checked ~ .plan_details {
  border-color: #6ff2c2;
  background-color: #ffffff;
  color: #000000;
}

@media only screen and (max-width: 800px) {
  .plan_section_wrapper {
    .payment_box {
      width: 600px;
      & .tree_image {
        width: 604px;
      }
      .close_button {
        margin-left: auto;
        margin-right: 27px;
        top: -141px;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .plan_section_wrapper {
    height: auto;
    & .payment_box {
      box-shadow: none;
      margin-bottom: 10px;
      & .close_button {
        display: none;
        visibility: hidden;
      }
      & .tree_image {
        display: none;
      }
      & .try_genius_forest {
        font-weight: 700;
        font-size: 25px;
        line-height: 150%;
      }
      & .thirty_day_trial {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 32px;
      }

      & .order_div {
        width: 88%;
        margin-left: 6%;
        justify-content: flex-start;
        & .summary_plan {
          left: 0px;
          margin-bottom: 10px;
        }
        & .price_calc {
          left: 0px;
        }
      }
      & .divider {
        width: 345px;
      }
      & .pricing_grid {
        width: 88%;
        left: unset;

        & .due_current {
          position: relative;
          left: 11px;
        }
        & .due_today {
          position: relative;
          left: 11px;
        }
        & .today_price {
          position: relative;
          right: 21px;
          width: 100%;
          left: auto;
        }
        & .total_price {
          position: relative;
          right: 21px;
          width: 100%;
          left: auto;
        }
      }
      & .save_percent {
        margin-left: 209px;
      }
      & .accountCharge {
        width: 352px;
        margin-bottom: 1px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #5d6970;
        margin-top: 27px;
      }
    }
  }
  .grid {
    flex-direction: column;
    margin-top: 35px;
    gap: 20px;
  }
  .card {
    width: 296px;
    height: 126px;
  }
  .plan_details {
    & .striked {
      margin-left: 207px;
    }
  }
}
.next_button {
  margin-left: -5px;
}
@media only screen and (max-width: 360px) {
  .plan_section_wrapper {
    & .payment_box {
      & .divider {
        width: 90%;
      }
    }
  }
}
