@import '../styles/colours.scss';

.footer_wrapper {
  background: $secondary_color;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10%;
  padding-right: 10%;
  min-width: 100vw;

  & .btn-footer-links {
    color: #ffffff;
    border: 2px solid transparent;
    padding: 2px 4px;
    border-radius: 4px;
    background: none;
    text-decoration: underline;
    height: min-content;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    box-shadow: none;

    &:focus {
      outline: none !important;
      border: 2px solid #ffffff;
    }
    &:hover {
      color: #ffffff;
      text-shadow: 1px 0 0 #ffffff;
    }
  }

  & .logo {
    cursor: pointer;
    &:focus {
      outline-offset: 6px;
      outline: 2px solid #ffffff;
    }
  }

  & .footer_line {
    border-color: #cdd1d4;
    box-shadow: none;
    margin: 28px 0 20px 0;
  }

  & .copyright {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    &:focus {
      outline-offset: 3px;
      outline: 2px solid #ffffff;
    }
  }

  & .links_wrapper {
    margin-right: 40px;
  }

  & .copyright_wrapper {
    align-self: flex-end;
  }
}

#subscribe_text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

#footer_wrapper_content {
  display: flex;
  flex-direction: row;
}

#news_letter_form {
  margin-left: auto;
}

@media only screen and (max-width: 480px) {
  #footer_wrapper_content {
    display: flex;
    flex-direction: column;
  }
  .logo {
    height: 65px;
    width: 112px;
  }
  #news_letter_form {
    margin: 0;
  }
}

@media only screen and (max-width: 600px) {
  .footer_wrapper {
    & .links_wrapper {
      margin: 15px 0;
    }
  }
}

@media only screen and (max-width: 644px) {
  .footer_wrapper {
    // margin-top: 50px;
    padding: 11% 5% 2% 5%;
    & .copyright {
      margin: 30px 0;
    }
  }
}

@media only screen and (max-width: 1080px) {
  .footer_wrapper {
    padding-right: 5%;
    padding-left: 5%;
  }
}
