.FAQ_wrapper {
  background-image: url('/assets/images/FAQbg_desktop.png');
  font-family: 'Open Sans';
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10% 10% 4% 10%;
  display: flex;
  max-width: 100% !important;

  & .section_one_wrapper {
    color: white;
    justify-content: center;
    align-items: center;
    & .textOne {
      text-align: center;
      font-size: 32px;
      font-weight: 700;
      padding-top: 1%;
      padding-bottom: 2%;
    }
    & .textDiv {
      width: 55%;
      padding-bottom: 2%;
    }
    & .bottom_text {
      padding-bottom: 6%;
    }
  }

  & .section_two_wrapper {
    background-color: #ffffff;
    color: #172e38;
    padding: 5%;

    & .category_label {
      text-transform: capitalize;
    }

    & .page_title {
      margin-bottom: 5%;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 150%;
      color: #172e38;
    }

    & .top_link {
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 150%;
      color: #172e38;
      text-decoration: none;

      &:focus {
        outline-color: #36a77e;
      }
    }

    & .bottom_link {
      font-style: normal;
      font-weight: 600 !important;
      font-size: 20px;
      line-height: 150%;
      text-decoration-line: underline !important;
      color: #172e38;
      &:focus {
        outline-color: #36a77e;
      }
    }

    & .regular_link {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: #5d6970;
      text-decoration: none;

      &:focus {
        outline-color: #36a77e;
      }
    }
    & .bottom_link {
      font-weight: bold;
      text-decoration: none;
    }
    & .bottomDiv {
      justify-content: center;
      align-items: center;
      display: flex;
      color: #172e38;
      padding: 1%;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      color: #19252a;
    }
    & .bottomDiv-text {
      padding: 1%;
      padding-top: 60px;
      justify-content: center;
      align-items: center;
      display: flex;
      color: #172e38;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      color: #19252a;
    }
  }
  & .faq_box {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 2px 4px 2px rgba(207, 207, 207, 0.25);
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 2%;
    & .expansion_section {
      transition: all 2s linear;
      visibility: hidden;
      display: none;
      &.visible {
        visibility: visible;
        display: flex;
        padding: 2%;
      }
    }
    & .faq_info {
      transition: all 1s linear;
      visibility: hidden;
      display: none;
      &.visible {
        visibility: visible;
        display: flex;
      }
    }
    & .inner_faq {
      width: auto;
      display: flex;
      flex-wrap: nowrap;
      box-sizing: border-box;
    }
  }
}

@media only screen and (max-width: 600px) {
  .FAQ_wrapper {
    background-image: url('/assets/images/FAQbg_mobile.png');
    background-size: contain;
    & .bottom_text {
      text-align: center;
    }
    & .section_one_wrapper {
      padding-bottom: 35%;
      padding-top: 16%;
      & .textDiv {
        width: 100%;
      }
    }
    & .section_two_wrapper {
      padding: 1%;
      width: 100%;
      height: 100%;
      padding-bottom: 35px;

      & .bottomDiv {
        margin-top: 40px;
      }
    }
    & .faq_box {
      & .inner_faq {
        & h3 {
          font-size: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .FAQ_wrapper {
    padding: 2% !important;
    & .section_one_wrapper {
      padding-bottom: 27%;
    }
    & .section_two_wrapper {
      & .page_title {
        font-style: normal;
        font-weight: 700;
        font-size: 23px;
        line-height: 150%;
        padding-left: 5px;
        margin-bottom: 40px;
        margin-top: 25px;
      }

      & .top_link {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .FAQ_wrapper {
    & .section_one_wrapper {
      padding-bottom: 24%;
    }
  }
}

@media only screen and (max-width: 400px) {
  .FAQ_wrapper {
    & .section_one_wrapper {
      padding-bottom: 15%;
    }
  }
}
