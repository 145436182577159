.nothingToShow-wrapper {
  height: 380px;
  & .top-image-wrapper {
    text-align: center;
    padding-top: 40px;
    width: 400px;
    position: relative;
    margin: 0 auto;
    margin-top: 30px;
    & .top-image {
      opacity: 0.9;
      height: 220px;
    }
    & .back-image-one {
      position: absolute;
      left: 27%;
      height: 60px;
      top: 10%;
    }
    & .back-image-two {
      position: absolute;
      height: 40px;
      right: 11%;
      top: 10%;
    }
    & .back-image-four {
      position: absolute;
      height: 50px;
      bottom: 10%;
    }
    & .back-image-five {
      position: absolute;
      height: 80px;
      bottom: 30%;
      right: 0%;
    }
    & .back-image-six {
      position: absolute;
      height: 90px;
      left: 10%;
      bottom: 10%;
    }
    & .back-image-seven {
      position: absolute;
      height: 50px;
      left: 10%;
      top: 22%;
    }
  }
  & .text {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #19252a;
    text-align: center;
  }
  & .sub-text {
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #697276;
    text-align: center;
    max-width: 320px;
  }
}

@media only screen and (max-width: 600px) {
  .nothingToShow-wrapper {
    & .top-image-wrapper {
      width: 280px;
      & .back-image-six {
        height: 60px;
        left: 6%;
        bottom: 7%;
      }
      & .back-image-seven {
        left: 2%;
        top: 26%;
      }
      & .back-image-five {
        height: 50px;
        bottom: 40%;
        right: 0%;
      }
    }
    & .text {
      font-size: 16px;
      line-height: 20px;
    }
    & .sub-text {
      font-size: 14px;
      line-height: 18px;
      margin-top: 0px;
    }
  }
}
