@import '../../styles/colours.scss';

.masterControl_wrapper {
  max-width: 1600px;
  margin: auto;
}

.designSystem_section-wrapper {
  padding: 30px 10%;
}

.designSystem-header {
  max-width: 1600px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  & .title {
    color: $primary_text-color;
    font-size: 20px;
    margin: 5px 0px;
  }
  & .subTitle {
    color: $primary_text-color;
    font-size: 16px;
    margin: 5px 0px;
  }
}

.designSystem-sections {
  border: solid 1px #989898;
  border-radius: 4px;
  padding: 10px;
  position: relative;
  padding: 40px 5%;
  & .title {
    color: $primary_color;
    font-size: 20px;
    margin: 5px 0 10px 0;
    position: absolute;
    top: -20px;
    left: 2%;
    background: #ffffff;
    padding: 0 5px;
  }
  & .subTitle {
    color: $primary_text-color;
    font-size: 16px;
    margin: 15px 0;
  }
  & .designSystem-elements {
    margin-right: 40px;
  }
  & .designSystem-elements-wrapper {
    margin-bottom: 40px;
  }
}

.tab_icons {
  margin-right: 12px;
}

.circle_progress_label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #19252a;
}

.MuiPopover-root {
  & .MuiMenu-paper {
    border: 2px solid $primary_color;
    & .Mui-selected {
      background-color: #dcfff2;
    }
    & .Mui-selected:hover {
      background-color: #dcfff2;
    }
  }
}

.MuiButtonBase-root {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px !important;
  letter-spacing: 0.005em;
  color: #19252a;
}


@media only screen and (max-width: 600px) {
  .tab_icons {
    width: 27px;
    height: 30px;
  }
}
