@import '../../../styles/colours.scss';

.tableHeader {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  white-space: nowrap;
}

.reset_filter {
  cursor: pointer;
  background: none;
  border: none;
  &:focus {
    outline: 2px solid $primary_focus;
  }
  &:hover {
    outline: 2px solid $primary_focus;
  }
}

.table_wrapper {
  & .edit_question {
    cursor: pointer;
    &:focus {
      outline: 2px solid $primary_focus;
    }
    &:hover {
      outline: 2px solid $primary_focus;
    }
  }
}

.tableContent {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

#options {
  max-width: 133.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#resetFilter {
  display: flex;
  height: 45px;
  align-items: center;
  justify-content: center;
  color: #0a5e3f;
  margin-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
  & p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0.24px;
    text-decoration-line: underline;
  }
}

#resetIcon {
  margin-right: 12.09px;
  margin-top: 2px;
}

// #question_filter{
//     position: -webkit-sticky;
//     position: sticky;
//     top: 0;
//     background-color: white;
//     padding-top: 15px;
// }

//orange: #f57871
// blue: #34568B

#updated {
  background-color: #34568b;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}

#not_updated {
  background-color: #f57871;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}

#table {
  border-spacing: 0 5px !important;
}

.question_table_top_container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1000px;
}

@media only screen and (max-width: 1280px) {
  .table_wrapper {
    width: 90% !important;
  }
}

@media only screen and (max-width: 1080px) {
  .table_wrapper {
    width: fit-content !important;
  }
}
