@keyframes fadeinout {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.landing_page_wrapper {
  padding-left: 5%;
  padding-right: 5%;
  max-width: 1600px;
  margin: 0 auto;
}

.test {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background: radial-gradient(#ffda2d, white);
  // opacity: 0.5;
}

.sign_up_button {
  border: 0px solid #172e38;
  border-radius: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #172e38;
  background-color: #6ff2c2;
  width: 187px;
  height: 57px;
  padding: 10px 16px 10px 16px;
  white-space: nowrap;
  cursor: pointer;
  transform: all 0.3s;
}

.sign_up_button.active {
  background-color: #31d196;
  border-color: black;
  box-shadow: none;
  outline: black;
}

.section_1_wrapper {
  position: relative;
  overflow: hidden;
  height: 635px;
  padding-left: 10%;
  & .image_slider {
    z-index: 1;
    position: absolute;
    width: 615px;
    height: 375.02px;
    top: 164px;
    right: 82px;
    cursor: pointer;
    & .images {
      position: relative;
      width: 588.15px;
      height: 360.02px;
      & .inner_images {
        width: 590px;
        height: 360.02px;
      }
      & .section_one_image_one {
        position: absolute;
        left: 1000.33px;
        transition: left 1.2s;
        visibility: hidden;
      }
      & .section_one_image_one.active {
        left: 0px;
        visibility: visible;
      }
      & .section_one_image_two {
        position: absolute;
        left: 1000.33px;
        transition: left 1.2s;
        visibility: hidden;
      }
      & .section_one_image_two.active {
        left: 0px;
        visibility: visible;
      }
      & .section_one_image_three {
        position: absolute;
        left: 1000.33px;
        transition: left 1.2s;
        visibility: hidden;
      }
      & .section_one_image_three.active {
        left: 0px;
        visibility: visible;
      }
    }
  }
  & .section_one_bg_image_one {
    position: absolute;
    top: -15px;
    left: 8%;
    transition: all 0.3s ease-in-out;
  }
  & .section_one_bg_image_one.active {
    transform: rotate(-45deg);
    top: 0px;
  }
  & .section_one_bg_image_two {
    position: absolute;
    top: 75%;
    left: 0px;
    transition: all 0.3s ease-in-out;
  }
  & .section_one_bg_image_two.active {
    transform: rotate(30deg);
    top: 73%;
    left: 10px;
  }
  & .section_one_bg_image_three {
    position: absolute;
    top: 5%;
    left: 45%;
    transition: all 0.3s ease-in-out;
  }
  & .section_one_bg_image_three.active {
    transform: rotate(90deg);
    top: 2%;
    left: 43%;
  }
  //image_four doesnt get an animation change
  & .section_one_bg_image_four {
    position: absolute;
    top: 190px;
    left: 6%;
  }
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  & .section_one_bg_image_five {
    position: absolute;
    top: 240px;
    left: 48%;
    transition: all 0.3s ease-in-out;
  }
  & .section_one_bg_image_five.active {
    top: 230px;
    left: 46%;
    transform: rotate(-10deg);
  }
  & .section_one_bg_image_six {
    position: absolute;
    bottom: -30px;
    right: 10px;
    transition: all 0.3s ease-in-out;
  }
  & .section_one_bg_image_six.active {
    transform: rotate(-10deg);
    bottom: -25px;
    right: 0px;
  }
  & .section_one_bg_image_seven {
    position: absolute;
    top: 23%;
    right: 0px;
    transition: all 0.3s ease-in-out;
  }
  & .section_one_bg_image_seven.active {
    transform: rotate(-20deg);
    top: 20%;
    right: -10px;
  }
  & .section_one_outer {
    position: absolute;
    right: 75px;
    top: 134px;
    border-radius: 12.3307px;
    width: 655px;
    height: 423px;
  }
  & .macbook_mobile {
    display: none;
    visibility: hidden;
  }
  & .section_one_card_wrapper {
    max-width: 440px;
    padding-top: 200px;
    padding-bottom: 308px;
    z-index: 2;
    & .section_one_buttons_wrapper {
      display: flex;
      gap: 15px;
      padding-top: 35px;
      & .sign_up_button_cover {
        width: 187px;
        height: 57px;
        border: 1.5px solid #19252a;
        box-sizing: border-box;
        border-radius: 10px;
        transform: rotate(-4deg);
        position: absolute;
        transition: all 0.3s;
      }
      & .sign_up_button_cover.active {
        transform: rotate(0deg);
        outline: none !important;
        border-color: black;
        box-shadow: none;
        outline: black;
      }
    }
    & h2 {
      font-weight: 800;
      font-size: 45px;
      line-height: 150%;
      margin: 0 0 0 0;
    }
    & p {
      font-size: 20px;
      width: 445px;
    }
  }
}

.section_2_wrapper {
  padding-bottom: 88px;
  padding-top: 20px;
  & .benefits_grid {
    height: 400px;
    gap: 31px;
  }
  & .section_two_mobile {
    display: none;
    visibility: hidden;
  }
  & .section_two_image_one {
    position: absolute;
    width: 882.43px;
    height: 510.03px;
    left: 0px;
    bottom: 0px;
    z-index: -1;
  }
  & .section_two_image_two {
    position: absolute;
    left: 100px;
  }
  & .benefit_text_wrapper {
    padding-right: 32px;
    & h2 {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      color: #19252a;
    }
    & .sub_text_one {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;
      color: #505d62;
      max-width: 340px;
    }
    & .sub_text_two {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #919a9e;
    }
  }
  & .benefit_cards_wrapper {
    align-items: end;
    height: 350px;
    & .benefit_card {
      background-color: white;
      width: 260px;
      min-width: 260px;
      height: 332px;
      padding: 37px 32px;
      z-index: 1;
      transition: margin-bottom 0.5s;
      box-shadow: 0px 0px 8.21492px 4.10746px rgba(180, 180, 180, 0.2);
      border-radius: 15px;
      border: 2px solid transparent;
      cursor: pointer;
      &:hover {
        margin-bottom: 53px;
      }
      &:focus {
        margin-bottom: 53px;
        outline: none;
        border: 2px solid #36a77e;
      }
      &:active {
        outline: none;
        border: 2px solid transparent;
      }
      & h2 {
        font-style: normal;
        font-weight: 900;
        font-size: 80px;
        line-height: 20px;
        margin: 0px;
        color: #6aa992;
        margin-top: 18px;
      }
      & h3 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        color: #19252a;
        margin-top: 60px;
        margin-bottom: 0px;
      }
      & p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #172e38;
        margin-top: 20px;
      }
    }
  }
}

.section_3_wrapper {
  background-color: white;
  position: relative;
  overflow: hidden;
  padding-bottom: 35px;
  & h2 {
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    line-height: 35px;
    color: #19252a;
    padding-top: 30px;
  }
  & .section_three_card_container {
    display: flex;
    justify-content: center;
    height: 364px;
    width: fit-content;
    margin: 0 auto;
    &:focus {
      outline: 2px solid #36a77e;
    }
    & .section_three_card_image {
      opacity: 1;
    }
    & .section_three_card_image.active {
      animation: fadeinout 0.8s linear 1 forwards;
    }
    & .section_three_card_content {
      position: absolute;
      width: 650px;
      height: 364px;
      text-align: center;
      opacity: 1;
      & h2 {
        font-weight: 700;
        font-size: 32px;
        line-height: 150%;
        color: #172e38;
        margin-top: 70px;
        margin-bottom: 10px;
      }
      & p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        margin-top: 0px;
      }
    }
    & .section_three_card_content.active {
      animation: fadeinout 0.8s linear 1 forwards;
    }
  }
  & .dotted_lines_container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 50px;
    & .dotted_wrapper {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 120px;
      & .section_three_dotted_lines {
        position: absolute;
        width: 900px;
      }
      & .section_three_buttons_wrapper {
        left: 0;
        display: flex;
        gap: 110px;
        z-index: 3;
        padding-top: 40px;
        height: 199px;
        & div {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 135px;
          & .button_image_container {
            height: 120px;
            width: 103px;
            display: grid;
            align-items: end;
            justify-content: center;
            position: relative;
            & .section_three_button_wrapper {
              background: none;
              border: none;
              z-index: 200;
              &:focus {
                outline: none;
                & .section_three_buttons {
                  border: 6.5px solid white;
                  outline: 3.5px solid #e5e5e5;
                  margin-bottom: -5px !important;
                }
              }
            }
            & .section_three_buttons {
              width: 103px;
              height: 100px;
              border-radius: 50%;
              cursor: pointer;
              transition: all 0.5s;
              z-index: 1;
              border: 0px solid white;
              outline: 0px solid #e5e5e5;
              &:hover {
                margin-bottom: 10px;
              }
            }
            & .button_shadow {
              width: 103px;
              height: 100px;
              position: absolute;
              border-radius: 50%;
              bottom: 4px;
              left: 50%;
              -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
              z-index: -1;
            }
            & #engage_shadow {
              background: radial-gradient(#ffda2d, white);
              box-shadow: 0 0 1px #ffda2d;
            }
            & #interact_shadow {
              background: radial-gradient(#97d1bc, white);
              box-shadow: 0 0 1px #97d1bc;
            }
            & #report_shadow {
              background: radial-gradient(#172e38, white);
              box-shadow: 0 0 1px #172e38;
            }
            & #diagnostic_shadow {
              background: radial-gradient(#ffbbb8, white);
              box-shadow: 0 0 1px #ffbbb8;
            }
            & #curriculum_shadow {
              background: radial-gradient(#faca92, white);
              box-shadow: 0 0 1px #faca92;
            }
          }
          & p {
            margin: 0;
            margin-top: 10px;
            font-weight: 600;
            font-size: 25px;
            line-height: 34px;
            color: #172e38;
          }
        }
      }
    }
  }
}

.section_4_wrapper {
  text-align: center;
  position: relative;
  padding-left: 5%;
  padding-right: 5%;
  max-width: 1600px;
  margin: 0 auto;
  & .section_four_image {
    width: 100%;
  }
  & h2 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    color: #19252a;
  }
  & .why_wrapper {
    display: flex;
    position: absolute;
    flex-direction: row;
    justify-content: center;
    margin-left: 15%;
    margin-right: 15%;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(-50%);
    background-color: #f6fffc;
    border-radius: 15px;
    height: 70%;
    align-items: center;
    justify-content: center;
    & .why_card {
      width: 100%;
      & h3 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 45px;
        line-height: 52px;
        margin: 0 0 0 0;
      }
      & p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #505d62;
        margin: 0 0 0 0;
      }
    }
  }
}

.section_5_wrapper {
  background-color: white;
  padding-left: 10%;
  padding-right: 10%;
  position: relative;
  height: 679.55px;
  overflow: hidden;
  & .section_five_image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
  }
  & .section_5_content {
    position: absolute;
    width: 75%;
    left: 34%;
    top: 150px;
  }
  & .section_5_title {
    display: flex;
    flex-direction: column;
    width: 412px;
    position: absolute;
    top: 168px;
    & p {
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      color: #505d62;
    }
    & .section_5_text_desktop {
      width: 430px;
      & h2 {
        margin: 0px 0px 0px 0px;
        font-weight: 700;
        font-size: 40px;
        line-height: 150%;
      }
    }
    & .section_5_text_mobile {
      display: none;
    }
  }
}

.review_slides {
  background-color: white;
  transition: transform 0.5s;
  width: 250px;
  height: 280px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 20px;
  // border: 2px solid transparent;
  &:focus {
    outline: none;
    // border: 2px solid #36a77e;
  }
  & .review_title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }
  & .review_comment {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
  & .review_slides_content_wrapper {
    padding-top: 8%;
    & .review_top_wrapper {
      // display: grid;
      display: flex;
      gap: 10px;
      align-items: end;
      grid-template-columns: auto auto;
      margin-bottom: 14px;
      & .review_top_container {
        & .mobile_star {
          display: none;
          visibility: hidden;
        }
        //   padding-left: 15%;
        & h2 {
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100px;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
        }
        & p {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
        }
      }
      & .star_review_wrapper {
        justify-self: center;
        //   padding-left: 15%;
      }
    }
  }
  & .review_title {
    margin: 0 0 0 0;
    padding-top: 5px;
    white-space: nowrap;
  }
  & .review_comment {
    margin: 0 0 0 0;
    padding-top: 10px;
  }
}

#marketing_carousel {
  width: 100%;
}

.section_6_wrapper {
  background-color: #ffffff;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
  overflow: hidden;
  & h2 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    color: #19252a;
    padding-top: 36px;
  }
  & .recognized_images_wrapper {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    justify-items: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 81.21px;
  }
}

.section_7_wrapper {
  position: relative;
  overflow: hidden;
  .section_seven_background {
    // position: absolute;
    width: 100%;
    bottom: 0px;
  }
  .section_seven_content_container {
    left: 0;
    right: 0;
    top: 40%;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    & h2 {
      text-align: center;
      font-weight: 700;
      font-size: 40px;
      line-height: 52px;
      color: #19252a;
      margin: 0 0 0 0;
    }
    & .buttons_wrapper {
      padding-top: 40px;
      display: flex;
      gap: 20px;
      justify-content: center;
      & .sign_up_button_wrapper {
        cursor: pointer;
        & .sign_up_button {
          border: 0px solid #172e38;
          color: #172e38;
          background-color: #6ff2c2;
          width: 187px;
          height: 57px;
          border-radius: 10px;
          transform: all 0.3s;
        }
        & .sign_up_button.active {
          background-color: #31d196;
          outline: none !important;
          border-color: black;
          box-shadow: none;
          outline: black;
        }
        & .sign_up_button_cover {
          width: 187px;
          height: 57px;
          border: 1.5px solid #19252a;
          box-sizing: border-box;
          border-radius: 10px;
          transform: rotate(-4deg);
          position: absolute;
          transition: all 0.3s;
        }
        & .sign_up_button_cover.active {
          transform: rotate(0deg);
          outline: none !important;
          border-color: black;
          box-shadow: none;
          outline: black;
        }
      }
      & .demo_button {
        width: 187px;
        height: 57px;
        border-radius: 10px;
        transition: all 0.2s;
      }
      & .demo_button:hover {
        color: white;
        background-color: #172e38;
      }
    }
  }
}

@media only screen and (max-width: 1360px) {
  .section_1_wrapper {
    padding-left: 5%;
    & .section_one_bg_image_four {
      left: 1%;
    }
  }
}

@media only screen and (min-width: 1600px) {
  .section_5_wrapper {
    & .section_5_content {
      width: 1050px;
    }
  }
}

@media only screen and (max-width: 1700px) {
  .section_5_wrapper {
    & .section_5_content {
      left: 38%;
    }
  }
}

@media only screen and (max-width: 1500px) {
  .section_2_wrapper {
    & .benefits_grid {
      gap: 12px;
    }
  }
  .section_3_wrapper {
    & .dotted_lines_container {
      & .dotted_wrapper {
        & .section_three_dotted_lines {
          width: 830px;
        }
        & .section_three_buttons_wrapper {
          padding-top: 50px;
          gap: 100px;
          & div {
            width: 109px;
            & p {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  //SECTION FOUR
  .section_4_wrapper {
    & .why_card {
      padding-bottom: 20px;
      padding-top: 20px;
    }
  }

  .section_5_wrapper {
    & .section_5_content {
      left: 44%;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .section_2_wrapper {
    & .benefits_grid {
      gap: 10px;
    }
  }
  //SECTION FOUR
  .section_4_wrapper {
    text-align: center;
    & .why_wrapper {
      height: 150px;
    }
  }

  .section_5_wrapper {
    & .section_5_content {
      left: 41%;
    }
  }
}

@media only screen and (max-width: 1300px) {
  .section_5_wrapper {
    & .section_5_content {
      left: 45%;
    }
  }
}

@media only screen and (max-width: 1239px) {
  //SECTION ONE
  .section_1_wrapper {
    display: grid;
    padding-top: 40px;
    padding-left: 5%;
    padding-right: 5%;
    height: 700px;
    justify-items: center;
    & .section_one_card_wrapper {
      padding-top: 0px;
      padding-left: 0px;
      padding-bottom: 0px;
      & h2 {
        font-size: 35px;
        text-align: center;
      }
      & p {
        text-align: center;
        width: 350px;
        font-size: 18px;
      }
      & .section_one_buttons_wrapper {
        justify-content: center;
      }
    }
    //background images
    & .section_one_bg_image_one {
      display: none;
      visibility: hidden;
    }
    & .section_one_bg_image_two {
      display: none;
      visibility: hidden;
    }
    & .section_one_bg_image_four {
      display: none;
      visibility: hidden;
    }

    & .section_one_bg_image_three {
      top: 50%;
      left: -20px;
      z-index: -1;
    }
    & .section_one_bg_image_five {
      top: 70%;
      left: -100px;
      width: 60%;
      z-index: -1;
    }
    & .section_one_bg_image_six {
      top: 70%;
      right: -100px;
      width: 60%;
      z-index: -1;
    }
    & .section_one_bg_image_seven {
      top: 40%;
      right: -80px;
      width: 36%;
      z-index: -1;
    }
    //backgroudn images done
    & .section_one_outer {
      display: none;
      visibility: hidden;
    }
    & .image_slider {
      display: none;
      visibility: hidden;
    }
    & .macbook_mobile {
      display: initial;
      visibility: visible;
      width: 50%;
    }
  }

  .section_5_wrapper {
    & .section_5_content {
      left: 41%;
    }
  }

  .section_2_wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 1300px) {
  .section_5_wrapper {
    & .section_5_content {
      left: 45%;
    }
  }
}

@media only screen and (max-width: 1360px) {
  .section_2_wrapper .benefit_cards_wrapper .benefit_card {
    width: 245px;
    min-width: 245px;
  }
}

@media only screen and (max-width: 1340px) {
  .section_5_wrapper {
    & .section_5_content {
      left: 45%;
    }
  }
}

@media only screen and (max-width: 1279px) {
  .section_2_wrapper {
    & .benefits_grid {
      gap: 5%;
      height: 350px;
      justify-content: center;
    }
    & .section_two_mobile {
      display: block;
      visibility: visible;
      margin: 0 auto;
      padding-bottom: 20px;
      text-align: center;
      margin-top: 0px;
    }
    & .demo_button_mobile {
      display: initial;
      visibility: visible;
    }
    & .sub_text_two_mobile {
      display: initial;
      visibility: visible;
    }
    & .demo_button {
      display: none;
      visibility: hidden;
    }
    & .section_two_image_container {
      display: none;
      visibility: hidden;
      & .section_two_image {
        display: none;
        visibility: hidden;
      }
    }
    & .benefit_text_wrapper {
      width: 340px;
      padding-right: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      height: 100%;
      margin: auto;
      text-align: center;
      h2,
      .sub_text_one {
        margin: 0 auto;
        max-width: 400px;
        text-align: center;
        margin-bottom: 20px;
      }
      & .sub_text_two {
        display: none;
        visibility: hidden;
      }
    }
    & .benefit_cards_wrapper {
      justify-items: center;
      padding-bottom: 30px;
      & .benefit_card {
        background-color: white;
        height: 230px;
        padding: 20px 25px;
        & h2 {
          font-size: 50px;
          padding-right: 20px;
          margin-top: 15px;
        }
        & h3 {
          font-size: 23px;
          margin-top: 20px;
          margin-bottom: 5px;
        }
        & p {
          font-size: 16px;
          margin-top: 10px;
          margin-bottom: 0px;
          line-height: 28px;
        }
        & .benefit_card_text {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 965px) {
  .section_2_wrapper {
    padding-top: 40px;
    .benefit_cards_wrapper .benefit_card {
      width: 180px;
      min-width: 180px;
      height: 280px;
      padding: 20px 25px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  //section three
  .section_3_wrapper {
    & .dotted_lines_container {
      padding-top: 30px;
      & .dotted_wrapper {
        & .section_three_dotted_lines {
          width: 680px;
        }
        & .section_three_buttons_wrapper {
          gap: 110px;
          & div {
            width: 100%;
            & .button_image_container {
              width: 80px;
              height: 87.87px;
              padding-top: 25px;
              & .button_shadow {
                width: 73px;
                height: 70.87px;
              }
              & .section_three_buttons {
                width: 73px;
                height: 70.87px;
              }
            }
            & p {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  //SECTION FOUR
  .section_4_wrapper {
    margin-left: 0%;
    margin-right: 0%;
    & .section_four_image_mobile {
      width: 100%;
    }
  }

  .section_5_wrapper {
    & .section_5_content {
      left: 48%;
    }
  }
}

@media only screen and (max-width: 1100px) {
  //SECTION ONE
  .section_1_wrapper {
    & .macbook_mobile {
      width: 55%;
    }
  }

  //SECTION THREE
  .section_3_wrapper {
    & .dotted_lines_container {
      padding-top: 20px;
      & .dotted_wrapper {
        & .section_three_dotted_lines {
          width: 630px;
        }
        & .section_three_buttons_wrapper {
          gap: 80px;
        }
      }
    }
  }

  //SECTION FOUR
  .section_4_wrapper {
    text-align: center;
    & .section_four_image_mobile {
      width: 100%;
    }
    & .why_wrapper {
      height: 100px;
      & .why_card {
        width: 100%;
        & h3 {
          font-size: 35px;
        }
        & p {
          font-size: 18px;
        }
      }
    }
  }

  //SECTION FIVE
  .section_5_wrapper {
    background-color: white;
    padding-left: 10%;
    padding-right: 10%;
    position: relative;
    height: 700px;
    overflow: hidden;
    & .section_five_image {
      position: absolute;
      width: 100%;
      height: 679.55px;
      left: -2px;
      top: 0px;
    }
    & .section_5_content {
      left: 5%;
      top: 345px;
    }
    & .section_5_title {
      display: flex;
      flex-direction: column;
      width: 609px;
      position: absolute;
      top: 30px;
      border-radius: 10px;
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;
      left: 35%;
      margin-left: -154.5px;
      align-items: center;
      & h2 {
        margin: 0px 0px 0px 0px;
        font-size: 35px;
      }
      & p {
        text-align: center;
        width: 280px;
      }
      & .section_5_text_mobile {
        display: block;
        text-align: center;
      }
      & .section_5_text_desktop {
        display: none;
      }
    }
  }

  //REVIEW SLIDES
  .review_slides {
    width: 266px;
    height: 269px;
    & .review_slides_content_wrapper {
      padding-top: 5%;
      & .review_top_wrapper {
        // display: grid;
        display: block;
        align-items: center;
        grid-template-columns: auto auto 200px;
        & .review_top_container {
          //   padding-left: 15%;
          & h2 {
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100px;
            font-size: 16px;
          }
          & p {
            font-size: 14px;
          }
        }
        & .star_review_wrapper {
          justify-self: start;
        }
      }
    }
    & .review_title {
      margin: 0 0 0 0;
      padding-top: 5px;
      white-space: nowrap;
      font-size: 16px;
    }
    & .review_comment {
      margin: 0 0 0 0;
      padding-top: 10px;
      font-size: 14px;
    }
  }

  //SECTION SIX
  .section_6_wrapper {
    background-color: #ffffff;
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
    overflow: hidden;
    padding-top: 0px;
    padding-bottom: 0px;
    & .recognized_images_wrapper {
      display: grid;
      grid-template-columns: auto auto auto auto auto;
      justify-items: center;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 20px;
      & .partner_image {
        width: 80%;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  //SECTION ONE
  .section_1_wrapper {
    & .macbook_mobile {
      width: 70%;
    }
  }

  //SECTION TWO
  .section_2_wrapper {
    & .benefit_cards_wrapper {
      grid-gap: 15px;
      & .benefit_card {
        width: 150px;
      }
    }
  }

  .section_3_wrapper {
    & .dotted_lines_container {
      & .dotted_wrapper {
        & .section_three_buttons_wrapper {
          gap: 83px;
        }
      }
    }
  }

  .section_5_wrapper {
    & .section_5_title {
      left: 30%;
    }
  }

  //SECTION SEVEN
  .section_7_wrapper {
    height: 535px;
    & .section_seven_content_container {
      top: 48%;
    }
    & .section_seven_background {
      position: absolute;
      width: 150%;
      left: -25%;
      right: -25%;
    }
  }
}

@media only screen and (max-width: 800px) {
  //SECTION ONE
  .section_1_wrapper {
    & .macbook_mobile {
      width: 80%;
    }
  }
  //SECTION THREE
  .section_3_wrapper {
    & .dotted_lines_container {
      padding-top: 10px;
      & .dotted_wrapper {
        & .section_three_dotted_lines {
          width: 580px;
        }
        & .section_three_buttons_wrapper {
          gap: 70px;
          & div {
            & .button_image_container {
              padding-top: 25px;
              & .button_shadow {
                width: 73px;
                height: 70.87px;
              }
              & .section_three_buttons {
                width: 73px;
                height: 70.87px;
              }
            }
            & p {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  //SECTION ONE
  .section_1_wrapper {
    height: 670px;
    & .macbook_mobile {
      width: 80%;
    }

    & .section_one_card_wrapper {
      & .section_one_buttons_wrapper {
        gap: 15px;
      }
      & h2 {
        font-size: 32px;
        line-height: 150%;
      }
      & p {
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
      }
    }
  }

  .section_2_wrapper {
    & .benefit_text_wrapper {
      margin-bottom: 20px;
      & .sub_text_two {
        display: none;
        visibility: hidden;
      }
    }
    & .section_two_mobile {
      margin-top: 55px;
    }
    & .benefit_cards_wrapper {
      & .benefit_card {
        width: 380px;
        display: flex;
        padding: 10px 20px;
        height: 112px;
        // margin-bottom: 16px;
        &:hover {
          margin-bottom: 0px;
        }
        &:focus {
          margin-bottom: 0px;
        }
        & h3 {
          margin-top: 0px;
          margin-bottom: 0px;
        }
        & p {
          margin-top: 0px;
        }
        & .benefit_card_text {
          & h3 {
            font-size: 18px;
          }
        }
      }
    }
  }

  //SECTION THREE
  .section_3_wrapper {
    & .section_three_card_container {
      display: flex;
      justify-content: center;
      height: 364px;
      & .section_three_card_image {
        opacity: 1;
      }
      & .section_three_card_content {
        position: absolute;
        width: 80%;
        height: 364px;
        text-align: center;
        opacity: 1;
        & h2 {
          font-weight: 700;
          font-size: 25px;
          line-height: 150%;
          margin-top: 80px;
        }
        & p {
          font-size: 15px !important;
        }
      }
    }
    & .section_three_card_image {
      width: 88%;
    }
    & .dotted_lines_container {
      padding-top: 0px;
      & .dotted_wrapper {
        & .section_three_dotted_lines {
          width: 500px;
        }
        & .section_three_buttons_wrapper {
          gap: 45px;
          & div {
            & .button_image_container {
              padding-top: 23px;
              & .button_shadow {
                width: 65px;
                height: 63px;
              }
              & .section_three_buttons {
                width: 65px;
                height: 63px;
              }
            }
            & p {
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  .section_4_wrapper {
    & .why_wrapper {
      & .why_card {
        & h3 {
          font-size: 25px;
        }
      }
    }
  }

  .section_5_wrapper {
    & .section_5_title {
      left: 27%;
    }
  }

  //SECTION SEVEN
  .section_7_wrapper {
    height: 420px;
    & .section_seven_content_container {
      top: 45%;
    }
  }
}
@media only screen and (max-width: 600px) {
  //SECTION ONE
  .section_1_wrapper {
    height: 620px;
    & .macbook_mobile {
      width: 80%;
    }
  }
  //SECTION TWO
  .section_2_wrapper {
    overflow: hidden;
    & .benefit_cards_wrapper {
      & .benefit_card {
        width: 278px;
      }
    }
  }

  //SECTION THREE
  .section_3_wrapper {
    padding-bottom: 10px;
    & h2 {
      font-size: 25px;
    }
    & .section_three_card_container {
      height: 300px;
      background-color: #fff8f8;
      & .section_three_card_content {
        width: 90%;
        & h2 {
          font-size: 20px;
          margin-top: 70px;
        }
        & p {
          font-size: 14px;
        }
      }
    }
    & .dotted_lines_container {
      & .dotted_wrapper {
        & .section_three_dotted_lines {
          width: 420px;
        }
        & .section_three_buttons_wrapper {
          gap: 23px;
          & div {
            & .button_image_container {
              padding-top: 14px;
              & .button_shadow {
                width: 50px;
                height: 48.54px;
              }
              & .section_three_buttons {
                width: 50px;
                height: 48.54px;
              }
            }
            & p {
              margin-top: 0;
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .section_4_wrapper {
    padding-left: 0px;
    padding-right: 0px;
    & .why_wrapper {
      height: 70%;
      margin-left: 5%;
      margin-right: 5%;
    }
  }

  .section_5_wrapper {
    height: 700px;
    & .section_5_content {
      top: 315px;
      & .marketing_carousel {
        & .marketing_carousel_dots {
          display: none;
          visibility: hidden;
        }
      }
    }
    & .section_5_title {
      width: 320px;
      margin-left: 0px;
      & h2 {
        font-weight: 700;
        font-size: 25px;
        line-height: 150%;
        color: #19252a;
      }
      & p {
        font-size: 18px;
      }
    }
    & .section_five_image {
      display: none;
      visibility: hidden;
    }
  }

  .section_6_wrapper {
    & h2 {
      font-size: 20px;
    }
  }

  //SECTION SEVEN
  .section_7_wrapper {
    height: 480px;
    & .section_seven_background {
      width: 200%;
      left: -50%;
      right: -50%;
    }
    & .section_seven_content_container {
      & h2 {
        font-size: 25px;
        line-height: 37.5px;
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .section_5_wrapper {
    & .section_5_title {
      left: 18%;
    }
  }
}

@media only screen and (max-width: 500px) {
  //SECTION THREE
  .section_3_wrapper {
    & .section_three_card_image {
      margin-bottom: 100px;
      width: 90%;
    }
    & .section_three_card_container {
      height: 300px;
      & .section_three_card_content {
        & h2 {
          margin-top: 45px;
        }
      }
    }
    & .dotted_lines_container {
      & .dotted_wrapper {
        & .section_three_dotted_lines {
          width: 300px;
        }
        & .section_three_buttons_wrapper {
          gap: 10px;
          & div {
            width: 70px;
            & .button_image_container {
              padding-top: 5px;
              & .section_three_buttons.active {
                border: 3.5px solid white;
                outline: 2.5px solid #e5e5e5;
                margin-bottom: -3px !important;
              }
              & .button_shadow {
                width: 40px;
                height: 38.83px;
              }
              & .section_three_buttons {
                width: 40px;
                height: 38.83px;
              }
            }
            & p {
              margin-top: 0;
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  //SECTION FOUR
  .section_4_wrapper {
    & .why_wrapper {
      height: 70%;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  //SECTION SEVEN
  .section_7_wrapper {
    height: 400px;
    & .section_seven_content_container {
      & .buttons_wrapper {
        padding-top: 20px;
      }
      top: 45%;
      & h2 {
        font-size: 25px;
      }
    }
  }
}

@media only screen and (max-width: 490px) {
  .section_5_wrapper {
    & .section_5_title {
      left: 15%;
    }
  }
  .section_6_wrapper {
    & h2 {
      font-size: 20px;
      line-height: 27px;
    }
  }
}

@media only screen and (max-width: 450px) {
  .section_5_wrapper {
    & .section_5_title {
      left: 12%;
    }
  }
}

@media only screen and (max-width: 400px) {
  .section_5_wrapper {
    & .section_5_title {
      left: 9%;
    }
  }

  .sign_up_button {
    width: 161px;
  }

  //SECTION ONE
  .section_1_wrapper {
    height: 530px;
    padding-left: 0%;
    padding-right: 0%;
    & .macbook_mobile {
      width: 350px;
    }
    & .section_one_card_wrapper {
      margin-left: 5%;
      margin-right: 5%;
      & .section_one_buttons_wrapper {
        gap: 15px;
        & .sign_up_button_cover {
          width: 161px;
        }
      }
      & h2 {
        text-align: start;
      }
      & p {
        text-align: start;
      }
      & .section_one_buttons_wrapper {
        padding-top: 10px;
      }
    }
  }

  //SECTION THREE
  .section_3_wrapper {
    padding-bottom: 0px;
    height: 560px;
    & .section_three_card_image {
      margin-bottom: 140px;
      width: 90%;
    }
    & .section_three_card_container {
      height: 290px;
      & .section_three_card_content {
        width: 320px;
        & h2 {
          font-size: 16px;
          margin-top: 32px;
          margin-bottom: 0px;
        }
        & p {
          font-size: 10px;
        }
      }
    }
    & .dotted_lines_container {
      width: 100%;
      & .dotted_wrapper {
        padding-top: 0px;
        width: 250px;
        & .section_three_dotted_lines {
          width: 100%;
        }
        & .section_three_buttons_wrapper {
          gap: 15px;
          & div {
            & .button_image_container {
              & .section_three_buttons.active {
                border: 2.5px solid white;
                outline: 1.5px solid #e5e5e5;
                margin-bottom: -2px !important;
              }
            }
            width: 55px;
            & p {
              font-size: 10px;
            }
          }
        }
      }
    }
  }

  //SECTION FOUR
  .section_4_wrapper {
    & .why_wrapper {
      & .why_card {
        & h3 {
          font-weight: 700;
          font-size: 20px;
          line-height: 16px;
          color: #19252a;
          margin-top: 10px;
        }
        & p {
          font-size: 12px;
        }
      }
    }
  }

  //REVIEW SLIDES
  .review_slides {
    padding-left: 10%;
    padding-right: 10%;
    & .review_slides_content_wrapper {
      padding-top: 5%;
      & .review_top_wrapper {
        display: grid;
        align-items: center;
        grid-template-columns: 150px auto;
        & .review_top_container {
          padding-left: 5%;
          & .mobile_star {
            display: initial;
            visibility: visible;
          }
          & h2 {
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100px;
            font-size: 16px;
          }
          & p {
            font-size: 14px;
          }
        }
        & .destop_star {
          display: none;
          visibility: hidden;
        }
        & .star_review_wrapper {
          justify-self: start;
          padding-left: 0px;
        }
      }
    }
    & .review_title {
      margin: 0 0 0 0;
      padding-top: 5px;
      white-space: nowrap;
      font-size: 16px;
    }
    & .review_comment {
      margin: 0 0 0 0;
      padding-top: 10px;
      font-size: 14px;
    }
  }

  .section_6_wrapper {
    & h2 {
      padding-top: 0px;
    }
    & .recognized_images_wrapper {
      padding-bottom: 0px;
    }
  }

  //SECTION SEVEN
  .section_7_wrapper {
    height: 350px;
    & .section_seven_content_container {
      & h2 {
        line-height: 150%;
      }
      & .buttons_wrapper {
        gap: 15px;
        & .sign_up_button_wrapper {
          & .sign_up_button {
            width: 171px;
            height: 57px;
          }
          & .sign_up_button_cover {
            width: 171px;
            height: 57px;
          }
        }
        & .demo_button {
          width: 150px;
          height: 45.72px;
        }
      }
    }
  }
}

@media only screen and (max-width: 390px) {
  .section_5_wrapper {
    & .section_5_title {
      left: 7%;
    }
  }
}

@media only screen and (max-width: 360px) {
  .section_5_wrapper {
    & .section_5_title {
      left: 5%;
    }
  }

  //SECTION SEVEN
  .section_7_wrapper {
    & .section_seven_content_container {
      & .buttons_wrapper {
        gap: 15px;
        & .sign_up_button_wrapper {
          & .sign_up_button {
            width: 161px;
            height: 57px;
          }
          & .sign_up_button_cover {
            width: 161px;
            height: 57px;
          }
        }
      }
    }
  }
}

//tablet font sizes
@media only screen and (min-width: 600px) and (max-width: 1239px) {
  .section_1_wrapper {
    & .section_one_card_wrapper {
      height: 200px;
      & h2 {
        font-size: 37px;
      }
    }
  }

  .section_2_wrapper {
    & .benefit_text_wrapper {
      & h2 {
        font-size: 32px;
      }
      & .sub_text_one {
        font-size: 18px;
      }
    }
  }

  .section_3_wrapper {
    & h2 {
      font-size: 32px;
    }
    & .section_three_card_container {
      & .section_three_card_content {
        & h2 {
          font-size: 25px;
        }
        & p {
          font-size: 16px;
        }
      }
    }
  }

  .section_4_wrapper {
    & .why_wrapper {
      & .why_card {
        & p {
          font-size: 16px;
        }
      }
    }
  }

  .section_5_wrapper {
    & .section_5_title {
      & h2 {
        font-size: 32px;
      }
      & p {
        font-size: 18px;
      }
    }
  }

  .section_6_wrapper {
    & h2 {
      font-size: 28px;
    }
  }

  .section_7_wrapper {
    & .section_seven_content_container {
      & h2 {
        font-size: 32px;
        line-height: 45px;
      }
    }
  }
}

//mobile font sizes
@media only screen and (min-width: 360px) and (max-width: 600px) {
  .section_1_wrapper {
    & .section_one_card_wrapper {
      & h2 {
        font-size: 32px;
      }
    }
  }

  .section_2_wrapper {
    & .benefit_text_wrapper {
      & h2 {
        font-size: 25px;
      }
      & .sub_text_one {
        font-size: 18px;
        line-height: 150%;
      }
    }
    & .benefit_cards_wrapper {
      & .benefit_card {
        & .benefit_card_text {
          & p {
            font-size: 14px;
            line-height: 19px;
          }
        }
      }
    }
  }

  .section_3_wrapper {
    & h2 {
      font-size: 25px;
      line-height: 150%;
    }
    & .section_three_card_container {
      width: 100%;
      & .section_three_card_content {
        & h2 {
          font-size: 16px;
        }
        & p {
          font-size: 14px !important;
        }
      }
    }
  }

  .section_4_wrapper {
    & .why_wrapper {
      & .why_card {
        & h3 {
          font-size: 20px;
          line-height: 15.77px;
        }
        & p {
          font-size: 12px;
        }
      }
    }
  }

  .section_5_wrapper {
    & .section_5_title {
      & h2 {
        font-size: 25px;
      }
      & p {
        font-size: 18px;
      }
    }
  }

  .section_6_wrapper {
    & h2 {
      font-size: 20px;
    }
  }

  .section_7_wrapper {
    & .section_seven_content_container {
      top: 52%;
      & h2 {
        font-size: 25px;
        line-height: 25px;
      }
      & .buttons_wrapper {
        margin-top: 15px;
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .section_7_wrapper {
    & .section_seven_content_container {
      top: 55%;
      & h2 {
        font-size: 22px;
        line-height: 25px;
      }
      & .buttons_wrapper {
        margin-top: 10px;
      }
    }
  }
}
