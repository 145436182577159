$primary_color: #36a77e;
$secondary_color: #19252a;
$default_dark: #26353b;

$default_text_color: #19252a;

$primary_text-color: #180202;
$primary_links: #172e38;

$secondary_text-color: #5d6970;

// <---- Interactive States ------ >
$primary_focus: #36a77e;
$primary_hover: rgba(58, 90, 255, 0.14);

// <---- Color classes ------ >
.primary-color {
  color: $primary_color;
}

.back_default_grey {
  background: #f8f8f8;
}
