@import '../../styles/colours.scss';

.top_step_indicator {
  min-height: auto;
  padding-top: 36px;
  padding-bottom: 26px;
}

.diagnostic_wrapper{
  min-height: 100vh;
}

.question_top_section {
  margin-top: 40px !important;
}

.top_step_indicator-background {
  background: #f8f8f8;
}

.diagnostic_step_title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #172e38;
}

.welcome_wrapper {
  padding: 30px 7%;
}

.diagnostic_welcome_box {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border-left: 130px solid;
  border-left-color: $secondary_color;
  padding: 38px 80px;
  max-width: 960px;
  margin: 0 auto;
  & .grade_number {
    position: absolute;
    top: 45px;
    left: -90px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #33cc94;
    & .number {
      padding-top: 10px;
      display: block;
      font-size: 32px;
      font-weight: bold;
    }
  }

  & .box_image {
    position: absolute;
    bottom: 0px;
    left: -131px;
    width: 155px;
  }

  & .progress_bar {
    position: absolute;
    top: 25px;
    right: 25px;
  }

  & .title {
    margin: 60px 0px 15px 0px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    color: #6a6969;
  }

  & .topic {
    margin: 0px 0px 15px 0px;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    color: #000000;
    text-transform: capitalize;
  }

  & .description {
    margin: 0px 0px 35px 0px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
  }

  & .timeline {
    margin: 0px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;

    & .dot {
      font-size: 45px;
      vertical-align: bottom;
    }
  }

  & .button_wrapper {
    text-align: center;
    padding: 45px 0px;
  }
}

.tailored_plan_button {
  margin-left: 20px;
}

.question_progress_wrapper {
  padding-right: 120px;
  margin-top: 4px;
  & .number_indicator {
    cursor: default;
    font-style: normal;
    font-weight: bold;
    font-size: 9.39303px;
    line-height: 13px;
    color: #172e38;
    border: 1px solid #172e38;
    box-sizing: border-box;
    border-radius: 100%;
    width: 22px;
    height: 22px;
    text-align: center;
    padding: 3px 2px;
    margin-top: -5px;
  }
}

.test_outline_wrapper {
  width: 386px;
}

.test_outline_box {
  max-width: 310px;
  padding: 20px;

  & .test_outline_box_description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-top: 10px;
    margin-left: 39px;
    margin-right: 10px;
  }
}

.question_outline_box-mobile {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 17px 20px;
  position: relative;
  margin-bottom: 20px;
  & .close_outline {
    position: absolute;
    right: 21px;
    top: 15px;
    cursor: pointer;
    border: none;
    background: none;
    padding-top: 5px;
    &:focus {
      outline-color: $primary_color;
    }
  }
  & .title {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #19252a;
    margin: 0px;
  }
  & .skill_title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #221e1e;
    margin: 25px 0;
  }
  & .skill_description {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin: -10px 0 20px 0;
  }
  & .question_outline_hr {
    border: 1px solid #ececec;
    margin: 0px;
  }
}

.question_hr {
  border: 1px solid #ececec;
  margin-top: 0px;
}

.test_outline_title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #19252a;
  margin-left: 20px;
}

.box_expanded {
  background: #ffffff;
  box-shadow: 0px 3.002px 30px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}

.test_outline_box_title {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  color: #19252a;
}

.test_outline_number_indicator {
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
  line-height: 13px;
  color: #fff;
  border: 1px solid #172e38;
  box-sizing: border-box;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: inline-block;
  text-align: center;
  padding: 4px 5px;
  margin-top: -5px;
  background: #19252a;
  border: 6px solid #354045;
  margin-right: 8px;
  vertical-align: middle;
}

.question_body {
  padding: 24px 90px 0 90px;
  position: relative;
  &:focus {
    z-index: 1000;
    outline-color: $primary_color;
  }
}

.next_question_button {
  background: none;
  border: none;
  box-shadow: none;
  position: absolute;
  top: 60px;
  right: -48px;
  cursor: pointer;
  width: 100px;
  height: 100px;
  overflow: hidden;
  & .icon_desktop {
    top: -35px;
    left: -17px;
    position: absolute;
    width: 170px;
  }
  &:focus {
    outline-color: $primary_color;
  }
  & .icon_mobile {
    visibility: hidden;
    display: none;
  }
}

.next_report_button {
  top: 228px;
  right: 24px;
}

.report_wrapper {
  background-color: $default_text_color;
  padding-top: 50px;
  padding-bottom: 10px;
  & .pre_button_wrapper {
    text-align: end;
  }
  & .next_button {
    background: none;
    border: none;
    position: absolute;
    top: 45%;
    right: 10px;
    cursor: pointer;
    &:focus {
      outline-color: $primary_color;
    }
  }

  & .practice_plan_box {
    background: #25353b;
    box-shadow: 0px 3.002px 15.01px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 80px 25px 25px 25px;
    margin-top: 32px;
    height: 227px;
    max-height: 227px;
    & .title {
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      line-height: 22px;
      color: #6ff2c2;
      margin: 0 0 10px 0;
    }
    & .beaten {
      font-style: normal;
      font-weight: normal;
      font-size: 16.5714px;
      line-height: 23px;
      color: #919a9e;
      & .percentage {
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
      }
    }
    & .level_title {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #c4c4c4;
      margin: 0 0 10px 0;
      width: 90px;
    }
    & .grade {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #31d197;
    }
    & .grade-mobile {
      visibility: hidden;
      display: none;
    }
    & .level {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #fda78f;
    }
    & .practice_plan_wrapper {
      margin-bottom: -11px;
      margin-top: 17px;
    }
    &:focus {
      outline-color: $primary_color;
    }
    & .plan_title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      margin: 0 0 10px 0;
    }
    & .difficult_title {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      color: #c4c4c4;
      margin-right: 5px;
      width: 84px;
    }
    ul {
      padding-left: 18px;
    }
    & .topics {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #919a9e;
      margin-bottom: 8px;
    }
    & .difficult_list {
      list-style-type: none;
      padding-top: 1px;
      width: 88px;
    }
  }

  & .display_more {
    width: 200px;
    color: rgba(255, 255, 255, 0.4);
    margin: 0 auto;
    cursor: pointer;
    box-shadow: none;
    border: none;
    background: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.4);
    font-family: 'Open Sans', sans-serif;
  }
}

.question_outline_mobile {
  display: none;
  visibility: hidden;
}

.question_outline_mobile_wrapper {
  background: #f8f8f8;
  border-radius: 20px;
  padding: 6px 20px 17px 20px;
  min-height: 55px;
  min-width: 145px;
  & .round_icon_button {
    & .icon {
      margin-top: 0px;
    }
  }
  & .outline_mobile_title {
    margin: 6px 0px 10px 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #19252a;
  }
  & .outline_question_title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #19252a;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.next_button_mobile {
  visibility: hidden;
  display: none;
  &:focus {
    outline-color: $primary_color;
  }
}

.more_info_tab {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  margin: 0 auto;
}

.recommended_skills_box {
  background: #ffffff;
  box-shadow: 0px 3.002px 15.01px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 23px 42px;
  height: 210px;
  & .score_number {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    color: #000000;
  }
  & .top_content {
    padding-bottom: 15px;
    & .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #19252a;
    }
    & .score {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #697276;
    }
  }
  & .grade {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #43555c;
  }
  & .grade_level_box {
    background: #f8f8f8;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #19252a;
    padding: 5px 9px;
    & .level {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #22805e;
    }
  }
  & .description {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #697276;
    margin-top: 32px;
  }
}

.button_mobile {
  visibility: hidden;
  display: none;
}

.performance_chart-mobile {
  visibility: hidden;
  display: none;
}

.performance_chart_wrapper {
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #919a9e;
  padding-top: 30px;
  margin-right: 20px;
  & .average_line {
    position: absolute;
    top: 0px;
    height: 382px;
  }
  & .performance_line {
    left: 0px;
    position: absolute;
    width: 100%;
  }
  & .average_label {
    position: absolute;
    bottom: -50px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #919a9e;
  }
  & .performance_fundamental {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  & .performance_intermediate {
    position: absolute;
    top: 0px;
    left: 40%;
  }
  & .performance_advanced {
    position: absolute;
    top: 0px;
    left: 70%;
  }
  & .performance_master {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  & .active_level {
    color: #f99f20;
  }
  & .bar_chart {
    margin-top: 8px;
    background: rgba(80, 93, 98, 0.1);
    border-left: 4px solid #919a9e;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
    height: 40px;
    max-height: 40px;
    position: relative;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #919a9e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 12px;
    white-space: pre;
  }
  & .bar_chart:hover {
    background: rgba(210, 220, 226, 0.1);
    border-color: #d2dce2;
  }
  & .active_bar_chart {
    border-color: #6ff2c2;
    background: rgba(111, 242, 194, 0.1);
    & .bar_label {
      color: #6ff2c2;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .report_wrapper {
    & .next_button {
      visibility: hidden;
      display: none;
    }
    & .practice_plan_box {
      margin-top: 80px;
      padding: 25px;
    }
    & .pre_button_wrapper {
      text-align: start;
      padding-bottom: 30px;
    }
    & .next_report_button {
      top: 250px;
      right: 37px;
    }
  }
}

@media only screen and (max-width: 1250px) {
  .next_report_button {
    top: 192px;
    width: 90px !important;
    right: 10px;
  }
  .recommended_skills_box {
    height: 230px;
  }
}

@media only screen and (max-width: 1200px) {
  .question_outline_desktop {
    display: none;
    visibility: hidden;
  }
  .question_outline_mobile {
    display: block;
    visibility: visible;
  }
  // .question_body {
  //   padding: 24px 90px 0px 0px;
  // }
  .question_top_section {
    margin-top: 0px !important;
  }
  .report_wrapper {
    & .next_button {
      width: 90px;
    }
  }
}

@media only screen and (max-width: 1160px) {
  & .diagnostic_welcome_box {
    padding: 30px 30px;
  }
  .recommended_skills_box {
    height: 250px;
  }
}

@media only screen and (max-width: 1100px) {
  .report_wrapper {
    & .practice_plan_box {
      & .level_title,
      .grade {
        visibility: hidden;
        display: none;
      }
      .grade-mobile {
        visibility: visible;
        display: block;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #31d197;
        margin-bottom: 10px;
      }
    }
    & .button_desktop {
      visibility: hidden;
      display: none;
    }
    & .button_mobile {
      visibility: visible;
      display: block;
      top: 95px;
      right: 13px;
    }
    & .difficult_title,
    .practice_plan_box,
    .difficult_list {
      width: auto !important;
    }
  }
}

@media only screen and (max-width: 960px) {
  .next_button_mobile {
    position: absolute;
    top: 14%;
    right: 0px;
    visibility: visible;
    display: block;
  }
  .report_wrapper {
    & .next_button {
      visibility: hidden;
      display: none;
    }
  }
  .recommended_skills_box {
    height: auto;
  }
}

@media only screen and (max-width: 900px) {
  & .diagnostic_welcome_box {
    border-left-width: 30px;
    padding: 30px 15px;
    & .box_image {
      left: -30px;
      width: 60px;
    }
    & .grade_number {
      left: -25px;
      top: 20px;
      & .grade_label {
        visibility: hidden;
        display: none;
      }
    }
    & .title {
      margin-top: 40px;
    }
  }
  .welcome_wrapper {
    padding: 0px;
  }
  .question_progress_wrapper {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 695px) {
  .question_progress_wrapper {
    margin-top: 20px;
  }
  .next_button_mobile {
    top: 30%;
  }
  .next_question_button {
    width: 40px;
    height: 40px;
    & .icon_mobile {
      visibility: visible;
      display: block;
      top: -15px;
      left: -7px;
      position: absolute;
      width: 71px;
    }
    & .icon_desktop {
      visibility: hidden;
      display: none;
    }
  }
  .report_wrapper {
    & .button_mobile {
      height: 90px;
    }
  }
}

@media only screen and (max-width: 620px) {
  .question_outline_mobile_wrapper {
    padding: 6px 14px 17px 14px;
    min-width: 85px;
    max-height: 55px;
    width: 117px;
    & .outline_question_title {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .check_buttons_wrapper {
    width: 200%;
  }
  & .diagnostic_welcome_box {
    & .title {
      font-size: 14px;
      margin-bottom: 0px;
    }
    & .topic {
      font-size: 16px;
      margin-bottom: 12px;
      text-transform: capitalize;
    }
    & .description {
      font-size: 12px;
      margin-bottom: 20px;
    }
    & .timeline {
      font-size: 14px;
      margin-bottom: 20px;
    }

    & .button_wrapper {
      padding: 15px 0px;
    }
  }
  .question_outline_box-mobile {
    margin-right: -22px;
    margin-left: -22px;
  }

  .next_question_button {
    right: 0px;
    top: 52px;
  }

  .more_info_tab {
    padding-top: 50px;
    padding-bottom: 0px;
  }
  .report_wrapper {
    & .button_mobile {
      right: -20px;
      top: 30px;
    }
    & .pre_button_wrapper {
      padding-bottom: 15px;
    }
    & .practice_plan_box {
      background: none;
      padding: 0px;
      box-shadow: none;
      margin-top: 40px;
      & .title {
        color: #ffffff;
      }
    }
  }
  .performance_chart-mobile {
    visibility: visible;
    display: inline;
  }
  .performance_chart-desktop {
    visibility: hidden;
    display: none;
  }
  .performance_chart_wrapper {
    position: relative;
    padding-left: 83px;
    & .level_master,
    .level_advanced,
    .level_intermediate,
    .level_fundamental {
      position: absolute;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      left: 0;
    }
    & .active_title {
      position: absolute;
      top: 103px;
      color: #6ff2c2;
      font-size: 12px;
      line-height: 16px;
      max-width: 127px;
      z-index: 2000;
    }
    & .master_level {
      margin-right: 10px;
      vertical-align: bottom;
    }
    & .level_advanced {
      left: 0px;
      top: 106px;
    }
    & .level_intermediate {
      top: 183px;
    }
    & .level_fundamental {
      bottom: 30px;
    }
    & tspan,
    .recharts-cartesian-axis {
      visibility: hidden;
      display: none;
    }
    & .recharts-rectangle {
      & .bottom_indicator {
        height: 20px;
        background-color: red;
      }
      border: 2px solid green;
    }
  }
}

@media only screen and (max-width: 500px) {
  .question_progress_wrapper {
    margin-top: 20px;
  }
  .report_wrapper {
    & .practice_plan_box {
      background: none;
      padding: 0px;
      box-shadow: none;
    }
  }
  .diagnostic_step_title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 440px) {
  .performance_chart_wrapper {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 380px) {
  .recommended_skills_box {
    & .top_content {
      max-width: 186px;
    }
  }
}
