@import '../../styles/colours.scss';

.diagnostic_report_top_bar {
  min-height: auto;
  padding-top: 36px;
  padding-bottom: 26px;

  & .top_button_wrapper {
    text-align: end;
  }
}

.diagnostic_report_background {
  background: #f8f8f8;
}

.diagnostic_report_wrapper {
  background-color: $default_text_color;
  padding-top: 50px;
  padding-bottom: 10px;
  & .top_report_wrapper {
    min-height: auto;

    & .top_report_sm_title {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #ffffff;
    }

    & .top_report_title {
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
      color: #ffff;
      text-transform: capitalize;
    }
  }

  & .practice_skill_button_wrapper {
    margin-top: 25px;
    text-align: end;
  }

  & .skill_information {
    background: #25353b;
    box-shadow: 0px 3.002px 15.01px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 26px 31px;

    & .skill_name {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #6ff2c2;
    }

    & .skill_level_title {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #c4c4c4;
    }

    & .skill_level_wrapper-mobile {
      display: none;
      visibility: hidden;
    }

    & .skill_level {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #fda78f;
      margin-top: 10px;
    }

    & .beaten_text {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;
      color: #ffffff;
      margin: 0;
      margin-top: 20px;

      & .beaten_percentage {
        font-size: 20px;
        font-weight: 600;
      }
    }

    & .custom_hr {
      margin-top: 20px;
      margin-bottom: 15px;
    }
  }
  & .practice_plan_wrapper {
    margin-top: 10px;
    margin-right: 10px;

    & .practice_plan_top {
      padding-bottom: 10px;
    }

    & .unit_name {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #f8f8f8;
      height: 27px;
      padding-top: 3px;
    }

    & .list_icon {
      box-sizing: border-box;
      height: 27px;
      display: inline-block;
      text-align: center;
      margin-right: 8px;
      vertical-align: middle;
      color: #f8f8f8;
    }

    & .unit_difficult {
      height: 27px;
      align-items: center;
      display: flex;
    }
  }
}

.diagnostic_report_more {
  & .more_information {
    padding-bottom: 50px;
    overflow: hidden;

    .more_info_tab {
      padding-top: 50px;
      padding-bottom: 20px;
      text-align: center;
      margin: 0 auto;
    }

    & .nothing_to_show {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 16px;
      color: #697276;
      text-align: center;
      margin-top: 20px;
    }

    & .skill_boxes_wrapper {
      padding: 0px 35px;
    }

    & .expanded_box {
      background-color: #f8f8f8;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .diagnostic_report_wrapper {
    & .next_button {
      visibility: hidden;
      display: none;
    }
    & .practice_plan_box {
      margin-top: 80px;
      padding: 25px;
    }
    & .pre_button_wrapper {
      text-align: start;
      padding-bottom: 30px;
    }
    & .next_report_button {
      top: 250px;
      right: 37px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .diagnostic_report_wrapper {
    & .next_button {
      width: 90px;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .diagnostic_report_wrapper {
    & .practice_plan_box {
      & .level_title,
      .grade {
        visibility: hidden;
        display: none;
      }
      .grade-mobile {
        visibility: visible;
        display: block;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #31d197;
        margin-bottom: 10px;
      }
    }
    & .button_desktop {
      visibility: hidden;
      display: none;
    }
    & .button_mobile {
      visibility: visible;
      display: block;
      top: 95px;
      right: 13px;
    }
    & .difficult_title,
    .practice_plan_box,
    .difficult_list {
      width: auto !important;
    }
  }
}

@media only screen and (max-width: 960px) {
  .diagnostic_report_wrapper {
    & .next_button {
      visibility: hidden;
      display: none;
    }
  }
}

@media only screen and (max-width: 695px) {
  .diagnostic_report_wrapper {
    & .button_mobile {
      height: 90px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .diagnostic_report_top_bar {
    padding-top: 10px;
    padding-bottom: 20px;
    & .top_button_wrapper {
      text-align: start;
    }
  }

  .diagnostic_report_more .more_information {
    border-radius: 0px;
    & .skill_boxes_wrapper {
      padding: 0px;
      box-shadow: none;
    }
  }
  .diagnostic_report_wrapper {
    padding-top: 20px;
    padding-bottom: 10px;
    & .practice_skill_button_wrapper {
      text-align: center;
    }
    & .top_report_wrapper .top_report_title {
      font-size: 20px;
      margin: 10px 0 0 0;
    }
    & .skill_information {
      padding: 20px 15px;
    }
    & .more_info_tab {
      padding-bottom: 20px;
    }
    & .button_mobile {
      right: -20px;
      top: 30px;
    }
    & .pre_button_wrapper {
      padding-bottom: 15px;
    }
    & .practice_plan_box {
      background: none;
      padding: 0px;
      box-shadow: none;
      margin-top: 40px;
      & .title {
        color: #ffffff;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .diagnostic_report_wrapper {
    & .practice_plan_wrapper .unit_name {
      padding-top: 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }
    & .skill_information {
      & .skill_level_title,
      .skill_level {
        display: none;
        visibility: hidden;
      }

      & .beaten_text {
        margin-top: 10px;
      }

      & .skill_level_wrapper-mobile {
        display: inline-block;
        visibility: visible;
        & .skill_level_title,
        .skill_level {
          display: inline-block;
          visibility: visible;
        }
      }
    }
    & .practice_plan_box {
      background: none;
      padding: 0px;
      box-shadow: none;
    }
  }
}
