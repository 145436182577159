@import './styles/colours.scss';
@import './styles/fontStyles.scss';
@import './styles/sizes.scss';
@import './styles/spacing.scss';
@import './styles/alignment.scss';
@import './styles/theme.scss';

html {
  scroll-behavior: smooth;

  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
    env(safe-area-inset-left);
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // added this for horizontal scroll problems, please take note as it might affect code in the future.
  overflow-x: hidden;
}

.body_with_menu_open:after {
  content: '';
  display: block;
  position: fixed;
  background-color: rgba(32, 32, 32, 0.82);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
}

.disable_scroll {
  overflow: hidden;
}

.pages_wrapper {
  min-height: 72vh;
}

.loading_wrapper {
  padding-top: 20%;
}

.admin_content_wrapper {
  padding-left: 30px;
  padding-bottom: 40px;
}

.try_demo {
  border-radius: 10px !important;
}

// < -------------- Reusable CSS ----------------------->
.nothing_to_show {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #697276;
  text-align: center;
}
.fields_error_messages {
  color: #e51937;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.interactive_element {
  cursor: pointer;
  &:focus {
    outline: 2px solid $primary_focus;
  }
  &:hover {
    background-color: $primary_hover;
  }
}

.hyperlink {
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px white, 0 0 0 4px $primary_focus;
    border-radius: 4px;
    color: #0a5e3f !important;
  }
  &:hover {
    color: #0a5e3f !important;
  }
}

.restricted_image {
  width: 400px;
  margin-left: -85px;
}

.restricted_text {
  margin-top: 0px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 36px;
  margin-left: 30px;
  color: #19252a;
  margin-bottom: 60px;
}

.restricted_login_wrapper {
  text-align: center;
  padding-right: 40px;
}

.button_icon {
  padding: 0px;
  border: none;
  background: none;
  display: inline-flex;
}

.sr_only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.MuiTabs-indicator-custom {
  width: 62px !important;
  height: 4px !important;
  margin-top: 20px !important;
}

.app_loading {
  position: fixed;
  left: 46%;
  top: 35%;
  z-index: 1000;
}

.display_json {
  border: 1px solid #666666;
  border-radius: 4px;
  padding: 20px;
  margin: 20px;
}

.hide_element {
  display: none;
  visibility: hidden;
}

// < -------------- Reusable CSS properties ----------------------->

.remove_list_decoration {
  list-style-type: none;
}

.remove_list_spacing {
  padding-left: 0px;
}

.cursor_pointer {
  cursor: pointer;
}

.cursor_none {
  cursor: default !important;
}

.position_relative {
  position: relative;
}

.display_block {
  display: block;
}

.file-upload::-webkit-file-upload-button {
  visibility: hidden;
}

.file-upload {
  content: 'Select some files';
  display: inline-block;
  color: #ffffff;
  border: none;
  background: #172e38;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 14px;
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  line-height: 16px;
  border-radius: 8px;
  text-transform: capitalize;
  margin-bottom: 50px;
  &:hover {
    background-color: #3e4a4f;
  }
}

#cookie_consent_form {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
  display: relative;
}

.circle_progress_label_hundred {
  font-size: 11.5px;
}

@media only screen and (max-width: 1500px) {
  .admin_wrapper {
    padding-right: 0%;
    padding-left: 2%;
  }
}

@media only screen and (max-width: 600px) {
  .app_loading {
    left: 42%;
  }
  .restricted_image {
    width: 300px;
    margin-left: -40px;
  }
}

@media only screen and (min-height: 1080px) {
  .pages_wrapper {
    min-height: 85vh;
  }
}
