.desktop_side_bar {
  background: #505d62;
  min-height: 100vh;
  height: auto;
  width: 200px;
  padding-top: 3%;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);

  & .side_bar_links {
    text-decoration: none;
    &:focus {
      outline-color: white;
      outline-offset: -4px;
    }
  }
}

.menu_sideBar {
  & .user_image_wrapper {
    margin-top: 50px;
    width: 77px;
    height: 77px;
    border-radius: 50%;
    background-color: #172e38;
    & .profile_picture {
      width: 77px;
      height: 77px;
      border-radius: 50%;
    }

    & .profile_icon_default {
      width: 40px;
      height: 40px;
      color: white;
      margin-top: 7px;
      margin-top: 17px;
      margin-left: 17px;
    }
  }
  & .user_name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    margin-top: 46px;
    margin-left: 20px;
    text-transform: capitalize;
  }
}

@media only screen and (max-width: 1080px) {
  .desktop_side_bar {
    display: none;
    visibility: hidden;
  }
  .menu_sideBar {
    & .menu_side_buttons {
      width: 50%;
      margin: 0 auto;
    }
    & #side_menu_login {
      margin-bottom: 30px;
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 1080px) {
  .menu_sideBar {
    & .menu_side_items {
      text-align: center !important;
    }
  }
}

@media only screen and (max-width: 600px) {
  .menu_sideBar {
    & .menu_side_items {
      text-align: start !important;
    }
    & .menu_side_buttons {
      text-align: center !important;
      width: 100% !important;
    }
  }
}
