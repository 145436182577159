.accountSettings_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #f8f8f8;
  min-height: 1100px;
  overflow-x: hidden;
  & .loading_image {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 5px;
    left: -50px;
  }
  & .pass_textfield {
    position: relative;
  }
  & .accountSettings_box {
    padding-bottom: 45px;
    margin-top: 70px;
    overflow-y: hidden;
    position: relative;
    width: 1115px;
    height: 1150px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 2px rgba(207, 207, 207, 0.25);
    border-radius: 10px;
    overflow-x: hidden;
    & .accountSettings_text {
      width: 500px;
      font-weight: 700;
      font-size: 32px;
      line-height: 150%;
      margin-left: 64px;
      margin-top: 85px;
      color: #19252a;
    }
    & .accountSettings_profile {
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      color: #19252a;
      margin-left: 64px;
      margin-top: 56px;
    }
    & .accountSettings_password {
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      margin-left: 64px;
      margin-top: 336px;
      position: relative;
      top: 36px;
      color: #19252a;
    }
    & .accountSettings_notifications {
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      margin-left: 64px;
      margin-top: 127px;
      color: #19252a;
    }
    & .profile_img {
      border-radius: 100%;
      position: relative;
      top: -536px;
      left: 550px;
    }
    .close_button {
      top: -195px;
      left: 285px;
    }
    .addNewText {
      font-size: 20px;
      top: 35px;
      width: fit-content;
    }
    .newStudentInfo {
      top: 77px;
    }
  }
  & .add_photo {
    border-radius: 100%;
    position: relative;
    top: -515px;
    left: 463px;
    z-index: 2;
  }
  & .change_button {
    border: none;
    margin-left: auto;
    background: none;
    width: fit-content;
    position: relative;
    top: -14px;
    cursor: pointer;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px white, 0 0 0 4px #36a77e;
      border-radius: 2px;
      color: #0a5e3f !important;
    }
    &:hover {
      & .change_password {
        color: #0a5e3f !important;
      }
    }
  }
  & .see_pass {
    background: none;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 278px;
    background: none;
    border: none;
    right: 20px;
  }
  & .eye_img {
    position: relative;
    left: -6px;
    top: 2px;
  }
  .file-upload {
    position: relative;
    top: -528px;
    left: 424px;
    width: 9px;
    height: 19px;
    border-radius: 100px;
    color: #172e38;
    background: none;
    margin-bottom: 0px;
  }
  .file-upload:focus-visible {
    outline: none;
    box-shadow: 0 0 0 3px #36a77e;
  }
  & .accountSettings_info {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    position: relative;
    top: -515px;
    left: 364px;
    width: fit-content;
    & .info_text {
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      color: #000000;
      margin-bottom: 8px;
    }
    & .pass_placeholder {
      position: relative;
      left: 10px;
      top: 10px;
    }
    & .change_password {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      width: fit-content;
      text-decoration-line: underline;
      color: #19252a;
      margin-left: auto;
    }
  }
  & .accountSettings_checkInfo {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: fit-content;
    position: relative;
    top: -452px;
    left: 364px;
    & .send_me_notifications {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      color: #172e38;
      margin-bottom: 20px;
    }
    & .checkInfo_data {
      align-items: flex-start;
      display: flex;
      margin-bottom: 20px;
      & .checkbox {
        color: #36a77e;
        width: 24px;
        height: 24px;
      }
      & .checkInfo_text {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        color: #172e38;
        margin-top: 5px;
      }
      & .checkInfo_subtext {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #5d6970;
      }
    }
  }
  & .save_changes_button {
    position: relative;
    top: -445px;
    left: 918px;
    width: fit-content;
    & .button_text {
      font-weight: 400;
      line-height: 19px;
    }
  }
  & .accountSettingsStudent_box {
    margin-top: 71px;
    overflow-y: hidden;
    position: relative;
    width: 1115px;
    height: 960px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 2px rgba(207, 207, 207, 0.25);
    border-radius: 10px;
    overflow-x: hidden;
    & .accountSettings_text {
      width: 500px;
      font-weight: 700;
      font-size: 32px;
      line-height: 150%;
      margin-left: 64px;
      margin-top: 85px;
      color: #19252a;
    }
    & .accountSettings_profile {
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      color: #19252a;
      margin-left: 64px;
      margin-top: 56px;
    }
    & .accountSettings_password {
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      margin-left: 64px;
      margin-top: 336px;
      position: relative;
      top: 159px;
      color: #19252a;
    }
    & .profile_img {
      border-radius: 100%;
      position: relative;
      top: -356px;
      left: 550px;
    }
    & .add_photo {
      border-radius: 100%;
      position: relative;
      top: -334px;
      left: 463px;
      z-index: 2;
    }
    & .change_button {
      border: none;
      margin-left: auto;
      background: none;
      width: fit-content;
      position: relative;
      top: -4px;
      cursor: pointer;
      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px white, 0 0 0 4px #36a77e;
        border-radius: 2px;
        color: #0a5e3f !important;
      }
      &:hover {
        & .change_password {
          color: #0a5e3f !important;
        }
      }
    }
    & .see_pass {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 13px;
      background: none;
      border: none;
      right: 20px;
    }
    & .eye_img {
      position: relative;
      position: relative;
      left: -6px;
      top: 2px;
    }
    .file-upload {
      position: relative;
      top: -348px;
      left: 424px;
      width: 9px;
      height: 19px;
      border-radius: 100px;
      color: #172e38;
      background: none;
      margin-bottom: 0px;
    }
    .file-upload:focus-visible {
      outline: none;
      box-shadow: 0 0 0 3px #36a77e;
    }
    & .accountSettings_info {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      position: relative;
      top: -312px;
      left: 364px;
      width: fit-content;
      & .info_text {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #000000;
        margin-bottom: 2px;
      }
      & .change_password {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        width: fit-content;
        text-decoration-line: underline;
        color: #19252a;
        margin-left: auto;
      }
    }
    & .save_changes_button {
      position: relative;
      top: -260px;
      left: 918px;
      width: fit-content;
      & .button_text {
        font-weight: 400;
        line-height: 19px;
      }
    }
  }

  & .manageStudents_box {
    position: relative;
    width: 1115px;
    top: 32px;
    height: fit-content;
    min-height: 250px;
    max-height: 530px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #ffffff;
    box-shadow: 0px 2px 4px 2px rgba(207, 207, 207, 0.25);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;
    & .manage_students {
      position: relative;
      left: 64px;
      top: 47px;
      width: fit-content;
      font-weight: 700;
      font-size: 25px;
      line-height: 150%;
      color: #19252a;
    }
    & .manageStudents_text_div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      width: 1000px;
      color: #5d6970;
      top: 65px;
      left: 102px;
      position: relative;
      .student_span {
        position: relative;
        left: 20px;
      }
      .name_span {
        position: relative;
        left: 114px;
      }
      .email_span {
        left: 212px;
        position: relative;
      }
      .grade_level_span {
        left: 391px;
        position: relative;
      }
      .password_span {
        left: 434px;
        position: relative;
      }
    }
    & .manageStudents_grid {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
      margin-top: 75px;
      color: #5d6970;
      & .manageStudents_object_div {
        width: 1000px;
        padding-bottom: 10px;
        & .manageStudents_data_div {
          margin-top: 13.59px;
          justify-content: flex-start;
          align-items: center;
          display: flex;
          & .profile_img {
            border-radius: 100%;
            margin-left: 64px;
          }
          & .student_name {
            margin-left: 82px;
            width: 126px;
            height: fit-content;
            word-break: break-all;
          }
          & .student_email {
            margin-left: 18px;
            width: 199px;
            word-break: break-all;
          }
          & .student_grade {
            margin-left: 21px;
          }
          & .student_password {
            margin-left: 132px;
          }
          & .pencil_icon {
            margin-left: auto;
          }
          & .pencil_icon:focus {
            outline: none;
            box-shadow: 0 0 0 2px white, 0 0 0 4px #36a77e;
            border-radius: 2px;
            color: #0a5e3f !important;
          }
          & .pencil_icon_button {
            margin-left: auto;
            margin-right: 20px;
            border: none;
            background: none;
            width: fit-content;
            cursor: pointer;
            &:focus {
              outline: none;
              box-shadow: 0 0 0 2px white, 0 0 0 4px #36a77e;
              border-radius: 2px;
              color: #0a5e3f !important;
            }
            &:hover {
              outline: none;
              box-shadow: 0 0 0 2px white, 0 0 0 4px #36a77e;
              border-radius: 2px;
              color: #0a5e3f !important;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1150px) {
  .accountSettings_wrapper {
    & .accountSettings_box {
      width: 850px;
      & .profile_img {
        left: 450px;
      }
      & .add_photo {
        left: 364px;
      }
      & .file-upload {
        left: 324px;
      }
      & .accountSettings_info {
        left: 264px;
      }
      & .accountSettings_checkInfo {
        left: 264px;
      }
      & .save_changes_button {
        left: 675px;
      }
    }
    & .accountSettingsStudent_box {
      width: 850px;
      & .profile_img {
        left: 450px;
      }
      & .add_photo {
        left: 364px;
      }
      & .file-upload {
        left: 324px;
      }
      & .accountSettings_info {
        left: 264px;
      }
      & .accountSettings_checkInfo {
        left: 264px;
      }
      & .save_changes_button {
        left: 675px;
      }
    }
    & .manageStudents_box {
      width: 850px;
      overflow-x: auto;
      .manageStudents_text_div {
        width: 760px;
        left: 45px;
        .student_span {
          left: 20px;
        }
        .name_span {
          left: 74px;
        }
        .email_span {
          left: 152px;
        }
        .grade_level_span {
          left: 296px;
        }
        .password_span {
          left: 319px;
        }
      }
      & .manageStudents_grid {
        width: 760px;
        left: 45px;
        font-size: 14px;
        & .manageStudents_object_div {
          width: inherit;
          & .manageStudents_data_div {
            width: inherit;
            & .profile_img {
              margin-left: 20px;
            }
            & .student_name {
              margin-left: 47px;
              width: 110px;
            }
            & .student_email {
              margin-left: 0px;
              width: 176px;
            }
            & .student_grade {
              margin-left: 17px;
            }
            & .student_password {
              margin-left: 110px;
            }
            & .pencil_icon {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}
.changePwDialog {
  width: calc(400px + 40px);
  height: calc(350px + 40px);
  z-index: 2;
  padding: none;
}
.changePw {
  background: #ffffff;
  box-shadow: 0px 0px 8.21492px 4.10746px rgba(180, 180, 180, 0.2);
  border-radius: 10px;
  position: absolute;
  width: 400px;
  height: 430px;
  z-index: 2;
  padding: 20px;
  & .tree_image {
    position: relative;
    z-index: 3;
    left: -20px;
    top: -134px;
    width: 440px;
  }
  & .close_button {
    position: relative;
    z-index: 4;
    top: -195px;
    left: 375px;
    border: none;
    font-size: 17px;
    background: none;
    padding-top: 3px;
    cursor: pointer;
  }
  & .close_button:hover {
    background-color: rgba(58, 90, 255, 0.14);
  }
  & .changePwText {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 150%;
    color: #221e1e;
    left: 0px;
    top: 89px;
    text-align: center;
    width: 440px;
    height: 38px;
    position: absolute;
  }
  .acceptButtonText {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: #221e1e;
    left: 0px;
    top: 89px;
    text-align: center;
    width: 440px;
    height: 38px;
    position: absolute;
  }
  .acceptButtonSubText {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    color: #221e1e;
    left: 0px;
    top: 125px;
    text-align: center;
    width: 440px;
    height: 38px;
    position: absolute;
  }
  & .newStudentInfo {
    top: 150px;
    left: 40px;
    width: fit-content;
    position: absolute;
    display: flex;
    flex-direction: column;
    & .items {
      margin-bottom: 0px;
    }
    .changePwButton {
      margin-top: 10px;
      & .changePwButtonText { 
        text-transform: none;
      }
    }
  }
}
.acceptDialogDialog {
  width: calc(400px + 40px);
  height: calc(200px + 40px);
  z-index: 2;
  padding: none;
}
.acceptDialog {
  background: #ffffff;
  box-shadow: 0px 0px 8.21492px 4.10746px rgba(180, 180, 180, 0.2);
  border-radius: 10px;
  position: absolute;
  width: 400px;
  height: 200px;
  z-index: 2;
  padding: 20px;
  & .tree_image {
    position: relative;
    z-index: 3;
    left: -20px;
    top: -134px;
    width: 440px;
  }
  & .close_button {
    position: relative;
    z-index: 4;
    top: -195px;
    left: 375px;
    border: none;
    font-size: 17px;
    background: none;
    padding-top: 3px;
    cursor: pointer;
  }
  & .close_button:hover {
    background-color: rgba(58, 90, 255, 0.14);
  }
  .acceptButtonText {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: #221e1e;
    left: 0px;
    top: 89px;
    text-align: center;
    width: 440px;
    height: 38px;
    position: absolute;
  }
  .acceptButtonSubText {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    color: #221e1e;
    left: 0px;
    top: 125px;
    text-align: center;
    width: 440px;
    height: 38px;
    position: absolute;
  }
  .acceptButton {
    text-transform: none;
  }
  & .newStudentInfo {
    top: 150px;
    left: 40px;
    width: fit-content;
    position: absolute;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }
}
@media only screen and (max-width: 850px) {
  .accountSettings_wrapper {
    & .accountSettings_box {
      width: 600px;
      height: 1110px;
      & .accountSettings_text {
        margin-left: 44px;
        margin-top: 50px;
      }
      & .accountSettings_profile {
        margin-left: 44px;
      }
      & .accountSettings_password {
        margin-left: 44px;
        top: 10px;
      }
      & .accountSettings_notifications {
        margin-left: 44px;
      }
      & .profile_img {
        left: 228px;
      }
      & .add_photo {
        left: 140px;
      }
      & .file-upload {
        left: 100px;
      }
      & .accountSettings_info {
        left: 43px;
      }
      & .accountSettings_checkInfo {
        left: 43px;
      }
      & .save_changes_button {
        left: 425px;
      }
    }
    & .accountSettingsStudent_box {
      width: 600px;
      height: 960px;
      & .accountSettings_text {
        margin-left: 44px;
        margin-top: 50px;
      }
      & .accountSettings_profile {
        margin-left: 44px;
      }
      & .accountSettings_password {
        margin-left: 44px;
        top: 155px;
      }
      & .accountSettings_notifications {
        margin-left: 44px;
      }
      & .profile_img {
        left: 228px;
      }
      & .add_photo {
        left: 140px;
      }
      & .file-upload {
        left: 100px;
      }
      & .accountSettings_info {
        left: 43px;
      }
      & .pass_textfield {
        margin-top: 48px;
        position: relative;
      }
      & .pass_placeholder {
        top: 20px;
        position: relative;
      }
      & .pass_text {
        position: relative;
        top: 20px;
      }
      & .accountSettings_checkInfo {
        left: 43px;
      }
      & .save_changes_button {
        left: 425px;
      }
    }
    & .manageStudents_box {
      width: 600px;
      .manageStudents_text_div {
        display: none;
      }
      & .manageStudents_grid {
        width: 450px;
        left: 75px;
        font-size: 16px;
        & .manageStudents_object_div {
          width: inherit;
          & .manageStudents_data_div {
            width: inherit;
            & .profile_img {
              margin-left: 20px;
            }
            & .student_name {
              left: 63px;
              margin-left: 15px;
            }
            & .student_email {
              display: none;
            }
            & .student_grade {
              display: none;
            }
            & .student_password {
              display: none;
            }
            & .pencil_icon {
              right: 48px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .accountSettings_wrapper {
    background-color: #ffffff;
    min-height: 900px;
    width: 100%;
    & .loading_image {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 55px;
      left: 43%;
    }
    & .accountSettings_box {
      padding-bottom: 0px;
      width: 100%;
      height: 1080px;
      box-shadow: none;
      border-radius: none;
      margin-top: 0px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      & .accountSettings_text {
        width: fit-content;
        margin-right: auto;
        font-weight: 700;
        font-size: 25px;
        line-height: 150%;
        color: #172e38;
        margin-left: 47px;
      }
      & .accountSettings_profile {
        display: none;
      }
      & .accountSettings_password {
        display: none;
      }
      & .accountSettings_notifications {
        display: none;
      }
      & .profile_img {
        left: 0px;
        top: 20px;
      }
      & .add_photo {
        left: 20px;
        top: 3px;
      }
      & .accountSettings_info {
        left: 0px;
        top: 10px;
      }
      & .see_pass {
        top: 256px;
      }
      & .accountSettings_checkInfo {
        left: 0px;
        top: 35px;
        width: 310px;
        & .send_me_notifications {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 150%;
          margin-left: 2px;
        }
        & .checkInfo_data {
          & .checkInfo_text {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
          }
          & .checkInfo_subtext {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
          }
        }
      }
      & .save_changes_button {
        left: 0px;
        top: 45px;
      }
    }
    & .accountSettingsStudent_box {
      width: 100%;
      height: 850px;
      box-shadow: none;
      border-radius: none;
      margin-top: 0px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      & .accountSettings_text {
        width: fit-content;
        margin-right: auto;
        font-weight: 700;
        font-size: 25px;
        line-height: 150%;
        color: #172e38;
        margin-left: 50px;
      }
      & .accountSettings_profile {
        display: none;
      }
      & .accountSettings_password {
        display: none;
      }
      & .accountSettings_notifications {
        display: none;
      }
      & .profile_img {
        left: 0px;
        top: 20px;
      }
      & .add_photo {
        left: 20px;
        top: 3px;
      }
      & .pass_text {
        top: 1px;
      }
      & .pass_textfield {
        margin-top: 2px;
      }
      & .accountSettings_info {
        left: 0px;
        top: 10px;
      }
      & .accountSettings_checkInfo {
        left: 0px;
        top: 35px;
        width: 310px;
        & .send_me_notifications {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 150%;
          margin-left: 2px;
        }
        & .checkInfo_text {
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
        }
        & .checkInfo_subtext {
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
        }
      }
      & .save_changes_button {
        left: 0px;
        top: 45px;
      }
    }
    & .manageStudents_box {
      width: 100%;
      border-radius: none;
      box-shadow: none;
      align-items: center;
      & .manage_students {
        margin-right: auto;
        margin-left: 10%;
        left: 0px;
      }
      .manageStudents_text_div {
        display: none;
      }
      & .manageStudents_grid {
        width: 310px;
        left: 0px;
        & .manageStudents_object_div {
          width: inherit;
          & .manageStudents_data_div {
            width: inherit;
            & .profile_img {
              margin-left: 5px;
            }
            & .student_email {
              display: none;
            }
            & .student_grade {
              display: none;
            }
            & .student_password {
              display: none;
            }
            & .pencil_icon_button {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .acceptDialogDialog {
    width: calc(300px + 40px);
    height: calc(200px + 40px);
    z-index: 2;
    padding: 0px !important;
  }
  .acceptDialog {
    width: 300px;
    .tree_image {
      width: 340px;
    }
    .close_button {
      left: 291px;
    }
    .acceptButtonSubText {
      width: 340px;
      top: 85px;
    }
    .acceptButtonText {
      width: 340px;
      font-size: 18px;
      top: 50px;
    }
  }
  .changePwDialog {
    width: calc(300px + 40px);
    height: calc(350px + 40px);
    z-index: 2;
    padding: none;
  }
  .changePw {
    background: #ffffff;
    box-shadow: 0px 0px 8.21492px 4.10746px rgba(180, 180, 180, 0.2);
    border-radius: 10px;
    position: absolute;
    width: 300px;
    height: 380px;
    z-index: 2;
    padding: 20px;
    .tree_image {
      width: 340px;
    }
    .close_button {
      left: 280px;
    }
    .changePwText {
      width: 340px;
      left: 0px;
      top: 40px;
    }
    .newStudentInfo {
      top: 100px;
      left: 45px;
    }
  }
}

@media only screen and (max-width: 400px) {
  .changePwDialog {
    padding: 8px 0px !important;
  }
}
