.question_preview_wrapper {
  background: #ffffff;
  border: 1px solid #36a77e;
  border-radius: 10px;
  padding: 20px 5px 2px 20px;
  width: 380px;
  height: 200px;
  margin-top: 20px;

  & .question_preview_body {
    width: 380px;
    height: 200px;
    width: 100%;
    margin: 0px;
    overflow: scroll;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: none;
    }
  }

  & .preview_title {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #19252a;
    opacity: 0.9;
    margin-top: 0px;
  }
}
