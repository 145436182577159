@import '../../styles/colours.scss';

.practice_wrapper {
  min-height: 100vh;
  & .check_buttons_container {
    padding-left: 20px;
  }
  & .next_question_button {
    background: none;
    border: none;
    box-shadow: none;
    position: absolute;
    top: 150px;
    right: 0px;
    cursor: pointer;
    width: 100px;
    height: 100px;
    overflow: hidden;
    & .icon_desktop {
      top: -35px;
      left: -17px;
      position: absolute;
      width: 170px;
    }
    &:focus {
      outline-color: $primary_color;
    }
    & .icon_mobile {
      visibility: hidden;
      display: none;
    }
  }
  & .practice_question_body {
    padding: 20px 20px 0px 20px;
  }

  .question_outline_box-mobile {
    background: #f8f8f8;
    border-radius: 10px;
    padding: 17px 20px;
    position: relative;
    margin-bottom: 20px;
    & .close_outline {
      position: absolute;
      right: 21px;
      top: 15px;
      cursor: pointer;
      border: none;
      background: none;
      padding-top: 5px;
      &:focus {
        outline-color: $primary_color;
      }
    }
    & .title {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: #19252a;
      margin: 0px;
    }
    & .skill_title {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #221e1e;
      margin: 25px 0;
    }
    & .skill_description {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin: -10px 0 20px 0;
    }
    & .question_outline_hr {
      border: 1px solid #ececec;
      margin: 0px;
    }
  }

  & .question_outline_mobile {
    display: none;
    visibility: none;

    .question_outline_mobile_wrapper {
      background: #f8f8f8;
      border-radius: 20px;
      padding: 6px 20px 17px 20px;
      min-height: 55px;
      min-width: 145px;
      & .round_icon_button {
        & .icon {
          margin-top: 0px;
        }
      }
      & .outline_mobile_title {
        margin: 6px 0px 10px 0px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #19252a;
      }
      & .outline_question_title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #19252a;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  & .skill_outline_desktop {
    & .skill_outline_title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #19252a;
      margin-top: 0px;
    }

    & .skill_title_hr {
      margin-bottom: 28px;
      margin-top: 10px;
      max-width: 346px;
      margin-left: 0px;
    }
  }

  .practice_header_bar {
    min-height: auto;
    padding-top: 17px;
    padding-bottom: 17px;
    z-index: 1;
    background: #f8f8f8;

    & .header_skill_name-mobile {
      display: none;
      visibility: hidden;
    }

    & .title_wrapper {
      display: flex;
      gap: 15px;
      flex-direction: row;
      justify-content: center;

      & .header_title {
        font-style: normal;
        font-weight: 600;
        font-size: 23px;
        line-height: 28px;
        color: #172e38;
        margin: 0px;
        text-transform: capitalize;
      }
    }

    & .practice_tracker {
      & .header_text,
      .header_skill_name {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #172e38;
      }

      & .header_skill_name {
        margin-top: 0;
        margin-bottom: 8px;
      }

      & .number_indicator {
        cursor: default;
        font-style: normal;
        font-weight: bold;
        font-size: 9.39303px;
        line-height: 13px;
        color: #172e38;
        border: 1px solid #172e38;
        box-sizing: border-box;
        border-radius: 100%;
        width: 22px;
        height: 22px;
        text-align: center;
        padding: 3px 2px;
      }
      & .icon_indicator {
        margin-bottom: -7px;
      }
    }
  }

  & .skill_outline_wrapper {
    width: 394px;

    & .skill_box_hr {
      margin-bottom: 28px;
      margin-top: 28px;
      max-width: 346px;
      margin-left: 0px;
    }

    & .box_expanded {
      background: #ffffff;
      box-shadow: 0px 3.002px 30px rgba(0, 0, 0, 0.08);
      border-radius: 12px;

      &:focus {
        outline: none;
      }
    }

    & .skill_outline_box {
      max-width: 310px;
      padding: 20px;

      & .box_title {
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 20px;
        color: #19252a;
      }

      & .active_indicator {
        box-sizing: border-box;
        width: 32px;
        height: 32px;
        display: inline-block;
        text-align: center;
        padding: 4px 5px;
        padding-top: 4px;
        margin-right: 4px;
        vertical-align: middle;
      }

      & .unit_info_wrapper {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        margin-left: 39px;

        & .grade_info_box {
          display: flex;
          flex-direction: row;
          gap: 10px;
          line-height: 20px;
          font-style: normal;
          font-weight: 600;
          align-items: center;
          padding: 5px 15px;
          background: #f8f8f8;
          border-radius: 4px;

          & .grade_number {
            font-size: 14px;
            color: #19252a;
          }
          & .grade_type {
            font-size: 12px;
            color: #22805e;
          }
        }
      }

      & .practice_plan_wrapper {
        margin-top: 10px;
        margin-left: 39px;
        margin-right: 10px;

        & .title {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 24px;
          color: #697276;
          margin-bottom: 5px;
        }

        & .unit_name {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #19252a;
          max-width: 196px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 27px;
          padding-top: 3px;
        }

        & .list_icon {
          box-sizing: border-box;
          height: 27px;
          display: inline-block;
          text-align: center;
          margin-right: 8px;
          vertical-align: middle;
        }

        & .unit_difficult {
          height: 27px;
          align-items: center;
          display: flex;
        }
      }
    }
  }

  & .skill_outline_wrapper_mobile {
    width: auto;
    & .skill_outline_box {
      max-width: none;
      background: none;
      box-shadow: none;

      & .unit_info_wrapper .grade_info_box {
        background: #ffffff;
      }
    }
  }
}

.round_report {
  background: #19252a;
  border-bottom: 1px solid #26353b;

  & .report_mobile_title,
  .report_card_mobile {
    display: none;
    visibility: hidden;
  }

  & .engaging_image {
    width: 980px;
    z-index: -1 !important;
  }

  & .title {
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    color: #ffffff;
    margin-top: 80px;
  }

  & .sub_title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 50px;
  }

  & .report_card {
    box-shadow: 0px 3.002px 15.01px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    margin-top: 45px;
    padding: 36px 42px 20px 42px;
    background-color: rgba(38, 53, 59, 0.5);
    width: 570px;
    position: relative;
    margin-bottom: 150px;

    & .card_title {
      font-style: normal;
      font-weight: 600;
      font-size: 23px;
      line-height: 28px;
      color: #ffffff;
      width: 250px;
    }

    & .top_label {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      text-align: center;
      width: 100px;
    }

    & .task_points {
      display: flex;
      vertical-align: middle;
      justify-content: center;
      gap: 4px;
    }

    & .bottom_task_label {
      display: flex;
      vertical-align: middle;
      gap: 4px;
      text-align: center;
      width: 100px;
    }

    & .question_wrapper {
      padding: 2px 0;
    }

    & .task_top_wrapper {
      padding-bottom: 10px;
    }

    & .task_accuracy {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }



    & .bottom_points {
      width: 70px;
      margin-left: 57px;
      justify-content: flex-start;
    }

    & .bottom_summary {
      padding: 3px 0;
    }

    & .total_point_label {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      width: fit-content;
    }

    & .level_info_box {
      margin-top: 10px;
      line-height: 20px;
      font-style: normal;
      font-weight: 600;
      padding: 5px 10px;
      background: #3a474c;
      border-radius: 7px;
      text-align: center;
      width: fit-content;
      & .level_label {
        font-size: 12px;
        color: #36a77e;
      }
    }

    & .report_card_hr {
      background: #5f7178;
      height: 0.3px;
      margin-top: 33px;
      border: none;
    }

    & .card_task_name {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 250px;
    }

    & .card_question_name {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      text-decoration-line: none;
      color: #ffffff;
      padding-left: 30px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 250px;
    }

    & .bottom_labels {
      padding: 10px 0;
    }
  }

  & .report_button {
    padding: 35px 0;
    position: absolute;
    bottom: -134px;
    right: 10px;

    & .keep_going_icon {
      position: absolute;
      top: -4px;
      left: 43%;
    }
  }
}

.question_top_section{
  justify-content: flex-end;
}

@media only screen and (max-width: 1380px) {
  .round_report {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media only screen and (max-width: 1170px) {
  .round_report {
    & .engaging_image {
      width: 800px;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .practice_wrapper {
    & .skill_outline_desktop {
      display: none;
      visibility: hidden;
    }
    & .question_outline_mobile {
      display: block;
      visibility: visible;
    }
  }
  .round_report {
    padding-left: 2%;
    padding-right: 2%;
  }
  .question_top_section{
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 1020px) {
  .round_report {
    & .report_card {
      width: 565px;
    }
    & .report_mobile_title,
    .report_card_mobile {
      display: inline-block;
      visibility: visible;
      & .title {
        margin-top: 50px;
      }
      & .sub_title {
        margin-bottom: 20px;
      }
      & .report_card {
        width: 100%;
        margin-top: 10px;
      }
    }
    & .report_content_desktop {
      display: none;
      visibility: hidden;
    }
  }
}

@media only screen and (max-width: 900px) {
  .practice_wrapper {
    & .practice_header_bar {
      & .practice_tracker {
        padding-right: 0px;
      }
    }
  }
}

@media only screen and (max-width: 695px) {
  .practice_wrapper {
    & .next_question_button {
      width: 40px;
      height: 40px;
      top: -59px;
      right: 5px;
    }

    & .practice_question_body {
      padding: 30px 0 0 0;
      & .question_body_label,
      .question_body_description {
        padding-right: 20px;
      }
    }
    & .practice_header_bar {
      & .practice_tracker {
        margin-top: 20px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  & .report_mobile_title,
  .report_card_mobile {
    & .title {
      margin-top: 30px !important;
      font-size: 20px;
      line-height: 24px;
    }
    & .sub_title {
      font-size: 14px;
      line-height: 18px;
    }
    & .report_card {
      padding: 25px 20px 25px 20px;

      & .card_title {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
      }

      & .accuracy_icon,
      .points_icon {
        width: 30px;
      }

      & .top_label {
        font-size: 14px;
        line-height: 18px;
      }

      & .report_card_hr {
        margin-top: 30px;
      }

      & .card_question_name {
        padding-left: 10px;
        width: 180px;
      }

      & .card_task_name {
        width: 180px;
      }
    }
  }
}

@media only screen and (max-width: 532px) {
  .practice_wrapper {
    & .practice_header_bar {
      & .practice_tracker {
        & .header_skill_name {
          display: none;
          visibility: hidden;
        }
        & .header_skill_name-mobile {
          display: inline-block;
          visibility: visible;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: #172e38;
          margin: 5px 0px;
        }
        & .header_text {
          margin: 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 620px) {
  .question_outline_mobile_wrapper {
    padding: 6px 14px 17px 14px;
    min-width: 85px;
    max-height: 55px;
    width: 117px;
    & .outline_question_title {
      font-size: 14px;
    }
    & .check_buttons_wrapper {
      width: 200%;
    }
  }
  // .practice_wrapper {
  //   & .question_outline_box-mobile {
  //     margin-right: -22px;
  //     margin-left: -22px;
  //   }
  // }
}

@media only screen and (max-width: 500px) {
  .practice_wrapper {
    & .practice_header_bar {
      & .practice_tracker {
        margin-top: 20px;
      }
    }
    & .skill_outline_wrapper_mobile {
      & .skill_outline_box {
        padding: 20px 0px !important;
        & .unit_info_wrapper,
        .practice_plan_wrapper {
          margin-left: 0px;
        }
      }
    }
    & .check_buttons_container {
      padding-left: 0px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .practice_wrapper .question_outline_mobile .question_outline_mobile_wrapper {
    padding: 6px 9px 17px 9px !important;
    min-width: 115px !important;
  }
  .report_card_mobile {
    & .task_accuracy,
    .report_accuracy-desktop {
      display: none;
      visibility: hidden;
    }

    & .report_card {
      & .report_card_hr {
        margin-top: 10px;
      }
      & .card_title {
        margin-bottom: 10px;
      }
      & .task_top_wrapper {
        margin-bottom: 10px;
      }
    }
  }
}

@media only screen and (max-width: 410px) {
  .practice_wrapper {
    & .next_question_button {
      top: 40px;
    }
  }
}

@media only screen and (max-width: 360px) {
  .time_elapsed {
    min-width: 72px;
    padding: 15px 9px;
  }
  .question_score {
    padding: 15px 6px;
  }
}
