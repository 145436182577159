.button_container{
    width: 44px;
    height: 44px;
    position: relative;
    margin-right: 20px;
}

.arrow{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

#backButton{
    display: flex;
    height: 45px;
    color: #0A5E3F;
    width: 118px;
    margin-bottom: 52px;
    & p{
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;        
        color: #000000;
    }
}
.preview_wrapper {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 20px;

  & .preview_title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-top: 0px;
  }
}

.input_label{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #2A8463;
}

.answer_option{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  white-space: nowrap;
}

.remove_button{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.24px;
  text-decoration-line: underline;
  color: #EF4741;
}

.flex_container{
  display: flex;
}

#add_icon{
    margin-right: 12.09px;
    margin-top: 5px;
}

#add_button{
  display: flex;
  height: 45px;
  align-items: center;
  justify-content: left;
  color: #0A5E3F;
  padding-right: 5px;
  width: 134px;
  & p{
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 15px;
      letter-spacing: 0.24px;
      text-decoration-line: underline;
  }
}

.image_text{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #6A6969;
}

#upload_image_input{
  opacity: 0;
  position: absolute;
  z-index: -1;
}

#buttons_container{
  width: 820px;
  margin-top: 60px;
}