.payment_section_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 72vw;
  & .tree_image {
    position: relative;
    z-index: 3;
    top: -100px;
    width: 804px;
  }
  & .payment_box {
    box-shadow: 0px 0px 8.21492px 4.10746px rgba(180, 180, 180, 0.2);
    border-radius: 10px;
    display: flex;
    width: 804px;
    height: 800px;
    margin-top: 130px;
    margin-bottom: 102px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 8%;
    .close_button {
      position: relative;
      z-index: 4;
      margin-left: auto;
      margin-right: 27px;
      top: -190px;
      border: none;
      font-size: 17px;
      background: none;
      padding-top: 3px;
      cursor: pointer;
    }
    & .close_button:hover {
      background-color: rgba(58, 90, 255, 0.14);
    }
    & .back_button {
      position: relative;
      z-index: 4;
      margin-left: 15px;
      margin-right: auto;
      top: -163px;
      border: none;
      cursor: pointer;
      padding: 6px;
      font-size: 17px;
      background: none;
      padding-bottom: 3px;
    }
    & .back_button:hover {
      background-color: rgba(58, 90, 255, 0.14);
    }
    & .try_genius_forest {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 150%;
      color: #19252a;
      margin-bottom: 5px;
      margin-top: -88px;
      text-align: center;
    }
    & .thirty_day_trial {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      color: #172e38;
      margin-bottom: 27px;
    }
    & .payment_details_container {
      width: 550px;
      & .enter_details {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        color: #19252a;
      }
      & .payment_details_form {
        & h2 {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 23px;
          line-height: 20px;
          color: #221e1e;
        }
        & .name_input {
          width: 100%;
          height: 50px;
        }
      }
    }
    & .divider {
      margin-left: -1px;
      width: 550px;
      margin-top: 19px;
      border: 1px solid #ece8e8;
      margin-bottom: 16px;
    }
    & .divider2 {
      margin-left: -1px;
      width: 550px;
      border: 1px solid #ece8e8;
      margin-top: -14px;
      margin-bottom: 19px;
    }
    & .promo_code {
      width: 380px;
      margin-bottom: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    & .order_div {
      display: flex;
      justify-content: flex-start;
      width: 51%;
      margin-left: unset;
      flex-direction: column;
      & .order_summary {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #172e38;
        margin-bottom: 8px;
      }
      & .summary_plan {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        left: 15px;
        position: relative;
      }
      & .price_calc {
        margin-top: 6px;
        position: relative;
        left: 15px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
      }
      & .frequency {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #5d6970;
      }

      & .studentCount {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #5d6970;
      }
    }
    & .pricing_grid {
      width: 50%;
      position: relative;
      left: 50%;
      margin-bottom: 20px;
      & .due_current {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #5d6970;
      }
      & .total_price {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #5d6970;
        text-align: end;
      }
    }
    & .due_today {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #172e38;
      & .days_free {
        color: #68bd9e;
      }
    }
    & .today_price {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #172e38;
      text-align: end;
    }
    & .accountCharge {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      margin-top: 8px;
      text-align: center;
      color: #5d6970;
    }
  }
}
@media only screen and (max-width: 800px) {
  .payment_section_wrapper {
    .payment_box {
      width: 600px;
      & .tree_image {
        width: 604px;
      }
      .close_button {
        margin-left: auto;
        margin-right: 27px;
        top: -170px;
      }
      & .back_button {
        margin-left: 15px;
        margin-right: auto;
        top: -143px;
      }
      & .promo_code {
        padding-left: 1px;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .payment_section_wrapper {
    height: auto;
    & .payment_box {
      box-shadow: none;
      margin-bottom: -70px;
      & .payment_details_container {
        width: 350px;
        & .enter_details {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #19252a;
        }
      }
      & .promo_code {
        width: 350px;
      }
      & .tree_image {
        display: none;
      }
      .close_button {
        display: none;
      }
      & .back_button {
        display: none;
      }
      & .try_genius_forest {
        font-weight: 700;
        font-size: 25px;
        line-height: 150%;
      }
      & .thirty_day_trial {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 32px;
      }

      & .order_div {
        width: 88%;
        justify-content: flex-start;
        & .summary_plan {
          left: 0px;
          margin-bottom: 10px;
        }
        & .price_calc {
          left: 0px;
        }
      }
      & .divider {
        width: 100%;
        margin-bottom: 25px;
      }
      & .divider2 {
        width: 100%;
        margin-top: -6px;
      }

      & .pricing_grid {
        width: 109%;
        left: unset;
        margin-left: -11px;

        & .due_current {
          position: relative;
          left: 11px;
        }
        & .due_today {
          position: relative;
          left: 11px;
        }
        & .today_price {
          position: relative;
          right: 21px;
          width: 100%;
          left: auto;
        }
        & .total_price {
          position: relative;
          right: 21px;
          width: 100%;
          left: auto;
        }
      }
      & .accountCharge {
        width: 352px;
        margin-bottom: 1px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #5d6970;
        margin-top: 27px;
      }
    }
  }
}
@media only screen and (max-width: 360px) {
  .payment_section_wrapper {
    & .payment_box {
      width: 300px;
      margin-bottom: -50px;
      & .divider {
        width: 300px;
      }
      & .divider2 {
        width: 300px;
      }
      & .payment_details_container {
        width: 300px;
      }
      & .promo_code {
        width: 300px;
      }
      & .due_today {
        & .days_free {
          display: none;
        }
      }
      & .accountCharge {
        width: 300px;
      }
    }
  }
}
