.myProgress_tab {
  padding-top: 100px;
  background-color: #f8f8f8;
}

.myProgress_wrapper {
  position: relative;
  min-height: 72vh;
  padding-bottom: 50px;

  & .learner_info_wrapper {
    width: 178px;
    height: 178px;
    position: absolute;
    top: -100px;
  }

  & .topic_button_wrapper {
    text-transform: capitalize;
  }

  & .topic_selection_wrapper {
    button {
      background: #ffffff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
      border-radius: 14px;
      color: #000;
    }
  }

  & .subject_selection {
    margin: 50px 0 20px 0;
  }

  & .skills_tab_wrapper {
    padding: 50px 0;
  }

  & .nothing_to_show {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    color: #697276;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.myProgress_mobile{
  display: none;
  visibility: hidden;
}

@media only screen and (max-width: 900px) {
  .myProgress_wrapper {
    & .subject_selection {
      margin-top: 70px;
    }
    & .learner_info_wrapper {
      width: 150px;
      height: 150px;
      position: relative;
      top: 40px;
      margin: 0 auto;
      & .learner_token {
        width: 70px;
      }
    }
    & .subject_selection{
      display: none;
      visibility: hidden;
    }
    & .icon_desktop{
      display: none;
      visibility: hidden;
    }
  }
  .myProgress_mobile{
    display: flex;
    visibility: visible;
    background-color: #f8f8f8;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 30px;
    align-items: center;

    & .learner_points {
      margin-bottom: 5px;
    }
    & .icon_mobile{
      width: 160px;
      height: 150px;
      padding-right: 30px;
    }
    & .subject_dropdown_mobile{
      align-items: center;
      padding-top: 20px;
      padding-left: 20px;
    }
    & .subject_dropdown{
      display: flex;
      & .subject_dropdown_label{
        display: flex;
        align-items: center;
      }
    }

    & .topic_dropdown_mobile{
      padding-top: 20px;
      padding-left: 20px;
    }
    & .learner_info_content{
      width: 140px;
      height: 140px;
    }
    & .learner_token{
      width: 60px;
    }
    & .topic_selection_wrapper{
      display: none;
      visibility: hidden;
      height: 0px;
    }
    & .summary_top_content{
      width: 0;
      height: 0;
    }
  } 

  .myProgress_tab {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .myProgress_mobile{
    display: grid;
    justify-items: center;
    padding-left: 5%;
    padding-right: 5%;
    gap: 20px;
  }

}