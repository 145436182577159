@import '../../../styles/colours.scss';

.custom_button_icon {
  padding: 2px 5px 0px 0px;
}

.round_icon_button {
  padding: 6px 11px;
  border-radius: 100%;
  background-color: #f8f8f8;
  cursor: pointer !important;
  border: none;
  &:focus {
    outline: none;
    background: #f1eff0;
  }
  &:hover {
    outline: none;
    background: #f1eff0;
  }

  & .icon {
    margin-top: 4px;
  }
}

.loading_button {
  position: absolute;
  margin-right: 10px;
}

.round_icon_button-disabled {
  cursor: default !important;
  &:hover {
    background-color: #f8f8f8;
    outline: none;
  }
}

.round_icon_button-sm {
  padding: 3px 6px;
}

.animated_next_button {
  background: none;
  border: none;
  box-shadow: none;
  position: absolute;
  top: 60px;
  right: -48px;
  cursor: pointer;
  width: 100px;
  height: 100px;
  overflow: hidden;
  & .icon_desktop {
    top: -35px;
    left: -17px;
    position: absolute;
    width: 170px;
  }
  &:focus {
    outline-color: $primary_color;
  }
  & .icon_mobile {
    visibility: hidden;
    display: none;
  }
}

@media only screen and (max-width: 695px) {
  .animated_next_button {
    width: 40px;
    height: 40px;
    & .icon_mobile {
      visibility: visible;
      display: block;
      top: -15px;
      left: -7px;
      position: absolute;
      width: 71px;
    }
    & .icon_desktop {
      visibility: hidden;
      display: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  .animated_next_button {
    right: 0px;
    top: 52px;
  }
}
