.membership_billing_wrapper {
  & .main_box_mobile h1 {
    display: none;
    visibility: hidden;
  }
}

.membership_card_wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 8.21492px 4.10746px rgba(180, 180, 180, 0.2);
  border-radius: 10px;
  max-width: 415px;
  max-height: 290px;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px white, 0 0 0 4px #36a77e;
  }
  & .top_section {
    background-image: url('/assets/images/account_box.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    & .information_wrapper {
      padding: 11px 32px;
      display: flex;
      & .picture {
        background-color: #172e38;
        width: 77px;
        height: 77px;
        border-radius: 50%;
        display: inline-block;
        background-color: #172e38;
      }

      & .no_picture_wrapper {
        width: 77px;
        height: 77px;
        background: #172e38;
        border-radius: 20.5886px;
        border-radius: 50%;
        border: none;
        box-shadow: none;
        cursor: pointer;
        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px white, 0 0 0 4px #172e38;
        }

        & .no_picture {
          padding-top: 5px;
          padding-left: 5px;
          width: 24px;
          height: 24px;
        }
      }
      & .name {
        margin: 0px;
        padding-left: 15px;
        padding-top: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #172e38;
      }
    }
  }
  & .bottom_section {
    padding: 18px 32px;
    margin-top: -3px;
    z-index: 1;
    background: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    & p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      display: flex;
      align-items: center;
      color: #5d6970;
      margin: 0px;
    }
    & .date {
      margin-top: 5px;
      margin-bottom: 0px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      display: flex;
      align-items: center;
      color: #172e38;
    }
    & .more_details {
      cursor: pointer;
      margin-top: 5px;
      margin-left: -7px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      display: flex;
      align-items: center;
      text-decoration-line: underline;
      color: #172e38;
      border: none;
      box-shadow: none;
      background: none;
      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px white, 0 0 0 4px #36a77e;
        border-radius: 2px;
        color: #0a5e3f !important;
      }
      &:hover {
        color: #0a5e3f !important;
      }
    }

    & .price_wrapper {
      display: flex;
      & .sign {
        font-weight: 400;
        font-size: 16px;
        line-height: 33px;
        letter-spacing: 0.1px;
        color: #252b42;
        padding-top: 10px;
        padding-right: 4px;
      }
      & .price {
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 66px;
        display: flex;
        align-items: center;
        letter-spacing: 0.2px;
        color: #19252a;
      }
      & .plan {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1px;
        color: #919a9e;
        padding-left: 2px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .membership_card_wrapper {
    max-width: 100%;
    max-height: 100%;
    & .top_section {
      background-image: url('/assets/images/account_box_mobile.svg');
      background-size: 110%;
      height: 130px;
      & .information_wrapper {
        padding: 26px 52px;
      }
    }
    & .bottom_section {
      padding: 18px 25px;
      & p {
        font-weight: 400;
        font-size: 14px;
      }
      & .date {
        font-size: 16px;
      }
      & .more_details {
        font-size: 14px;
      }

      & .price_wrapper .price {
        font-size: 30px;
      }
    }
  }

  .membership_billing_wrapper {
    & .main_box h1 {
      display: none;
      visibility: hidden;
    }
  }

  .membership_billing_wrapper {
    & .billing_card_wrapper {
      margin-top: 0px !important;
    }
    & .main_box_mobile h1 {
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 22px !important;
      line-height: 36px !important;
      align-items: center;
      color: #19252a !important;
      margin-top: 60px;
      display: inline-block;
      visibility: visible;
    }
  }
}

@media only screen and (max-width: 420px) {
  .membership_card_wrapper {
    & .top_section {
      background-image: url('/assets/images/account_box_mobile.svg');
      background-size: 120%;
      & .information_wrapper {
        padding: 26px 30px;
      }
    }
  }
}

@media only screen and (min-height: 1120px) {
  .membership_billing_wrapper {
    padding-bottom: 20%;
  }
}
