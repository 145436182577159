@import '../../styles/colours.scss';

.diagnostic_wrapper {
  & .loading_image {
    width: 40px;
    height: 40px;
  }
  & .check_buttons_container {
    padding-left: 20px;
  }
  & .next_question_button {
    background: none;
    border: none;
    box-shadow: none;
    position: absolute;
    top: 150px;
    right: 0px;
    cursor: pointer;
    width: 100px;
    height: 100px;
    overflow: hidden;
    & .icon_desktop {
      top: -35px;
      left: -17px;
      position: absolute;
      width: 170px;
    }
    &:focus {
      outline-color: $primary_color;
    }
    & .icon_mobile {
      visibility: hidden;
      display: none;
    }
  }
  & .practice_question_body {
    padding: 20px 20px 0px 20px;
  }

  & .check_buttons_wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .question_outline_box-mobile {
    background: #f8f8f8;
    border-radius: 10px;
    padding: 17px 20px;
    position: relative;
    margin-bottom: 20px;
    & .close_outline {
      position: absolute;
      right: 21px;
      top: 15px;
      cursor: pointer;
      border: none;
      background: none;
      padding-top: 5px;
      &:focus {
        outline-color: $primary_color;
      }
    }
    & .title {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: #19252a;
      margin: 0px;
    }
    & .skill_title {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #221e1e;
      margin: 25px 0;
    }
    & .skill_description {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin: -10px 0 20px 0;
    }
    & .question_outline_hr {
      border: 1px solid #ececec;
      margin: 0px;
    }
  }

  & .question_outline_mobile {
    display: none;
    visibility: none;

    .question_outline_mobile_wrapper {
      background: #f8f8f8;
      border-radius: 20px;
      padding: 6px 20px 17px 20px;
      min-height: 55px;
      min-width: 145px;
      & .round_icon_button {
        & .icon {
          margin-top: 0px;
        }
      }
      & .outline_mobile_title {
        margin: 6px 0px 10px 0px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #19252a;
      }
      & .outline_question_title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #19252a;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  & .skill_outline_desktop {
    & .skill_outline_title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #19252a;
      margin-top: 0px;
    }

    & .skill_title_hr {
      margin-bottom: 28px;
      margin-top: 10px;
      max-width: 346px;
      margin-left: 0px;
    }
  }

  .practice_header_bar {
    min-height: auto;
    padding-top: 17px;
    padding-bottom: 17px;
    z-index: 1;
    background: #f8f8f8;

    & .header_skill_name-mobile {
      display: none;
      visibility: hidden;
    }

    & .title_wrapper {
      display: flex;
      gap: 15px;
      flex-direction: row;
      justify-content: center;

      & .header_title {
        font-style: normal;
        font-weight: 600;
        font-size: 23px;
        line-height: 28px;
        color: #172e38;
        margin: 0px;
        text-transform: capitalize;
      }
    }

    & .practice_tracker {
      & .header_text,
      .header_skill_name {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #172e38;
      }

      & .header_skill_name {
        margin-top: 0;
        margin-bottom: 8px;
      }

      & .number_indicator {
        cursor: default;
        font-style: normal;
        font-weight: bold;
        font-size: 9.39303px;
        line-height: 13px;
        color: #172e38;
        border: 1px solid #172e38;
        box-sizing: border-box;
        border-radius: 100%;
        width: 22px;
        height: 22px;
        text-align: center;
        padding: 3px 2px;
      }
      & .icon_indicator {
        margin-bottom: -7px;
      }
    }
  }

  & .skill_outline_wrapper {
    width: 394px;

    & .outline_description {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      padding-left: 45px;
    }

    & .skill_box_hr {
      margin-bottom: 28px;
      margin-top: 28px;
      max-width: 346px;
      margin-left: 0px;
    }

    & .box_expanded {
      background: #ffffff;
      box-shadow: 0px 3.002px 30px rgba(0, 0, 0, 0.08);
      border-radius: 12px;

      &:focus {
        outline: none;
      }
    }

    & .skill_outline_box {
      max-width: 310px;
      padding: 20px;

      & .box_title {
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 20px;
        color: #19252a;
        display: flex;
        vertical-align: middle;
        align-items: center;

        & .active_indicator {
          padding-top: 0px;
          margin-right: 1px;
          padding-left: 0px;
          height: 32px;
          margin-right: 11px;
        }

        & .test_outline_number_indicator {
          cursor: pointer;
          font-weight: bold;
          font-size: 15px;
          line-height: 13px;
          color: #fff;
          border: 1px solid #172e38;
          box-sizing: border-box;
          border-radius: 100%;
          width: 32px;
          height: 32px;
          display: inline-block;
          text-align: center;
          padding: 4px 5px;
          margin-top: -5px;
          background: #19252a;
          border: 6px solid #354045;
          margin-right: 8px;
          vertical-align: middle;
          margin-top: 1px;
        }
      }
    }
  }

  & .skill_outline_wrapper_mobile {
    width: auto;
    & .skill_outline_box {
      max-width: none;
      background: none;
      box-shadow: none;
    }
  }
}

.question_top_section {
  justify-content: flex-end;
}

@media only screen and (max-width: 1100px) {
  .diagnostic_wrapper {
    & .skill_outline_desktop {
      display: none;
      visibility: hidden;
    }
    & .question_outline_mobile {
      display: block;
      visibility: visible;
    }
  }
  .question_top_section {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 900px) {
  .diagnostic_wrapper {
    & .practice_header_bar {
      & .practice_tracker {
        padding-right: 0px;
      }
    }
  }
}

@media only screen and (max-width: 695px) {
  .diagnostic_wrapper {
    & .next_question_button {
      width: 40px;
      height: 40px;
      top: -59px;
      right: 5px;
    }

    & .question_score {
      min-width: 50px;
    }

    & .practice_question_body {
      padding: 30px 0 0 0;
      & .question_body_label,
      .question_body_description {
        padding-right: 20px;
      }
    }
    & .practice_header_bar {
      & .practice_tracker {
        margin-top: 20px;
      }
    }
  }
}

@media only screen and (max-width: 620px) {
  .question_outline_mobile_wrapper {
    padding: 6px 9px 17px 9px;
    min-width: 85px;
    max-height: 55px;
    width: 117px;
    & .outline_question_title {
      font-size: 14px;
    }
    & .check_buttons_wrapper {
      width: 200%;
    }
  }
  // .diagnostic_wrapper {
  //   & .question_outline_box-mobile {
  //     margin-right: -22px;
  //     margin-left: -22px;
  //   }
  // }
}

@media only screen and (max-width: 532px) {
  .diagnostic_wrapper {
    & .practice_header_bar {
      & .header_skill_name {
        display: none;
        visibility: hidden;
      }
      & .header_skill_name-mobile {
        display: inline-block;
        visibility: visible;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #172e38;
        margin: 5px 0px;
      }
      & .header_text {
        margin: 0px;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .diagnostic_wrapper {
    & .practice_header_bar {
      & .practice_tracker {
        margin-top: 20px;
      }
    }
    & .skill_outline_wrapper_mobile {
      & .skill_outline_box {
        padding: 20px 0px !important;
      }
    }
    & .check_buttons_container {
      padding-left: 0px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .diagnostic_wrapper .question_outline_mobile .question_outline_mobile_wrapper {
    padding: 6px 9px 17px 9px;
    min-width: 115px;
  }
}

@media only screen and (max-width: 410px) {
  .diagnostic_wrapper {
    & .next_question_button {
      top: 40px;
    }
  }
}

@media only screen and (max-width: 360px) {
  .time_elapsed {
    min-width: 72px;
    padding: 15px 9px;
  }
  .question_score {
    padding: 15px 6px;
  }
}
