#cookie_consent_form {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
  display: relative;
  z-index: 10000;
}

#cookie_consent_content {
  padding-left: 10%;
  padding-right: 10%;
  height: 122px;
  display: flex;
  align-items: center;
  & p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #19252a;
  }
  & #cookie_consent_text_wrapper {
    width: 100%;
    height: 58px;
  }
}

#close_button {
  position: absolute;
  top: 15px;
  right: 33px;
  cursor: pointer;
  background: none;
  border: none;
  padding-top: 3px;
  border: 2px solid transparent;
  & #button_wrapper {
    position: relative;
    & img {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  &:focus {
    outline: none;
    border: 2px solid #31d197;
    border-radius: 4px;
  }
  &:hover {
    outline: none;
    border: 2px solid #31d197;
    border-radius: 4px;
  }
}

#learn_more {
  padding-left: 34px;
}

#learn_more_text {
  cursor: pointer;
  white-space: nowrap;
}

#buttons_box_container {
  display: flex;
  margin-left: auto;
  margin-right: 10px;
}

#learn_more_open_text {
  margin-left: 10%;
  margin-right: 10%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #19252a;
  padding-bottom: 10px;
}

@media only screen and (max-width: 480px) {
  // #close_button{
  //   display: none;
  // }
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 644px) {
  #cookie_consent_text_wrapper {
    font-size: 10px;
  }
  #close_button {
    display: none;
  }
}

@media only screen and (max-width: 1080px) {
  #cookie_consent_content {
    padding-left: 5%;
    padding-right: 5%;
    flex-direction: column;
    height: auto;
    & #cookie_consent_text_wrapper {
      width: 100%;
      height: auto;
    }
    & #cookie_consent_button {
      margin-left: 0;
    }
    & #buttons_box_container {
      margin-left: auto;
      margin-right: auto;
    }
  }
  #learn_more {
    padding-left: 0;
  }

  #close_button {
    position: absolute;
    top: 39px;
    right: 18px;
  }
}
