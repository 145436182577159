.skill_box {
  background: #ffffff;
  border: 0.750499px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 20px 30px rgba(74, 73, 73, 0.18);
  border-radius: 12px;
  text-transform: capitalize;

  & .skill_top_content-collapsed {
    padding: 24px;
  }

  & .skill_top_content {
    padding: 24px;
    background: #f8f8f8;
    border-radius: 12px 12px 0px 0px;
  }

  & .skill_bottom_content {
    padding: 24px;
  }

  & .skill_box_row {
    padding-top: 10px;
  }

  & .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #19252a;
  }

  & .score_title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #697276;
  }

  & .score_value {
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #000000;
  }

  & .grade_info_box {
    display: flex;
    flex-direction: row;
    gap: 10px;
    line-height: 20px;
    font-style: normal;
    font-weight: 600;
    align-items: center;
    padding: 5px 15px;
    background: #ffffff;
    border-radius: 4px;

    & .grade_number {
      font-size: 14px;
      color: #19252a;
    }
    & .grade_type {
      font-size: 12px;
      color: #22805e;
    }
  }

  & .sub_title {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: #697276;
  }

  & .text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }

  & .difficulty_level {
    padding-bottom: 7px;
  }

  & .button_wrapper {
    text-align: end;
    padding: 24px;
    padding-top: 10px;
  }

  & .practice_plan_wrapper {
    margin-top: 10px;
    margin-right: 10px;
    background: #ffffff;
    padding: 24px;
    min-height: 160px;
    border-radius: 12px;

    & .plan_title {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      color: #697276;
      margin-bottom: 5px;
    }

    & .plan_difficulty_title {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      color: #19252a;
      margin-bottom: 5px;
    }

    & .unit_name {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #6a6969;
      height: 27px;
      padding-top: 3px;
    }

    & .list_icon {
      box-sizing: border-box;
      height: 27px;
      display: inline-block;
      text-align: center;
      margin-right: 8px;
      vertical-align: middle;
    }

    & .unit_difficult {
      height: 27px;
      align-items: center;
      display: flex;
    }
  }
}

@media only screen and (max-width: 600px) {
  .skill_box {
    box-shadow: none;
    border-bottom: 1px solid #ececec;
    border-radius: 0px;
    & .title {
      width: 80%;
    }
    & .skill_top_content {
      border-radius: 0px;
    }
    & .practice_plan_wrapper {
      padding: 20px;

      & .unit_name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .skill_box {
    & .level_information_mobile {
      width: 98% !important;
      justify-content: space-between !important
    }
    & .skill_box_categories_mobile {
      display: inline-block;

      & .skill_box_mobile_items {
        width: 90%;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        align-items: center;
      }


      & .difficult {
        padding-bottom: 0px;
      } 

      & .score {
        padding-top: 23px;
      }

      & .difficulty_bar {
        padding-bottom: 5px;
      }
    }

    & .grade_info_box {
      gap: 10px;
      padding: 5px 0px;
    }
  }
}
