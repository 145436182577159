@import '../../../styles/colours.scss';

.early_dialog {
  & .MuiDialog-paper {
    background: #19252a;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding-top: 50px;
    width: 60%;
  }
  & .early_logo {
    position: absolute;
    top: 28px;
    left: 38px;
    & .logo {
      width: 89px;
    }
  }
  & .close_dialog {
    position: absolute;
    top: 25px;
    right: 25px;
  }
  & .early_image {
    position: absolute;
    right: 0px;
    top: 50px;
    width: 400px;
  }
  & .dialog_body {
    max-width: 350px;
    margin-bottom: 60px;
    margin-left: 90px;
    margin-top: 50px;
    & .title {
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
      color: #6ff2c2;
    }
    & .body_text {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: white;
    }
  }
  & .action_section {
    background-color: #ffffff;
    height: 150px;
    padding: 48px 20px;
    & .pre_sign-up_wrapper {
      width: 450px;
    }
    & .body_text-mobile {
      visibility: hidden;
      display: none;
    }
  }
}

.default_dialog_wrapper {
  & .close_dialog {
    position: absolute;
    right: 15px;
    top: 10px;
    border: none;
    background: none;
    cursor: pointer;
    text-align: center;
    padding-top: 3px;
    border: 2px solid transparent;

    &:focus {
      outline: none;
      border: 2px solid #19252a;
      border-radius: 4px;
    }
  }

  & .dialog_title {
    & h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 36px;
      color: #000000;
    }
    padding: 10px 25px 8px 25px;
  }

  & .body_title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 0px;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 1320px) {
  .early_dialog {
    & .MuiDialog-paper {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 860px) {
  .early_dialog {
    & .early_image {
      top: 100px;
      width: 300px;
    }
  }
}

@media only screen and (max-width: 750px) {
  .early_dialog {
    & .dialog_body {
      margin-left: 38px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .early_dialog {
    & .early_image {
      top: 190px;
      width: 200px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .early_dialog {
    & .dialog_body {
      max-width: 250px;
      margin-bottom: 0px;
    }
  }
  .default_dialog_wrapper {
    & .dialog_title {
      & h2 {
        font-size: 18px;
        line-height: 26px;
      }
      padding: 10px 25px 8px 25px;
    }

    & .body_title {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 530px) {
  .early_dialog {
    max-height: 650px;
    & .MuiDialog-paper {
      margin: 10px;
    }
  }
}

@media only screen and (max-width: 450px) {
  .early_dialog {
    & .MuiDialog-paper {
      padding-top: 20px;
    }
    & .early_image {
      top: 110px;
    }
    & .dialog_body {
      height: 230px;
      max-width: 200px;
      margin-bottom: 100px;
      & .body_text {
        visibility: hidden;
        display: none;
      }
    }
    & .action_section {
      padding: 20px;
      height: 300px;
      & .body_text-mobile {
        margin-top: 0px;
        visibility: visible;
        display: block;
        margin-bottom: 0px;
        margin-bottom: 15px;
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .early_dialog {
    & .early_image {
      top: 115px;
      width: 190px;
    }
  }
}

@media only screen and (max-width: 360px) {
  .early_dialog {
    & .early_image {
      top: 125px;
      width: 170px;
    }
  }
}
