.pricing_wrapper {
  & .freeTrial {
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0px;
    text-align: center;
  }
  & .section_one_buttons_wrapper {
    display: flex;
    gap: 15px;
    padding-top: 35px;
    & .sign_up_button_cover {
      width: 187px;
      height: 57px;
      border: 1.5px solid #19252a;
      box-sizing: border-box;
      border-radius: 10px;
      transform: rotate(-4deg);
      position: absolute;
      transition: all 0.3s;
    }
    & .sign_up_button_cover.active {
      transform: rotate(0deg);
    }
  }
  & .section_one_wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    color: #172e38;
    background-color: #ffffff;
    background-image: url('/assets/images/pricingdesktopbg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 3%;
    padding-top: 7%;
    text-align: center;
    & .section_one_buttons_wrapper {
      justify-content: center;
    }
    & .newMemberText {
      padding-top: 30px;
      font-size: 18px;
    }
    & .just9 {
      margin-top: 16px;
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 150%;
    }
  }
  & .section_two_wrapper {
    background-color: #def0e9;
    position: relative;
    height: 682.97px;
    overflow: hidden;

    & .section_two_card {
      position: absolute;
      width: 408px;
      height: 508px;
      left: 15%;
      top: 20%;
      background: #ffffff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      border-radius: 20px;
      z-index: 2;
      & .trial_name {
        font-family: 'Open Sans';
        font-style: normal;
        font-size: 22px;
        font-weight: 600;
        line-height: 33px;
        position: absolute;
        width: 203px;
        height: 33px;
        left: 10%;
        top: 5px;
      }
      & .pricing_div {
        display: flex;
        position: absolute;
        left: 10%;
        top: 15%;
      }
      & .dollar_sign {
        color: #252b42;
        font-weight: 700;
        font-size: 25px;
        padding-right: 2%;
      }
      & .price {
        color: #33cc94;
        font-size: 70px;
        font-weight: 800;
        padding-top: 2%;
      }
      & .per_duration {
        color: #5d6970;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        width: 200px;
        height: 56px;
        padding-left: 5%;
        padding-top: 1%;
      }
      & .list_items {
        position: relative;
        top: 165px;
        left: 10%;
        padding-left: 3%;
        padding-bottom: 4%;
        padding-top: 4%;
        font-size: 18px;
        font-weight: 400;
        list-style-image: url(/assets/images/pricingpage_checkmark.png);
        color: #5d6970;
        font-family: Open Sans;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: left;
      }
      & p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #19252a;
      }
    }
    & .switch_button {
      background: white;
      border-radius: 30px;
      width: 170px;
      text-align: center;
      font-weight: bold;
      position: relative;
      padding-right: 120px;
      position: relative;
      left: 15%;
      top: 5%;
      &:before {
        content: 'Annually';
        position: absolute;
        color: #172e38;
        top: 0;
        bottom: 0;
        right: 0;
        width: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        pointer-events: none;
        font-family: Open Sans;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: left;
      }

      &_checkbox {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        color: white;
        opacity: 0;
        z-index: 2;

        &:checked + .switch_button_label:before {
          transform: translateX(135px);
          transition: transform 200ms linear;
          content: 'Annually';
        }
        & + .switch_button_label {
          position: relative;
          padding: 15px 0;
          display: block;
          &:before {
            content: 'Monthly';
            background: #172e38;
            color: white;
            height: 87%;
            width: 85%;
            margin-left: 5px;
            margin-top: 2%;
            position: absolute;
            z-index: 2;
            justify-content: center;
            align-items: center;
            display: flex;
            left: 0;
            top: 0;
            border-radius: 30px;
            transform: translateX(0);
            transition: transform 200ms;
          }
          .switch_button_label_span {
            position: relative;
            color: #172e38;
            font-family: Open Sans;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: 0px;
            text-align: left;
          }
        }
      }
    }

    & .price_img {
      position: absolute;
      top: 25%;
      left: 50%;
      width: 508px;
      height: 422px;
    }
  }
}

.free_trial_button {
  border: 0px solid #172e38;
  border-radius: 4px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #172e38;
  width: 149px;
  height: 50px;
  top: 80%;
  position: absolute;
  justify-content: center;
  padding: 10px 16px 10px 16px;
  margin-left: 26%;
  border-radius: 10px;
  white-space: nowrap;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .pricing_wrapper {
    & .section_one_wrapper {
      background-image: url('/assets/images/pricingmobilebg.png');
      background-position: center;
    }
    & .section_two_wrapper {
      height: 810px;
      & .switch_button {
        left: 30%;
        top: 10%;
      }
      & .section_two_card {
        position: absolute;
        left: 5%;
        right: 5%;
        width: 90%;
        height: 610px;
      }
      .free_trial_button {
        width: 149px;
        margin-left: 25%;
        height: 57px;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      & .price_img {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 340px) and (max-width: 600px) {
  .pricing_wrapper {
    & .section_one_wrapper {
      & .newMemberText {
        width: 80%;
        padding-bottom: 8%;
      }
    }

    & .freeTrial {
      font-style: normal;
      font-weight: 700;
      font-size: 33px;
      line-height: 150%;
      margin-top: 50px;
      margin-bottom: 10px;
    }

    & .just9 {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    & .section_one_buttons_wrapper {
      padding-bottom: 2%;
      width: 100%;
      .sign_up_button_cover {
        width: 165px;
      }
    }
    & .sign_up_button {
      width: 165px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .section_two_wrapper {
      & .switch_button {
        position: relative;
        margin: 0 auto;
        top: auto;
        left: auto;
        margin-top: 50px;
      }

      & .section_two_card {
        height: 510px;
        & ul {
          margin-top: -15px;
        }

        & .trial_name {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;
        }

        & .dollar_sign {
          font-size: 20px;
        }

        & .price {
          font-size: 50px;
        }

        & .per_duration {
          font-size: 18px;
        }
        & .list_items {
          left: 10%;
          font-size: medium;
        }
        & .pricing_div {
          top: 10%;
        }
        & .free_trial_button {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 15px;
        }
      }
    }
  }
}

@media only screen and (min-width: 340px) and (max-width: 400px) {
  .pricing_wrapper {
    & .section_two_wrapper {
      height: 700px;
      & .section_two_card {
        height: 460px;
      }
    }
  }
}
