.time_elapsed {
  background: #f8f8f8;
  border-radius: 20px;
  padding: 17px 20px;
  min-height: 46px;
  min-width: 80px;
  & .time_elapsed_title {
    margin: 0px 0px 10px 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #19252a;
  }
  & .counter {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #19252a;
  }
}

.question_score {
  background: #f8f8f8;
  border-radius: 20px;
  padding: 17px 20px;
  min-height: 46px;
  min-width: 80px;
  & .question_score_title {
    margin: 0px 0px 10px 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #19252a;
  }
  & .question_score_counter {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #19252a;
  }
}

.result_message_wrapper {
  position: absolute;
  top: -68px;
  left: 69px;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  & .box {
    background: #ffffff;
    box-shadow: 3px 9px 33px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    padding: 18px;
    & .error {
      color: #cf380d;
    }
    & .success {
      color: #36a77e;
    }
  }
  .result_image {
    position: absolute;
    top: 37px;
    left: -19px;
  }
}

.difficult_level_wrapper {
  display: flex;
  & .level_box {
    background: #ececec;
    border-radius: 2px;
    width: 9px;
    height: 9px;
    margin-right: 2px;
  }
  & .active {
    background: #31d197;
  }
}

.learner_info_wrapper {
  background: #cd6200;
  border: 12px solid #ff9500;
  border-radius: 50%;

  & .learner_info_content {
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
    font-weight: bold;
    color: #ffffff;

    & .learner_token {
      margin-top: 10px;
    }

    & .learner_name {
      font-size: 15px;
      line-height: 14px;
    }

    & .learner_points {
      margin-top: 5px;
      font-style: normal;
      font-size: 11px;
      line-height: 14px;
    }
  }
}

.answer_helper {
  display: flex;
  & .answer_helper_label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    text-decoration-line: underline;
    color: #19252a;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 4px;
    background: none;

    &:focus {
      color: #0a5e3f;
      outline: none;
      border-color: #2a8463;
      text-shadow: 1px 0 0 #2a8463;
    }

    &:hover {
      color: #0a5e3f;
      outline: none;
      text-shadow: 1px 0 0 #2a8463;
    }
  }
}
.answer_dialog_wrapper {
  & .dialog_title {
    & h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 36px;
      color: #000000;
    }
    border-bottom: 1px solid #000000;
    padding: 10px 25px 8px 25px;
  }

  & .dialog_body {
    padding-bottom: 30px;
  }

  & .body_title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 33px;
    margin-top: 10px;
  }

  & .body_text {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
  }

  & .symbol_box {
    background: #f8f8f8;
    border-radius: 10px;
    width: 64px;
    height: 64px;
    text-align: center;
    & .symbol {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #000000;
      margin-top: 5px;
    }
  }

  & .close_dialog {
    position: absolute;
    right: 20px;
    top: 14px;
    border: 2px solid transparent;
    background: none;
    cursor: pointer;
    text-align: center;
    padding-top: 3px;

    &:focus {
      outline: none;
      border: 2px solid #19252a;
      border-radius: 4px;
    }
  }

  & .content_division {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .answer_dialog_wrapper {
    & .close_dialog {
      right: 8px;
      top: 10px;
    }
    & .dialog_title {
      & h2 {
        font-size: 16px;
      }
    }
    & .body_title {
      font-size: 14px;
      margin-bottom: 15px;
      margin-top: 0px;
    }
    & .body_text {
      font-size: 14px;
    }
    & .symbol_box {
      width: 45px;
      height: 45px;
      & .symbol {
        margin-top: -4px;
        font-size: 14px;
      }
    }
  }

  .time_elapsed {
    padding: 16px 14px;
    & .counter {
      font-size: 14px;
    }
  }
  .question_score {
    min-height: 46px;
    min-width: 37px;
    padding: 15px 11px;
    & .question_score_counter {
      font-size: 14px;
    }
  }
  .time_elapsed {
    padding: 15px 11px;
  }

  .result_message_wrapper {
    top: -68px;
    .box {
      text-align: center;
      width: 93%;
      padding: 10px;
    }
    .result_image {
      left: -20px;
    }
  }
}
